import React, { useState } from 'react'

import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import CustomerLoanApp from './LoanApplications'
import CustomerDir from './CustomerDir'
import PersonalInfo from './PersonalInfo'
import CustomerDocs from './CustomerDocs'
import BusinessInfo from './BusinessInfo'

const CustomerNavbar = () => {
  const history = useHistory()

  const [activeBar, setActiveBar] = useState('personalInfo')

  const handleTabClick = tab => {
    setActiveBar(tab)
  }
  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-transparent'>
        <div className='ml-1 mt-4'>
          <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
            <i className='fas fa-arrow-left mr-3' />
            Back
          </h3>
        </div>
        <div className='mt-5 flex justify-around w-full gap-5'>
          <button
            onClick={() => handleTabClick('personalInfo')}
            className={`cursor-pointer ${
              activeBar === 'personalInfo'
                ? 'border-b-4 text-lg border-blue-700  text-blue-700'
                : ' text-black px-3 text-lg py-2'
            }`}
          >
            Personal Informations
          </button>
          <button
            onClick={() => handleTabClick('businessInfo')}
            className={`cursor-pointer ${
              activeBar === 'businessInfo'
                ? 'border-b-4 text-lg border-blue-700  text-blue-700'
                : ' text-black px-3 text-lg py-2'
            }`}
          >
            Business Informations
          </button>
          <button
            onClick={() => handleTabClick('loanApp')}
            className={`cursor-pointer ${
              activeBar === 'loanApp'
                ? 'border-b-4 border-blue-700 text-lg  text-blue-700'
                : ' text-black px-3 text-lg py-2'
            }`}
          >
            Loan Applications
          </button>
          <button
            onClick={() => handleTabClick('customerDocs')}
            className={`cursor-pointer ${
              activeBar === 'customerDocs'
                ? 'border-b-4 border-blue-700 text-lg  text-blue-700'
                : ' text-black px-3 py-2 text-lg'
            }`}
          >
            Customer Documents
          </button>
          <button
            onClick={() => handleTabClick('directors')}
            className={`cursor-pointer ${
              activeBar === 'directors'
                ? 'border-b-4 border-blue-700 text-lg  text-blue-700'
                : ' text-black px-3 py-2 text-lg'
            }`}
          >
            Directors
          </button>
        </div>
        <div className=' border-b-2 border-gray-100 mt-5' />
        <div className='w-full overflow-hidden'>
          {activeBar === 'personalInfo' && <PersonalInfo />}
          {activeBar === 'businessInfo' && <BusinessInfo />}
          {activeBar === 'loanApp' && <CustomerLoanApp />}
          {activeBar === 'customerDocs' && <CustomerDocs />}
          {activeBar === 'directors' && <CustomerDir />}
        </div>
      </main>
    </DashboardLayout>
  )
}

export default CustomerNavbar
