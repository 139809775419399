/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { notification } from 'antd'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import isEqual from 'lodash.isequal'

import {
  BUSINESS_INDUSTRY,
  BUSINESS_LOCATION,
  BUSINESS_NAME,
  FOUNDED_DATE,
  INCORPORATION_DATE,
  RC_NUMBER,
  YEAR_AT_BIZ,
  BIZ_DESCRIPTION,
  EQUIPMENT_LOAN,
  MEDICATION_LOAN,
  BUSINESS_EMAIL,
  BUSINESS_PHONE_NO,
  NO_OF_EMPLOYEE
} from '../../../sharedComponents/entries/constant'
import { adminServices } from '../../../services'
import { InputField } from '../../../sharedComponents/input-field'
import { Loader } from '../../../sharedComponents'
import DummyInput from '../../../sharedComponents/input-field/DummyInput'

const BusinessInfo = () => {
  const validationSchema = Yup.object().shape({
    buz_name: Yup.string().required('Business name is required'),
    buz_no_of_staff: Yup.number().required('Number of employees is required'),
    buz_incorp_date: Yup.date().required('Incorporation date is required'),
    buz_foundind_year: Yup.date().required('Founded date is required'),
    buz_description: Yup.string(),
    buz_loan_industry: Yup.string().required('Business industry is required'),
    buz_rc_number: Yup.string().required('RC number is required'),
    buz_year: Yup.string().required('Year at business is required'),
    buz_address: Yup.string().required('Business location is required'),
    business_email: Yup.string().required('Business email is required')
  })

  const [shouldEdit, setShouldEdit] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [customeBizInfo, setCustomeBizInfo] = useState({})

  const userIdFromParams = useParams().id

  const updateBtnRef = useRef(null)

  const initialValues = {
    buz_address: customeBizInfo.buz_address,
    buz_description: customeBizInfo.buz_description,
    buz_email: customeBizInfo.buz_email,
    buz_foundind_year: customeBizInfo.buz_foundind_year,
    buz_incorp_date: customeBizInfo.buz_incorp_date,
    buz_loan_industry: customeBizInfo.buz_loan_industry,
    buz_name: customeBizInfo.buz_name,
    buz_no_of_staff: customeBizInfo.buz_no_of_staff,
    buz_phone: customeBizInfo.buz_phone,
    buz_rc_number: customeBizInfo.buz_rc_number,
    buz_year: customeBizInfo.buz_year,
    business_email: customeBizInfo.buz_email
  }

  /**
   * @param {*} values the form values
   * @returns {void}
   */
  const getCustomerDBizInfo = async () => {
    const { data } = await adminServices.getCustomerBizInfo(userIdFromParams)
    if (data.success) {
      setCustomeBizInfo(data.data.business_information)
    } else {
      notification.error({
        message: 'An error occured',
        description: data?.message || 'This User Id does not have a business information'
      })
    }
  }

  const handleSubmit = async customerBusinessInformation => {
    setIsUpdating(true)
    if (isEqual(customerBusinessInformation, initialValues)) {
      setIsUpdating(false)
      notification.warning({
        message: 'Nothing to update',
        description: 'No changes detected'
      })
      return null
    }

    try {
      await adminServices.updateCustomerBizInfo({
        userId: userIdFromParams,
        business_name: customerBusinessInformation.buz_name,
        business_rc_number: customerBusinessInformation.buz_rc_number,
        business_loan_industry: customerBusinessInformation.buz_loan_industry,
        business_incorp_date: customerBusinessInformation.buz_incorp_date,
        business_year: customerBusinessInformation.buz_year,
        business_street_address: customerBusinessInformation.buz_address,
        business_founding_year: customerBusinessInformation.buz_foundind_year,
        business_description: customerBusinessInformation.buz_description,
        business_phone_number: customerBusinessInformation.buz_phone,
        business_num_of_staff: customerBusinessInformation.buz_no_of_staff.toString(),
        business_email: customerBusinessInformation.business_email
      })
      setIsUpdating(false)
      notification.success({
        message: 'Success',
        description: 'Business information updated successfully'
      })
      getCustomerDBizInfo()
    } catch (error) {
      setIsUpdating(false)
      notification.error({
        message: 'An error occured',
        description:
          error?.response?.data?.message ||
          'An error occured while updating the business information'
      })
    }
  }

  const notifyToEdit = () => {
    if (!shouldEdit) {
      updateBtnRef.current.classList.add('shake')
      // Remove the 'shake' class after the animation duration (0.5s in this case)
      setTimeout(() => {
        updateBtnRef.current.classList.remove('shake')
      }, 500)
    }
  }

  useEffect(() => {
    getCustomerDBizInfo()
  }, [])

  return (
    <div className=''>
      <div className='flex items-center justify-end px-8 py-6'>
        <div className='btn-primary bg-transparent border border-blue-600' ref={updateBtnRef}>
          {!shouldEdit ? (
            <button
              onClick={() => setShouldEdit(true)}
              className='text-primary text-lg font-semibold'
            >
              Edit informtion
            </button>
          ) : (
            <label htmlFor='handleSubmit' className='text-primary cursor-pointer'>
              Update information <i className='fas fa-syn' />
            </label>
          )}
        </div>
      </div>
      <div className=''>
        <div className='w-full mx-auto md:px-6 rounded-xl'>
          {isUpdating && (
            <div className='flex flex-col justify-center items-center'>
              <div className='absolute'>
                <Loader width={200} height={200} color='blue' />
              </div>
            </div>
          )}
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ values }) => (
              <Form>
                <div className='flex flex-wrap mx-1 lg:mx-2 md:mx-auto'>
                  <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                    <Field
                      as={shouldEdit ? InputField : DummyInput}
                      type='text'
                      name='buz_name'
                      onClick={notifyToEdit}
                      value={values.buz_name}
                      label={<label className='text-black text-base'>{BUSINESS_NAME}</label>}
                      placeholder={BUSINESS_NAME.slice(0, -1)}
                      className='form-control bg-white'
                    />
                    <ErrorMessage name='buz_name' component='small' className='text-red-500' />
                  </div>
                  <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                    <Field
                      as={shouldEdit ? InputField : DummyInput}
                      type='text'
                      name='business_email'
                      value={values.business_email}
                      label={<label className='text-black text-base'>{BUSINESS_EMAIL}</label>}
                      placeholder={BUSINESS_EMAIL}
                      className='form-control bg-white'
                      onClick={notifyToEdit}
                    />
                    <ErrorMessage
                      name='business_email'
                      component='small'
                      className='text-red-500'
                    />
                  </div>
                  <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                    <Field
                      as={shouldEdit ? InputField : DummyInput}
                      type='text'
                      name='buz_phone'
                      value={values.buz_phone}
                      label={<label className='text-black text-base'>{BUSINESS_PHONE_NO}</label>}
                      placeholder={BUSINESS_PHONE_NO}
                      className='form-control bg-white'
                      onClick={notifyToEdit}
                    />
                    <ErrorMessage name='buz_phone' component='small' className='text-red-500' />
                  </div>
                  <div className='w-full md:w-1/3 md:px-4 mt-8'>
                    <label className='ml-2 text-black text-base leading-8'>
                      {BUSINESS_INDUSTRY}
                    </label>
                    <Field
                      value={values.buz_loan_industry}
                      name='buz_loan_industry'
                      className='form-control bg-white'
                      as={shouldEdit ? 'select' : DummyInput}
                      onClick={notifyToEdit}
                    >
                      {['Select business industry', EQUIPMENT_LOAN, MEDICATION_LOAN].map(
                        (type, id) => (
                          <option key={id} value={type === 'Select business industry' ? '' : type}>
                            {type}
                          </option>
                        )
                      )}
                    </Field>
                    <ErrorMessage
                      name='buz_loan_industry'
                      component='small'
                      className='text-red-500'
                    />
                  </div>
                  <div className='w-full md:w-1/3 md:px-4 mt-8'>
                    <Field
                      type='text'
                      maxLength='15'
                      value={values.buz_rc_number}
                      name='buz_rc_number'
                      label={<label className='text-black text-base'>{RC_NUMBER}</label>}
                      className='form-control bg-white'
                      as={shouldEdit ? InputField : DummyInput}
                      onClick={notifyToEdit}
                    />
                    <ErrorMessage name='buz_rc_number' component='small' className='text-red-500' />
                  </div>
                  <div className='w-full md:w-1/3 md:px-4 mt-8'>
                    <Field
                      type='text'
                      maxLength='15'
                      value={values.buz_no_of_staff}
                      name='buz_no_of_staff'
                      label={<label className='text-black text-base'>{NO_OF_EMPLOYEE}</label>}
                      className='form-control bg-white'
                      as={shouldEdit ? InputField : DummyInput}
                      onClick={notifyToEdit}
                    />
                    <ErrorMessage
                      name='buz_no_of_staff'
                      component='small'
                      className='text-red-500'
                    />
                  </div>
                  <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                    <Field
                      type='text'
                      name='buz_year'
                      value={values.buz_year}
                      maxLength='10'
                      label={<label className='text-black text-base'>{YEAR_AT_BIZ}</label>}
                      className='form-control bg-white'
                      as={shouldEdit ? InputField : DummyInput}
                      onClick={notifyToEdit}
                    />
                    <ErrorMessage name='buz_year' component='small' className='text-red-500' />
                  </div>
                  <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                    <Field
                      type='date'
                      label={<label className='text-black text-base'>{INCORPORATION_DATE}</label>}
                      placeholder={INCORPORATION_DATE}
                      name='buz_incorp_date'
                      value={values.buz_incorp_date}
                      max={new Date().toISOString().slice(0, 10)}
                      className='form-control bg-white'
                      as={shouldEdit ? InputField : DummyInput}
                      onClick={notifyToEdit}
                    />
                    <ErrorMessage
                      name='buz_incorp_date'
                      component='small'
                      className='text-red-500'
                    />
                  </div>
                  <div className='w-full md:w-1/3 md:px-4 mt-8'>
                    <Field
                      type='date'
                      label={<label className='text-black text-base'>{FOUNDED_DATE}</label>}
                      name='buz_foundind_year'
                      value={values.buz_foundind_year}
                      max={new Date().toISOString().slice(0, 10)}
                      className='form-control bg-white'
                      as={shouldEdit ? InputField : DummyInput}
                      onClick={notifyToEdit}
                    />
                    <ErrorMessage
                      name='buz_foundind_year'
                      component='small'
                      className='text-red-500'
                    />
                  </div>
                  <div className='w-full md:w-1/3 md:px-4 mt-8'>
                    <Field
                      type='text'
                      value={values.buz_description}
                      name='buz_description'
                      label={<label className='text-black text-base'>{BIZ_DESCRIPTION}</label>}
                      className='form-control py-8 bg-white'
                      as={shouldEdit ? InputField : DummyInput}
                      onClick={notifyToEdit}
                    />
                    <ErrorMessage
                      name='buz_description'
                      component='small'
                      className='text-red-500'
                    />
                  </div>
                  <div className='w-full md:max-w-sm px-1 md:px-4 mt-8'>
                    <label className='text-black text-base leading-8'>{BUSINESS_LOCATION}</label>
                    <Field
                      name='buz_address'
                      rows='6'
                      value={values.buz_address}
                      className='resize-none border rounded-md w-full max-w-md appearance-none bg-white border-b-2 mr-3 py-2 px-2 leading-tight  mt-1 focus:outline-none  border-gray-400'
                      as={shouldEdit ? 'textarea' : DummyInput}
                      onClick={notifyToEdit}
                    />
                    <ErrorMessage name='buz_address' component='small' className='text-red-500' />
                  </div>
                </div>
                <div className='w-full flex justify-center '>
                  <input hidden id='handleSubmit' type='submit' className='' />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default BusinessInfo
