import { Formik, Form, Field, ErrorMessage } from 'formik'
import React from 'react'
import { InputField } from '../../../../../../sharedComponents/input-field'
import PropTypes from 'prop-types'

const AccountCreation = ({ customeBizInfo }) => {
  return (
    <main>
      <div className='text-black text-base font-bold my-6'>
        Kindly provide the following Information:
      </div>
      <Formik
        initialValues={{
          buz_incorp_date: customeBizInfo?.buz_incorp_date || '',
          buz_name: customeBizInfo?.buz_name || '',
          buz_rc_number: customeBizInfo?.buz_rc_number || '',
          bvn: customeBizInfo?.vfd_data?.bvn || ''
        }}
        enableReinitialize
      >
        <Form>
          <div className='flex flex-wrap mx-1 lg:mx-2 md:mx-auto'>
            <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8'>
              <Field
                as={InputField}
                type='text'
                name='buz_rc_number'
                label={<label className='text-black text-base'>Rc Number</label>}
                placeholder='Enter your RC number'
                className='form-control bg-white'
                disabled
              />
              <ErrorMessage name='buz_rc_number' component='small' className='text-red-500' />
            </div>
            <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8'>
              <Field
                as={InputField}
                type='text'
                name='buz_name'
                label={<label className='text-black text-base'>Company Name</label>}
                placeholder='Company ABC'
                className='form-control bg-white'
                disabled
              />
              <ErrorMessage name='buz_name' component='small' className='text-red-500' />
            </div>
            <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8'>
              <Field
                as={InputField}
                type='text'
                name='buz_incorp_date'
                label={<label className='text-black text-base'>Incorporation Date</label>}
                placeholder={"company's incorporation date"}
                className='form-control bg-white'
                disabled
              />
              <ErrorMessage name='buz_incorp_date' component='small' className='text-red-500' />
            </div>
            <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8'>
              <Field
                as={InputField}
                type='text'
                name='bvn'
                label={<label className='text-black text-base'>BVN</label>}
                placeholder='Your Bank Verification Number'
                className='form-control bg-white'
                disabled
              />
              <ErrorMessage name='bvn' component='small' className='text-red-500' />
            </div>
          </div>
        </Form>
      </Formik>
    </main>
  )
}

AccountCreation.propTypes = {
  customeBizInfo: PropTypes.any
}

export default AccountCreation
