import React, { useState } from 'react'
import {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  PHONE_NO_ONE,
  UPDATE_CHANGES,
  DISTRIBUTORS
} from '../constants'
import { notification } from 'antd'
import { useSelector } from 'react-redux'
import { dispatch } from '../../../redux/store'
import { FormSelect } from '../../../sharedComponents/form-select'

const ProfileForm = () => {
  const { data } = useSelector(({ auth: authUser }) => authUser.authUser.userInfo)

  const loading = useSelector(({ loading }) => loading.effects.auth.profileInfoUpdate)

  const exitingDistributors = useSelector(
    ({ distributors }) => distributors.customerGetDistributors
  )

  const { isAdmin } = useSelector(({ auth: { authUser } }) => ({
    isAdmin: authUser.userInfo?.is_admin
  }))

  const [user, setUser] = useState({
    firstName: data?.first_name,
    lastName: data?.last_name,
    email: data.email,
    phoneNo1: data.phone_number,
    distributor: data.distributor_id
  })

  const onChangeText = e => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const submitFormHandle = () => {
    if (!user.firstName) {
      return notification.error({
        message: 'An error occured',
        description: 'Firstname is required!'
      })
    }
    if (!user.lastName) {
      return notification.error({
        message: 'An error occured',
        description: 'Lastname is required!'
      })
    }
    dispatch.auth.profileInfoUpdate({
      first_name: user.firstName,
      last_name: user.lastName,
      distributor_id: user.distributor
    })
  }

  return (
    <div className='profile-form'>
      <div className=''>
        <div className='flex flex-wrap mb-4'>
          <div className='w-full px-2 mb-6'>
            <small className='text-red-700'>You can only update firstname and lastname **</small>
          </div>
          <div className='w-full  px-2 mb-6'>
            <label className='text-sm text-gray-900 font-semibold'>{FIRST_NAME}</label>
            <input
              className='form-control'
              name='firstName'
              type='text'
              onChange={onChangeText}
              value={user.firstName}
            />
          </div>
          <div className='w-full  px-2 mb-6'>
            <span className='text-sm text-gray-900 font-semibold'>{LAST_NAME}</span>
            <input
              className='form-control'
              name='lastName'
              type='text'
              onChange={onChangeText}
              value={user.lastName}
            />
          </div>

          <div className='w-full  px-2 mb-6'>
            <span className='text-sm text-gray-900  font-semibold'>{EMAIL}</span>
            <input
              className='form-control'
              name='email'
              type='email'
              onChange={onChangeText}
              value={user.email}
              disabled
            />
          </div>
          <div className='w-full  px-2 mb-6'>
            <span className='text-sm text-gray-900  font-semibold'>{PHONE_NO_ONE}</span>
            <input
              className='form-control'
              name='phoneNo1'
              type='text'
              onChange={onChangeText}
              value={user.phoneNo1}
              disabled
            />
          </div>
          {!isAdmin ? (
            <div className='w-full md:w-2/2 px-2 mb-6'>
              <label className='text-sm text-gray-900  font-semibold'>{DISTRIBUTORS}</label>
              <FormSelect
                options={exitingDistributors.map(distributor => ({
                  name: distributor.name,
                  value: distributor.id
                }))}
                value={user.distributor}
                onChange={onChangeText}
                className='form-control '
                label='Select distributor '
                name='distributor'
              />
            </div>
          ) : (
            ''
          )}
          <div className='w-full flex mt-6 mb-8 justify-start'>
            <button className='btn-primary-200' onClick={submitFormHandle}>
              {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
              {UPDATE_CHANGES}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileForm
