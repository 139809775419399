import React from 'react'
import SectionOne from './components/SectionOne'
import SectionTwo from './components/SectionTwo'
import Header from './components/Header'

const Faq = () => {
  return (
    <div className='w-full pb-16 pt-12'>
      <Header />
      <SectionOne />
      <SectionTwo />
    </div>
  )
}

export default Faq
