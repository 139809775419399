/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */

import { notification } from 'antd'
import store from './../redux/store'
import { ALL_ADMIN_ROUTES, FILE_EXTENSIONS, UPLOAD_FILE_TYPES } from './constants'
import moment from 'moment'
import { applyLoanlocalStorageKey } from '../pages/admin-pages/apply-for-loan/constants'
import { Upload } from '../services'
const converter = require('number-to-words')

export const getAllModels = () => {
  return store.getState()
}

export const getSingleModel = model => {
  const currentState = store.getState()
  return currentState?.[model]
}

export const reduxDispatch = () => store.dispatch

export const formatCurrency = value => {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(value)
}

export const formatNumberToNGNCurrency = number => {
  return new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 2
  }).format(number)
}

export const inputFieldformatCurrency = value => {
  const sanitizedValue = value.replace(/[^0-9.]/g, '') // Remove non-numeric characters except dot
  const formattedValue = Number(sanitizedValue).toLocaleString('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 0
  })

  return formattedValue
}

/**
 * @param {*} privileges this is the static privileges constant
 * @param {*} permissions this is the permissions pulled from backend for this particular user
 * @returns {boolean}
 */
export const checkPrivilages = (privileges, permissions = []) => {
  let hasPrivilage = false
  for (const role of permissions) {
    if (privileges?.includes(role)) {
      hasPrivilage = true
      break
    }
  }
  return hasPrivilage
}

export const checkIsAdminRoute = route => {
  return ALL_ADMIN_ROUTES.includes(route)
}

/**
 * A function that calculates percent of any amount and percent given
 * @param {number} rate
 * @param {number} amount
 * @returns number
 */
export const calculateRateInPercent = (rate, amount) => {
  return (rate / 100) * amount
}

export function sortByMonth(arr, year) {
  return arr.sort(function (a, b) {
    return new Date(`${a.x} ${year}`) - new Date(`${b.x} ${year}`)
  })
}

export const copyToClipboard = (
  value,
  message = 'Copied',
  successfully = () => null,
  failure = () => null
) => {
  const clipboard = navigator.clipboard
  if (clipboard !== undefined && clipboard !== 'undefined') {
    navigator.clipboard.writeText(value).then(successfully, failure)
    notification.success({
      message: message,
      type: 'success',
      placement: 'bottomLeft'
    })
  } else {
    if (document.execCommand) {
      const el = document.createElement('input')
      el.value = value
      document.body.append(el)

      el.select()
      el.setSelectionRange(0, value.length)

      if (document.execCommand('copy')) {
        successfully()
      }

      el.remove()
    } else {
      failure()
    }
  }
}

export const convertNumbersTowords = num => {
  return converter.toWords(num)
}

export function validateURL(textval) {
  var urlregex = new RegExp(
    "^(http|https|ftp)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$"
  )
  return urlregex.test(textval)
}

export const capitalizedWord = word => (!word ? '' : word.charAt(0).toUpperCase() + word.slice(1))

/**
 * @function Given an array of disbursed loans, it filters the upcoming loans based on the number days given
 * @param {Array} disbursedLoans the array of the disbursed loans to be filterd
 * @param {number} futureDays the upcoming number of days to consider as upcoming
 * @returns {Array} the array of filtered loans sorted in descending order
 */
export const filterUpcomingPayment = (disbursedLoans = [], futureDays = 7) => {
  return disbursedLoans
    .map(item => {
      const futureDueDayUnix = moment(moment().add(futureDays, 'd').format('YYYY-MM-DD')).unix()
      const nextInterestPaymentDueDateUnix = moment(new Date(item.next_interest_date)).unix()
      let dueSoon, overDue
      if (futureDueDayUnix > nextInterestPaymentDueDateUnix) {
        dueSoon = nextInterestPaymentDueDateUnix - moment().unix() > 0
        overDue = nextInterestPaymentDueDateUnix - moment().unix() < 0
      }
      return { ...item, dueSoon, overDue }
    })
    .filter(item => item.dueSoon || item.overDue)
}

export const formatedDate = date => {
  if (!date || moment(date).format('YYYY-MM-DD HH:MM') === 'Invalid date') {
    return ''
  }

  return moment(date).format('YYYY-MM-DD HH:MM')
}

export function getYearRange(year) {
  // Set the start of the year
  const startOfYear = moment(`${year}-01-01`).startOf('year')

  // Set the end of the year
  const endOfYear = moment(`${year}-12-31`).endOf('year')

  return {
    startOfYear: startOfYear.format('YYYY-MM-DD'),
    endOfYear: endOfYear.format('YYYY-MM-DD')
  }
}

export const checkInterestDefaulted = interest => {
  const expectedDateToReceiveInterest = moment(interest.payment_due_date)
  const interestPaymentDate = moment(interest.payment_date_manual)

  const isDefaulted =
    interestPaymentDate.isAfter(expectedDateToReceiveInterest) && interest.interest_paid > 0
  return isDefaulted
}
/**
 * @description checks if a file is of a valid type
 * @param {File} file the file to be validated
 * @param {Array} fileTypes the array of valid file types
 * @returns {boolean} true if the file is valid, false otherwise
 */
export const validateFileTypes = (
  file,
  fileTypes = ['png', 'jpg', 'pdf', 'xlsx', 'doc', 'docx', 'jpeg', 'webp']
) => {
  if (!file) {
    return false
  }

  const validTypes = fileTypes.map(type => type.toLowerCase())
  const fileType = file?.name?.split('.').pop().toLowerCase()
  return validTypes.includes(fileType)
}

export function calculateAmortization({
  startDate = new Date(),
  loanAmount,
  annualInterestRate = 42,
  loanTermMonths
}) {
  const monthlyInterestRate = annualInterestRate / 100 / 12
  const monthlyPayment =
    (loanAmount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -loanTermMonths))

  const amortizationSchedule = []
  let currentBalance = loanAmount

  for (let month = 1; month <= loanTermMonths; month++) {
    const interestPayment = currentBalance * monthlyInterestRate
    const principalPayment = monthlyPayment - interestPayment
    currentBalance -= principalPayment

    const paymentDate = new Date(startDate)
    paymentDate.setMonth(paymentDate.getMonth() + month - 1)

    amortizationSchedule.push({
      month,
      paymentDate: moment(paymentDate).format('YYYY-MM-DD'),
      payment: monthlyPayment.toFixed(2),
      principal: principalPayment.toFixed(2),
      interest: interestPayment.toFixed(2),
      balance: currentBalance?.toFixed(2),
      key: month
    })
  }

  return amortizationSchedule
}

export function removeCurrencyFormatting(currencyString) {
  // Remove non-numeric characters
  const numericString = currencyString?.replace(/[^0-9.-]/g, '')

  // Parse the numeric string into a number
  const numericValue = parseFloat(numericString)

  return numericValue
}

export function calculateBulletLoanPaymentsWithDetails({
  principal,
  monthlyInterestRate = 3.5 / 100,
  loanTermInMonths,
  startDate = new Date()
}) {
  const loanDetails = []

  let totalPayments = 0
  for (let month = 0; month < loanTermInMonths; month++) {
    const interestPayment = principal * monthlyInterestRate
    totalPayments += interestPayment

    const paymentDate = new Date(startDate)
    paymentDate.setMonth(paymentDate.getMonth() + month)

    loanDetails.push({
      month: month + 1,
      interestPayment: interestPayment.toFixed(2),
      paymentDate: moment(paymentDate).format('YYYY-MM-DD'), // Format as YYYY-MM-DD
      totalPayments: totalPayments.toFixed(2),
      key: month + 1
    })
  }

  return loanDetails
}

// TODO: to be modified and used for searches
/**
 * @description This function is used to search an array of objects or make a call to the API
 * @param {object} param
 * @param {Array} param.array
 * @param {string} param.searchTerm
 * @param {[Func]} param.makeSearchCall
 * @returns {Array<Promise>}
 */
export async function searchArrayOrMakeCallToAPI({ array = [], searchTerm = '', makeSearchCall }) {
  if (!searchTerm) {
    return array
  }

  const searchResults = array.filter(item => {
    for (const key in item) {
      if (
        typeof item[key] === 'string' &&
        item[key].toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return true
      }
    }
    return false
  })

  if (searchResults.length > 0) {
    return searchResults
  } else {
    try {
      if (makeSearchCall.length > 0) {
        const searchPromises = makeSearchCall.map(funcs => funcs(searchTerm))
        const searchResponses = await Promise.all(searchPromises)

        const mergedData = [...searchResponses[0]?.data, ...searchResponses[1]?.data]?.map(data => {
          return {
            ...data
          }
        })
        return mergedData
      } else {
        return []
      }
    } catch (error) {
      console.error('Error fetching data from API:', error)
      return []
    }
  }
}

export function transformBusinessInformation(currentItem, index) {
  const {
    buz_address,
    buz_description,
    buz_email,
    buz_foundind_year,
    buz_incorp_date,
    buz_loan_industry,
    buz_name,
    buz_no_of_staff,
    buz_phone,
    buz_rc_number
  } = currentItem

  return {
    ...currentItem,
    business_description: currentItem?.business_description || buz_description,
    business_founding_year: currentItem?.business_founding_year || buz_foundind_year,
    business_name: currentItem?.business_name || buz_name,
    business_num_employee: currentItem?.business_num_employee || buz_no_of_staff,
    business_loan_industry: currentItem?.business_loan_industry || buz_loan_industry,
    business_phone_number: currentItem?.business_phone_number || buz_phone,
    business_street_address: currentItem?.business_street_address || buz_address,
    business_email: currentItem?.business_email || buz_email,
    business_incorp_date: currentItem?.business_incorp_date || buz_incorp_date,
    buz_rc_number
  }
}

export function getLocalStorageLoanApplication() {
  return JSON.parse(window.localStorage.getItem(applyLoanlocalStorageKey)) || null
}

export function formatStepData(step, stepData) {
  switch (step) {
    case 1:
      return stepData

    case 2: {
      if (stepData) {
        const fileUrls = {}
        const docsIds = []
        stepData?.forEach(item => {
          fileUrls[item.type] = item.id + '|' + item.file_url
          docsIds.push(item.id)
        })
        return { fileUrls, docsIds }
      }

      throw new Error('error getting step 2 data')
    }

    case 3:
      return stepData

    case 4:
      return stepData

    default:
      break
  }
}
// eslint-disable-next-line no-undef

/**
 * A function that uploads file and returns url
 * @param {File} file the file to be uploaded
 * @param {UPLOAD_FILE_TYPES} type the type of file to be uploaded
 * @returns {string} the string url of the uploaded file
 */
export const handleUploadile = async (file, type) => {
  if (!file || !Object.keys(UPLOAD_FILE_TYPES).includes(type)) {
    throw new Error(
      `No file supplied or type of file to upload. The only accepted types are ${JSON.stringify(
        UPLOAD_FILE_TYPES
      )}}`
    )
  }

  if (!validateFileTypes(file, FILE_EXTENSIONS[type])) {
    throw new Error(`Invalid type uploaded ${type} accepted types are ${FILE_EXTENSIONS[type]}`)
  }
  // eslint-disable-next-line no-undef
  const formData = new FormData()
  formData.append('file', file)

  try {
    const { data } = await Upload.uploadFile(formData)
    return data.data.url
  } catch (error) {
    throw new Error(error)
  }
}

/**
 * A function that returns the component if allowed or empty string if not
 * @param {boolean} shouldReturnComponent
 * @param {React.Component} component
 * @returns {React.Component | string} the component or string
 */
export function saftyReturnComponent(shouldReturnComponent = false, component) {
  return shouldReturnComponent ? component : ''
}

export function formatName(fullname) {
  const nameArray = fullname.split(' ')
  const firstInitial = nameArray[0].charAt(0).toUpperCase()
  const lastInitial = nameArray.length > 1 ? nameArray[1].charAt(0).toUpperCase() : ''

  return firstInitial + lastInitial
}

export function maskString(inputString) {
  if (typeof inputString !== 'string') {
    throw new Error('Input must be a string')
  }

  const length = inputString.length

  if (length < 8) {
    throw new Error('String length must be at least 8 characters')
  }

  const maskedString =
    inputString.substring(0, 4) + '*'.repeat(4) + inputString.substring(length - 4)

  return maskedString
}
