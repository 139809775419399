/* eslint-disable camelcase */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react'
import { Table, DatePicker, Tooltip } from 'antd'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import { customerModuleColumns } from './constants'
import { useSelector } from 'react-redux'
import { dispatch } from '../../../redux/store'
import { capitalizedWord } from '../../../utils/helpers'
import _debounce from 'lodash/debounce'
import ReactPaginate from 'react-paginate'

const CustomerModule = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const searchInputRef = React.useRef(null)
  const [dataSource, setDataSource] = useState([])
  const [searchText, setSearchText] = useState(null)
  const [filterDateRange, setFilter] = useState(null)

  const { usersList, userLoading, isServerError, pagination } = useSelector(
    ({ loading, users }) => ({
      userLoading: loading.effects.users.getAllusers,
      usersList:
        users?.paginatedUsers
          ?.map(({ first_name, last_name, documents, business_name, id, ...more }) => ({
            name: capitalizedWord(first_name + ' ' + last_name),
            documents,
            first_name,
            last_name,
            key: id,
            id,
            business_name: capitalizedWord(business_name) || 'N/A',
            ...more
          }))
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) || [],
      pagination: users?.pagination,
      isServerError: users.isServerError
    })
  )

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const hasSelected = selectedRowKeys.length > 0

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const handleSearchDate = async (_, date) => {
    if (!date || !_) {
      return resetFilters()
    }

    setFilter(date)

    await dispatch.users.getAllusers({
      startDate: date[0],
      endDate: date[1]
    })
  }

  const handleSearch = async ({ target }) => {
    setSearchText(target.value)
    await dispatch.users.getAllusers({
      search: target.value
    })
  }

  const searchUserDebounce = _debounce(handleSearch, 700)

  const handlePageClick = event => {
    const page = event.selected + 1
    dispatch.users.getAllusers({
      page,
      startDate: filterDateRange ? filterDateRange[0] : undefined,
      endDate: filterDateRange ? filterDateRange[1] : undefined,
      search: searchText ? searchText : undefined
    })
  }

  const resetFilters = () => {
    setSearchText('')
    setFilter(null)
    dispatch.users.getAllusers()
  }

  useEffect(() => {
    if ((!usersList?.length || isServerError) && !userLoading) {
      dispatch.users.getAllusers()
    }
    if (!userLoading && Boolean(usersList?.length)) {
      setDataSource(usersList)
    }
  }, [userLoading])

  return (
    <DashboardLayout>
      <div className='m-5'>
        <div className='mb-10'>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-4 text-lg  '>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-1 text-lg '>
              <div className='relative mt-6  rounded-md mr-6 ml-0 col-span-3'>
                <div className='absolute top-4 left-2 pl-2'>
                  <button onClick={() => searchInputRef.current.focus()}>
                    <i className='fa fa-search text-gray-400 z-20 hover:text-gray-500' />
                  </button>
                </div>
                <div>
                  <input
                    type='text'
                    ref={searchInputRef}
                    onChange={searchUserDebounce}
                    className='h-14 w-full pl-10 border pr-10 rounded-md z-0 focus:shadow focus:outline-none'
                    placeholder='Search ID, Business name...'
                  />
                </div>
              </div>
            </div>
            <div className='flex mt-6'>
              <span className=' mt-4'>Filter by:</span>
              <div className='relative flex border rounded-md  ml-3'>
                <DatePicker.RangePicker
                  type='date'
                  onChange={handleSearchDate}
                  className=' h-14 w-60 pl-2 pr-8 text-center rounded-md z-0 focus:shadow focus:outline-none'
                />
              </div>
            </div>
            <button
              className='btn-primary border-primary border hover:bg-primary text-primary px-8 py-2 flex w-17 mt-6 mx-auto self-center justify-center hover:text-white'
              onClick={resetFilters}
            >
              Reset
            </button>
          </div>
        </div>
        <div>
          {hasSelected ? (
            <span className=' text-white bg-blue-800 mt-10  px-8 py-3 rounded z-10 mx-80 text-lg text-center justify-center'>
              {`${selectedRowKeys.length} Customers Selected`}
              <Tooltip title='Coming soon'>
                <span className='text-red-600 pl-3 cursor-pointer'> Add to blacklist</span>
              </Tooltip>
            </span>
          ) : (
            ''
          )}
        </div>
        <Table
          rowClassName=''
          className='mt-5'
          rowSelection={rowSelection}
          columns={customerModuleColumns}
          dataSource={dataSource}
          loading={userLoading}
          pagination={false}
        />
        <ReactPaginate
          breakLabel='...'
          className='react-paginate'
          nextLabel='next >'
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pagination?.total_pages}
          previousLabel='< previous'
          renderOnZeroPageCount={null}
        />
      </div>
    </DashboardLayout>
  )
}
export default CustomerModule
