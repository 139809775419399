/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { DatePicker, Dropdown, Popconfirm, Select, Spin, notification } from 'antd'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import { menu } from './InterestTracker'
import { LOAN_STATUSES_COLOUR_AND_NAME, months } from './constants'
import {
  copyToClipboard,
  formatNumberToNGNCurrency,
  formatedDate,
  getYearRange
} from '../../../utils/helpers'
import { dispatch } from '../../../redux/store'
import { pastSixMonths, todaysDate } from '../../../utils/dateRange'
import { Loader } from '../../../sharedComponents'
import copyIcon from '../../../images/icons/copyicon.svg'

const InterestPaymentDetails = () => {
  const [interestPaymentTrackerDetails, setInteresPaymentTrackerDetails] = useState(null)
  const [currentInterest, setcurrentInterest] = useState([])
  const [shouleEdit, setShouldEdit] = useState(false)
  const [selectedInterestDetails, setSelectedInterestDetails] = useState({
    interest_paid: '',
    payment_date_manual: '',
    required_payment: '',
    payment_due_date: ''
  })
  const [paymentDetails, setPaymentDetails] = useState({
    payment_date_manual: '',
    required_interest: '',
    payment_due_date: '',
    new_pay_amount: '',
    payment_id: ''
  })

  const {
    loadingDeleteInterestPayment,
    loadingEditInterestPayment,
    userInterestPaymentTrackerLoading
  } = useSelector(({ loading }) => ({
    loadingDeleteInterestPayment: loading.effects.payments.deleteInterestPayment,
    loadingEditInterestPayment: loading.effects.payments.updatePaymentsDetails,
    userInterestPaymentTrackerLoading: loading.effects.payments.interestTrackerDetails
  }))
  const history = useHistory()
  const params = useParams()

  const handleChange = ({ target }) => {
    setPaymentDetails({ ...paymentDetails, [target.name]: target.value })
  }

  const onDatePickerChange = (_, year) => {
    const yearRange = getYearRange(year)

    getInterestPaymentTrackerDetails({
      user_id: params.userId,
      start: yearRange.startOfYear,
      end: yearRange.endOfYear
    })
  }

  const handleEditInterest = async () => {
    const details = {
      ...paymentDetails
    }

    for (const key in details) {
      if (!details[key]) {
        delete details[key]
      } else {
        details[key] = String(details[key])
      }
    }
    await dispatch.payments.updatePaymentsDetails(details)
    getInterestPaymentTrackerDetails()
    setShouldEdit(false)
    setPaymentDetails({
      payment_date_manual: '',
      required_interest: '',
      payment_due_date: '',
      new_pay_amount: '',
      payment_id: ''
    })
  }

  const handleDeleteInterest = async () => {
    await dispatch.payments.deleteInterestPayment({
      payment_id: paymentDetails.payment_id
    })

    getInterestPaymentTrackerDetails()
  }

  /**
   * @description This function return the interest paid for each loan
   * @returns {string} interest paid
   */
  const getInterestPaidForGivenLoan = () => {
    if (currentInterest.length > 0) {
      return currentInterest.reduce((acc, curr) => {
        return acc + (Number(curr?.interest_paid) || 0)
      }, 0)
    }
  }

  /**
   * @description This function handles the select event
   */
  async function getInterestPaymentTrackerDetails(detailsInput) {
    const details = {
      user_id: params.userId,
      start: pastSixMonths,
      end: todaysDate
    }

    const interestTrackerDetails =
      (await dispatch.payments.interestTrackerDetails(detailsInput || details)) || {}
    setInteresPaymentTrackerDetails(interestTrackerDetails)
    handleSelect(Object.keys(interestTrackerDetails)[0], interestTrackerDetails)
  }
  /**
   * @description This function return the business name
   * @returns {string} business name
   */
  const getBusinessName = () => {
    if (interestPaymentTrackerDetails) {
      for (const [key, value] of Object.entries(interestPaymentTrackerDetails)) {
        if (value?.length > 0) {
          return value[0].business_name
        }
      }
    }
  }

  const getDifferenceInStatus = currentInterestPayment => {
    const paymentDueDate = moment(currentInterestPayment?.payment_due_date)
    const paymentDateManual = moment(currentInterestPayment?.payment_date_manual)
    const status = ''
    switch (currentInterestPayment?.payment_status) {
      case 'Delayed':
        const difference = paymentDateManual.diff(paymentDueDate, 'days')
        return `${difference} days delayed`
      case 'Late Payment':
        const differenceLate = paymentDateManual.diff(paymentDueDate, 'days')
        return `${differenceLate} days late`
      case 'Pending':
        const differencePending = paymentDueDate.diff(paymentDateManual, 'days')
        return `${differencePending} days pending`
      case 'Partial Defaulted':
        const differencePartialDefaulted = paymentDateManual.diff(paymentDueDate, 'days')
        return `${differencePartialDefaulted} days partial defaulted`

      case 'Over paid':
        const differenceOverPaid =
          Number(currentInterestPayment?.interest_paid) -
          Number(currentInterestPayment?.required_payment)
        console.log(differenceOverPaid, 'differenceOverPaid')
        return `${formatNumberToNGNCurrency(differenceOverPaid)} over paid`
      case 'Under paid':
        const differenceUnderPaid =
          Number(currentInterestPayment?.required_payment) -
          Number(currentInterestPayment?.interest_paid)
        return `${formatNumberToNGNCurrency(differenceUnderPaid)} under paid`

      case 'Defaulted':
        const differenceDefaulted = paymentDateManual.diff(paymentDueDate, 'days')
        return `${differenceDefaulted} days defaulted`
      case 'Paid on time':
        return ''
      case 'Not paid':
        const differenceNotPaid = paymentDueDate.diff(paymentDateManual, 'days')
        return `${differenceNotPaid} days remaining`
      default:
        break
    }
    return status
  }

  /**
   * @description This function handles the select event of the current interest to be displayed
   * @param {string} loanId which is a key in the interestPaymentTrackerDetails object
   * @param {object} interestDetails which is the interestPaymentTrackerDetails
   * @returns {void}
   * @example
   * handleSelect('loanId', interestDetails)
   */
  const handleSelect = (loanId, interestDetails) => {
    setSelectedInterestDetails({
      interest_paid: '',
      payment_date_manual: '',
      required_payment: '',
      payment_due_date: ''
    })
    setShouldEdit(false)
    const updateMonthsWithInterest = months.map(month => {
      const interest = interestDetails[loanId]?.find(
        item => item?.payment_month?.toLowerCase() === month.value.toLowerCase()
      )

      if (interest) {
        return { ...month, ...interest }
      }
      return month
    })
    setcurrentInterest(updateMonthsWithInterest)
  }

  /**
   * @description This function returns the total amount by given status
   * @param {string} status
   * @returns {number} total amount by given status
   */
  const getTotalAmountByStatus = status => {
    if (currentInterest.length > 0) {
      const amountToBePaid = currentInterest.reduce((acc, curr) => {
        if (curr?.payment_status === status) {
          return acc + (Number(curr?.required_payment) || 0)
        }
        return acc
      }, 0)

      const amountPaid = currentInterest.reduce((acc, curr) => {
        if (curr?.payment_status === status) {
          return acc + (Number(curr?.interest_paid) || 0)
        }
        return acc
      }, 0)
      return amountToBePaid - amountPaid
    }
  }

  useEffect(() => {
    getInterestPaymentTrackerDetails()
  }, [])

  return (
    <DashboardLayout>
      {!userInterestPaymentTrackerLoading ? (
        <main className='mb-10'>
          <div className='flex justify-between'>
            <div className='ml-1 mt-4'>
              <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
                <i className='fas fa-arrow-left mr-3' />
                Back
              </h3>
            </div>
            <div className='mt-2 mr-3'>
              <Dropdown
                menu={menu(
                  (interestPaymentTrackerDetails &&
                    interestPaymentTrackerDetails[selectedInterestDetails?.loan_id]) ||
                    []
                )}
                placement='bottomRight'
                arrow
              >
                <button className='flex-end btn-primary-200 rounded md:ml-52'>EXPORT</button>
              </Dropdown>
            </div>
          </div>
          {interestPaymentTrackerDetails && (
            <>
              <div className='w-full md:w-1/3 my-10'>
                <Select
                  onSelect={id => handleSelect(id, interestPaymentTrackerDetails)}
                  className='w-full px-5'
                  placeholder='Select Loan ID'
                >
                  <Select.Option
                    className=' border-b text-bold'
                    style={{
                      fontSize: '18px',
                      fontWeight: 'bold',
                      color: 'black'
                    }}
                    value=''
                    disabled
                  >
                    {getBusinessName()}
                  </Select.Option>
                  {Object.entries(interestPaymentTrackerDetails)?.map(([key, value], idx) => {
                    const id = value[0]?.unique_loan_key || key
                    return (
                      <Select.Option key={idx} value={key} className='pl-5 my-0 border-b py-2'>
                        <img
                          src={copyIcon}
                          className='inline pr-2'
                          onClick={() => copyToClipboard(id, 'Loan Unique key copied')}
                        />{' '}
                        {id}
                      </Select.Option>
                    )
                  })}
                </Select>
              </div>
              <div className='md:flex  gap-4'>
                <div className='grid bg-white border-2 py-6 px-7 rounded-md '>
                  <span>Total interest paid </span>
                  <span className='text-2xl font-bold text-green-700'>
                    {formatNumberToNGNCurrency(getInterestPaidForGivenLoan() || 0)}
                  </span>
                </div>
                <div className='grid bg-white border-2 py-6 px-9 rounded-md'>
                  <span>Total Delayed </span>
                  <span className='text-2xl text-delayed font-bold'>
                    {formatNumberToNGNCurrency(getTotalAmountByStatus('Delayed'))}
                  </span>
                </div>
                <div className='grid bg-white border-2 py-6 px-8 rounded-md'>
                  <span className=''>Total Defaulted </span>
                  <span className='text-2xl text-defaulted font-bold'>
                    {formatNumberToNGNCurrency(getTotalAmountByStatus('Defaulted'))}
                  </span>
                </div>
                <div className='grid bg-white border-2 py-6 px-8 rounded-md'>
                  <span className=''> Total Not Paid </span>
                  <span className='text-2xl text-notPaid font-bold'>
                    {formatNumberToNGNCurrency(getTotalAmountByStatus('Defaulted'))}
                  </span>
                </div>
              </div>
              <div className='md:flex gap-6 my-10'>
                <div className='bg-white rounded-md sm:w-full lg:w-3/4'>
                  <div className='md:flex justify-between items-center px-5'>
                    <div className='w-full md:w-2/6 px-2 my-10'>
                      <DatePicker
                        className='form-control outline-none '
                        onChange={onDatePickerChange}
                        picker='year'
                      />
                    </div>
                    <div className='pr-8 mt-3  lg:pr-10 md:flex gap-2'>
                      <p className=''>Status</p>
                      <p
                        className={`${
                          LOAN_STATUSES_COLOUR_AND_NAME[selectedInterestDetails?.payment_status]
                        } h-6 px-2 rounded-md text-center`}
                      >
                        {selectedInterestDetails?.payment_status}
                      </p>
                      <span className='pl-2 block'>
                        {selectedInterestDetails.payment_due_date &&
                          getDifferenceInStatus(selectedInterestDetails)}
                      </span>
                    </div>
                  </div>
                  <div className='grid gap-5 mx-5 my-5 grid-cols-3'>
                    {currentInterest.map((interest, idx) => {
                      return (
                        <div
                          className={`${
                            LOAN_STATUSES_COLOUR_AND_NAME[interest?.payment_status]
                          } w-28 border rounded-3xl p-2 text-center cursor-pointer ${
                            interest?.payment_id === selectedInterestDetails?.payment_id &&
                            interest?.payment_id !== undefined
                              ? ' border-blue-700 border-4 outline-black'
                              : ''
                          }`}
                          title='Click to view interest details'
                          key={idx}
                          onClick={() => {
                            const payment_due_date = formatedDate(interest?.payment_due_date)
                            const payment_date_manual = formatedDate(interest?.payment_date_manual)
                            setShouldEdit(false)
                            if (interest?.payment_status) {
                              setSelectedInterestDetails(prev => ({
                                ...prev,
                                ...interest,
                                payment_due_date,
                                payment_date_manual
                              }))

                              setPaymentDetails({
                                payment_date_manual,
                                required_interest: interest?.required_payment,
                                payment_due_date,
                                new_pay_amount: interest?.interest_paid,
                                payment_id: interest?.payment_id
                              })
                            } else {
                              setSelectedInterestDetails({
                                interest_paid: '',
                                payment_date_manual: '',
                                required_payment: '',
                                payment_due_date: ''
                              })
                              setPaymentDetails({
                                payment_date_manual: '',
                                required_interest: '',
                                payment_due_date: '',
                                new_pay_amount: '',
                                payment_id: ''
                              })
                            }
                          }}
                        >
                          <span>{interest.text}</span>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div
                  className={` mr-3 rounded-md md:w-1/2 w-full ${
                    selectedInterestDetails?.payment_id
                      ? `shadow-lg outline-black ${
                          LOAN_STATUSES_COLOUR_AND_NAME[selectedInterestDetails?.payment_status]
                        }`
                      : 'bg-white'
                  }`}
                >
                  <p className='mx-5 my-5 text-lg font-bold'>Interest details</p>
                  <div className='flex justify-between mx-5 my-5'>
                    <div>Only authorized role can edit</div>
                    <div className='border rounded-sm border-blue-800 px-2 py-1 shadow'>
                      <button
                        className={`${
                          selectedInterestDetails?.payment_id ? 'text-white' : 'text-blue-800'
                        }`}
                        onClick={() => {
                          let showErrorMessage = true
                          for (const key in selectedInterestDetails) {
                            if (selectedInterestDetails[key]) {
                              setShouldEdit(true)
                              showErrorMessage = false
                              break
                            }
                          }
                          if (showErrorMessage) {
                            setShouldEdit(false)
                            notification.warning({
                              message: 'Warning',
                              description: 'Please select a month to edit',
                              placement: 'bottomRight',
                              duration: 2
                            })
                          }
                        }}
                      >
                        Edit Interest
                      </button>
                    </div>
                  </div>
                  <div className='grid grid-cols-2 mx-4 gap-5'>
                    <div className='w-full md:w-12/12 px-2 mb-6'>
                      <span
                        className='text-sm text-gray-900  font-semibold'
                        title='The actual interest paid by the customer for this current month'
                      >
                        Interest Paid
                      </span>
                      <input
                        className='form-control'
                        type='text'
                        name='new_pay_amount'
                        defaultValue={selectedInterestDetails?.interest_paid}
                        onChange={handleChange}
                        disabled={!shouleEdit}
                        placeholder='Interest Paid'
                      />
                    </div>
                    <div className='w-full md:w-12/12 px-2 mb-6'>
                      <span
                        title='The date and time the interest was paid by the customer'
                        className='text-sm text-gray-900  font-semibold'
                      >
                        Date & Time Paid
                      </span>
                      <input
                        className='form-control'
                        name='payment_date_manual'
                        defaultValue={selectedInterestDetails?.payment_date_manual}
                        type='datetime-local'
                        onChange={handleChange}
                        disabled={!shouleEdit}
                        placeholder='Date & Time Paid'
                      />
                    </div>
                    <div className='w-full md:w-12/12 px-2 mb-3'>
                      <span
                        className='text-sm text-gray-900  font-semibold'
                        title='The expected interest the customer is suppose to pay for this month'
                      >
                        Required Interest
                      </span>
                      <input
                        name='required_interest'
                        className='form-control'
                        type='text'
                        defaultValue={selectedInterestDetails?.required_payment}
                        onChange={handleChange}
                        disabled={!shouleEdit}
                        placeholder='Required Interest'
                      />
                    </div>
                    <div className='w-full md:w-12/12 px-2 mb-3'>
                      <span
                        className='text-sm text-gray-900  font-semibold'
                        title='The expected date the customer is suppose to pay'
                      >
                        Expected Date & Time
                      </span>
                      <input
                        className='form-control'
                        name='payment_due_date'
                        onChange={handleChange}
                        disabled={!shouleEdit}
                        defaultValue={selectedInterestDetails?.payment_due_date}
                        type='datetime-local'
                        placeholder='Expected Date & Time'
                      />
                    </div>
                  </div>
                  <div className='flex justify-center my-8 gap-5'>
                    <Popconfirm
                      title='Are you sure to delete this payment?'
                      onCancel={() => {}}
                      onConfirm={handleDeleteInterest}
                      okText='Yes'
                      cancelText='No'
                      disabled={!paymentDetails.payment_id || !shouleEdit}
                    >
                      <button
                        disabled={!paymentDetails.payment_id || !shouleEdit}
                        className={`rounded-sm border border-blue-800 px-6 py-1 shadow text-blue-800 ${
                          (!paymentDetails.payment_id || !shouleEdit) &&
                          'border-opacity-50 text-opacity-50'
                        }`}
                      >
                        <i className='fas fa-trash ml-2' /> Delete
                        {loadingDeleteInterestPayment && (
                          <i className='fas fa-spinner fa-spin ml-2' />
                        )}
                      </button>
                    </Popconfirm>
                    <div>
                      <button
                        onClick={handleEditInterest}
                        disabled={!paymentDetails.payment_id || !shouleEdit}
                        className={`rounded-sm border border-blue-700 bg-blue-700 px-7 py-1 shadow text-white ${
                          (!paymentDetails.payment_id || !shouleEdit) &&
                          'bg-opacity-50 border-opacity-40'
                        }`}
                      >
                        <i className='fas fa-save ml-2' /> Save{' '}
                        {loadingEditInterestPayment && (
                          <i className='fas fa-spinner fa-spin ml-2' />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </main>
      ) : (
        <div className='w-full mx-auto mt-20'>
          <Loader color='blue' />
        </div>
      )}
    </DashboardLayout>
  )
}

export default InterestPaymentDetails
