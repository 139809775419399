/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react'
import ProfilePicture from './ProfilePicture'
import { useSelector } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { InputField } from '../../../sharedComponents/input-field'
import * as Yup from 'yup'
import { BUSINESS_PHONE_NO } from '../../../sharedComponents/entries/constant'
import PasswordResetModal from './PasswordResetModal'
import {
  useLocation,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min'
import { dispatch } from '../../../redux/store'
import { Spin, notification } from 'antd'
import { Loader } from '../../../sharedComponents'
import { adminServices } from '../../../services'

const PersonalInfo = () => {
  const [isResetModalOpen, setIsResetModalOpen] = useState(false)
  const [shouldEdit, setShouldEdit] = useState(false)
  const [profileUrl, setProfileUrl] = useState('')
  const [userData, setUserData] = useState(null)
  const [gettingUserData, setGettingUserData] = useState(true)

  const { isAdmin, userUpdating } = useSelector(
    ({ loading, auth: { authUser } }) => ({
      isAdmin: authUser.userInfo?.is_admin,
      data: authUser.userInfo?.data,
      userUpdating: loading.effects.users.updateUser
    })
  )

  const userId = useParams().id

  const initialValues = {
    first_name: userData?.first_name || '',
    last_name: userData?.last_name || '',
    email: userData?.email || '',
    phone_number: userData?.phone_number || '',
    state_of_origin: userData?.state_of_origin || '',
    local_govt: userData?.local_govt || '',
    nin: userData?.nin || '',
    bvn: userData?.bvn || '',
    dob: userData?.dob || '',
    gender: userData?.gender || ''
  }

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone_number: Yup.string().required('Phone number is required'),
    state_of_origin: Yup.string().required('State of origin is required'),
    local_govt: Yup.string().required('Local government is required'),
    bvn: Yup.string().length(11).required('BVN is required'),
    nin: Yup.string().length(11).required('NIN is required'),
    dob: Yup.date().nullable().required('Date of Birth is required'),
    gender: Yup.string().required('Gender is required')
  })

  /**
   * @param {*} values the form values
   * @returns {void}
   */
  const handleResetPasswordClick = () => {
    setIsResetModalOpen(true)
  }

  const handleCloseResetModal = () => {
    setIsResetModalOpen(false)
  }

  const handleSubmit = async (values) => {
    await dispatch.users.updateUser({
      ...values,
      photo: profileUrl || userData?.photo || '',
      user_id: userData?.id
    })
    setShouldEdit(false)
    setProfileUrl('')
  }

  async function getCurrentUserData() {
    try {
      const { data } = await adminServices.getUserInfo(userId)
      setUserData(data.data)
      setGettingUserData(false)
    } catch (error) {
      setGettingUserData(false)
      notification.error({
        message: 'Error getting user data',
        description: error?.response?.data?.message || error.message
      })
    }
  }

  useEffect(() => {
    getCurrentUserData()
  }, [userId])

  return (
    <div className=''>
      <div className='absolute top-1/3 w-full mx-auto'>
        {gettingUserData && <Loader color='blue' height='200px' width='200px' />}
      </div>
      <div className='flex items-center justify-between px-8 py-6'>
        <div>
          <ProfilePicture
            photo={userData?.photo}
            setProfileUrl={setProfileUrl}
          />
        </div>
        <div
          className={`${
            shouldEdit ? 'shadow-lg' : 'bg-transparent'
          } btn-primary border border-blue-600`}
        >
          {!shouldEdit ? (
            <button
              onClick={() => setShouldEdit(true)}
              className='text-primary text-lg font-semibold'
            >
              Edit information
            </button>
          ) : (
            <label
              htmlFor='handleSubmit'
              className='text-primary cursor-pointer'
            >
              Save Edits{' '}
              {userUpdating ? (
                <Spin size='small' />
              ) : (
                <i className='fas fa-save' />
              )}
            </label>
          )}
        </div>
      </div>
      <div className=''>
        <div className='w-full mx-auto md:px-6 rounded-xl'>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            <Form>
              <div className='flex flex-wrap mx-1 lg:mx-2 md:mx-auto'>
                <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                  <Field
                    as={InputField}
                    type='text'
                    name='first_name'
                    label={
                      <label className='text-black text-base'>First Name</label>
                    }
                    placeholder='First Name'
                    className='form-control bg-white'
                    disabled={!shouldEdit}
                  />
                  <ErrorMessage
                    name='first_name'
                    component='small'
                    className='text-red-500'
                  />
                </div>
                <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                  <Field
                    as={InputField}
                    type='text'
                    name='last_name'
                    label={
                      <label className='text-black text-base'>Last Name</label>
                    }
                    placeholder='Last Name'
                    className='form-control bg-white'
                    disabled={!shouldEdit}
                  />
                  <ErrorMessage
                    name='last_name'
                    component='small'
                    className='text-red-500'
                  />
                </div>
                <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                  <Field
                    as={InputField}
                    type='email'
                    name='email'
                    title='You are not allowed to edit users email'
                    label={
                      <label className='text-black text-base'>Email</label>
                    }
                    placeholder='Email'
                    className='form-control bg-white cursor-not-allowed'
                    disabled
                  />
                  <ErrorMessage
                    name='email'
                    component='small'
                    className='text-red-500'
                  />
                </div>
                <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                  <Field
                    as={InputField}
                    type='text'
                    name='phone_number'
                    maxLength='15'
                    label={
                      <label className='text-black text-base'>
                        Phone Number
                      </label>
                    }
                    placeholder={BUSINESS_PHONE_NO.slice(0, -1)}
                    className='form-control bg-white'
                    disabled={!shouldEdit}
                  />
                  <ErrorMessage
                    name='phone_number'
                    component='small'
                    className='text-red-500'
                  />
                </div>
                <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                  <Field
                    as={InputField}
                    type='text'
                    name='bvn'
                    maxLength='15'
                    label={<label className='text-black text-base'>BVN</label>}
                    placeholder='BVN'
                    className='form-control bg-white'
                    disabled={!shouldEdit}
                  />
                  <ErrorMessage
                    name='bvn'
                    component='small'
                    className='text-red-500'
                  />
                </div>

                <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                  <Field
                    as={InputField}
                    type='text'
                    name='state_of_origin'
                    maxLength='15'
                    label={
                      <label className='text-black text-base'>
                        State of Origin
                      </label>
                    }
                    placeholder='State of origin'
                    className='form-control bg-white'
                    disabled={!shouldEdit}
                  />
                  <ErrorMessage
                    name='state_of_origin'
                    component='small'
                    className='text-red-500'
                  />
                </div>

                <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                  <Field
                    as={InputField}
                    type='text'
                    name='local_govt'
                    maxLength='15'
                    label={
                      <label className='text-black text-base'>
                        Local Government
                      </label>
                    }
                    placeholder='Local government'
                    className='form-control bg-white'
                    disabled={!shouldEdit}
                  />
                  <ErrorMessage
                    name='local_govt'
                    component='small'
                    className='text-red-500'
                  />
                </div>

                <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                  <Field
                    as={InputField}
                    type='text'
                    name='nin'
                    maxLength='15'
                    label={<label className='text-black text-base'>NIN</label>}
                    placeholder='NIN'
                    className='form-control bg-white'
                    disabled={!shouldEdit}
                  />
                  <ErrorMessage
                    name='nin'
                    component='small'
                    className='text-red-500'
                  />
                </div>
                <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                  <Field
                    as={InputField}
                    type='date'
                    name='dob'
                    label={<label className='text-black text-base'>Date of Birth</label>}
                    placeholder='Date of Birth'
                    className='form-control bg-white'
                    disabled={!shouldEdit}
                  />
                  <ErrorMessage
                    name='dob'
                    component='small'
                    className='text-red-500'
                  />
                </div>
                <div className='flex w-full items-center '>

                  <div className='md:w-1/3  max-w-4xl sm:-1/2  md:px-4 mt-8'>
                    <label className='text-black text-base'>Gender</label>
                    <Field
                      as='select'
                      name='gender'
                      className='form-control bg-white'
                      disabled={!shouldEdit}
                    >
                      <option value='' label='Select Gender' />
                      <option value='male' label='Male' />
                      <option value='female' label='Female' />
                      <option value='other' label='Other' />
                    </Field>
                    <ErrorMessage
                      name='gender'
                      component='small'
                      className='text-red-500'
                    />
                  </div>
                  <div className='md:w-1/3 sm:-1/2  md:px-4 mt-8'>
                    <div className='relative'>
                      <Field
                        as={InputField}
                        type='password'
                        name='password'
                        label={
                          <label className='text-black text-base'>Password</label>
                        }
                        className='form-control bg-white pr-10'
                        disabled={!shouldEdit}
                      />
                      <span className='absolute inset-y-0 right-0 top-8 cursor-pointer flex items-center pr-3'>
                        <button
                          type='button'
                          className=' focus:outline-none cursor-pointer'
                          onClick={handleResetPasswordClick}
                        >
                        Reset Password
                        </button>
                      </span>
                    </div>
                    <span className='block text-xs text-red-900'>
                    Click this only if you want to reset this users password
                    </span>
                    <ErrorMessage
                      name='password'
                      component='small'
                      className='text-red-500'
                    />
                  </div>
                </div>
              </div>
              <div className='w-full flex justify-center'>
                <input
                  hidden={!profileUrl}
                  id='handleSubmit'
                  value='Save'
                  type='submit'
                  className='bg-primary px-4 py-2 text-white text-base rounded-md cursor-pointer'
                />{' '}
                {userUpdating && <Loader color='blue' width={20} height={20} />}
              </div>
            </Form>
          </Formik>
        </div>
      </div>
      {/* Render the PasswordResetModal if isResetModalOpen is true */}
      {isResetModalOpen && (
        <PasswordResetModal onClose={handleCloseResetModal} />
      )}
    </div>
  )
}

export default PersonalInfo
