import React, { useState, useRef } from 'react'
import exclamationIcon from '../../../images/icons/exclamation.svg'
import ApplyForLoan from './ApplyForLoan'
import LoanBasicFaq from './LoanBasicFaq'
import MaintaingALoan from './MaintaingALoan'
import 'antd/dist/antd.css'
import {
  APPLY_FOR_LOAN_HEADER,
  APPLY_FOR_LOAN,
  LOAN_BASICS_HEADER,
  LOAN_BASICS,
  MAINTAINING_A_LOAN_HEADER,
  MAINTAINING_A_LOAN
} from '../constants'

const SectionTwo = () => {
  const [currentFaq, setCurrentFaq] = useState(1)
  const faqRef = useRef()

  const changeCurrentFaq = id => {
    if (faqRef.current) {
      faqRef.current.style.opacity = 0
      setTimeout(() => {
        setCurrentFaq(id)
        faqRef.current.style.opacity = 1
      }, 300)
    }
  }

  return (
    <section>
      <div className='container mx-auto md:px-16 px-3'>
        <div className='grid grid-cols-1 md:grid-cols-12 gap-10 mt-10'>
          <div className='md:col-span-5'>
            <div
              className={`${
                currentFaq === 1 ? 'bg-primary' : 'bg-info'
              } mb-5 p-5 rounded-lg cursor-pointer duration-150`}
              onClick={() => changeCurrentFaq(1)}
            >
              <div className='flex'>
                <div className='tab-items-lg'>
                  <figure className='tab-items'>
                    <img src={exclamationIcon} alt='exclamation' />
                  </figure>
                </div>
                <div>
                  <h4
                    className={`${
                      currentFaq === 1 ? 'text-white' : 'text-black'
                    } text-xl font-semibold`}
                  >
                    {LOAN_BASICS_HEADER}
                  </h4>
                  <p className={`${currentFaq === 1 ? 'text-white' : 'text-gray-900'} `}>
                    {LOAN_BASICS}
                  </p>
                </div>
              </div>
            </div>

            <div
              className={`${
                currentFaq === 3 ? 'bg-primary' : 'bg-info'
              } mb-5 p-5 rounded-lg cursor-pointer  duration-150`}
              onClick={() => changeCurrentFaq(3)}
            >
              <div className='flex'>
                <div className='tab-items-lg'>
                  <figure className='tab-items'>
                    <img src={exclamationIcon} alt='exclamation' />
                  </figure>
                </div>
                <div>
                  <h4
                    className={`${
                      currentFaq === 3 ? 'text-white' : 'text-black'
                    } text-xl font-semibold`}
                  >
                    {APPLY_FOR_LOAN_HEADER}
                  </h4>
                  <p className={`${currentFaq === 3 ? 'text-white' : 'text-gray-900'} `}>
                    {APPLY_FOR_LOAN}.{' '}
                  </p>
                </div>
              </div>
            </div>

            <div
              className={`${
                currentFaq === 2 ? 'bg-primary' : 'bg-info'
              } mb-5 p-5 rounded-lg cursor-pointer duration-150`}
              onClick={() => changeCurrentFaq(2)}
            >
              <div className='flex'>
                <div className='tab-items-lg'>
                  <figure className='tab-items'>
                    <img src={exclamationIcon} alt='exclamation' />
                  </figure>
                </div>
                <div>
                  <h4
                    className={`${
                      currentFaq === 2 ? 'text-white' : 'text-black'
                    } text-xl font-semibold`}
                  >
                    {MAINTAINING_A_LOAN_HEADER}
                  </h4>
                  <p className={`${currentFaq === 2 ? 'text-white' : 'text-gray-900'} `}>
                    {MAINTAINING_A_LOAN}.{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='md:col-span-7 duration-300' ref={faqRef}>
            <LoanBasicFaq currentFaq={currentFaq} />
            <ApplyForLoan currentFaq={currentFaq} />
            <MaintaingALoan currentFaq={currentFaq} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionTwo
