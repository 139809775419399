import ApiHandler from './ApiHandler'

export default {
  login: data => ApiHandler.post('login', data),
  getUser: () => ApiHandler.get('info'),
  register: data => ApiHandler.post('register', data),
  forgotPassword: data => ApiHandler.post('forgot-password', data),
  confirmAccount: token => ApiHandler.get(`confirm_account/${token}`),
  resendConfirmAccount: data => ApiHandler.post('send-confirmation', data),
  resetPassword: data => ApiHandler.post('reset-password', data),
  updateProfile: data => ApiHandler.post('customer/update-profile', data),
  createUserTransactionPin: data => ApiHandler.post('user/pins', data),
  updateUserTransactionPin: data => ApiHandler.put('user/pins', data),
  verifyUserTransactionPin: data => ApiHandler.post('user/pins/verify', data),
  passwordChange: data => ApiHandler.post('customer/change_password', data),
  uploadProfileImg: data => ApiHandler.post('customer/upload-photo', data),
  refreshToken: () => ApiHandler.post('refresh'),
  updateBankStatement: data => ApiHandler.post('customer/get-bank-statement', data),
  resetTempPassword: data => ApiHandler.post('/reset-temp-password', data),
  resetPwdForCustomer: data => ApiHandler.put('admin/reset_user_password', data)
}
