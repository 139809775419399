import React from 'react'
import { Collapse } from 'antd'
import PropTypes from 'prop-types'
import exclamationIcon from '../../../images/icons/exclamation.svg'

import {
  // headers
  APPLY_FOR_LOAN_HEADER,
  FILING_OUT_A_LOAN_HEADER,
  LOAN_APPROVAL_HEADER,
  BORROW_LIMIT_HEADER,
  LENGTH_OF_BORROWING_HEADER,
  COLLATERAL_HEADER,
  LOAN_DOCUMENT_HEADER,
  PAYMENT_FEES_HEADER,
  APPROVAL_FEES_HEADER,
  FILING_OUT_A_LOAN,
  LOAN_APPROVAL,
  BORROW_LIMIT,
  COLLATERAL,
  LENGTH_OF_BORROWING,
  LOAN_DOCUMENT_1,
  LOAN_DOCUMENT_2,
  LOAN_DOCUMENT_3,
  LOAN_DOCUMENT_4,
  LOAN_DOCUMENT_5,
  LOAN_DOCUMENT_6,
  LOAN_DOCUMENT_7,
  LOAN_DOCUMENT_8,
  LOAN_DOCUMENT_9,
  LOAN_DOCUMENT_10,
  LOAN_DOCUMENT_11,
  LOAN_DOCUMENT_12,
  LOAN_DOCUMENT_13,
  PAYMENT_FEES,
  APPROVAL_FEES,
  APPLY_FOR_LOAN
} from '../constants'

const ApplyForLoan = ({ currentFaq }) => {
  const { Panel } = Collapse

  return (
    <div
      className={`bg-info pb-5 ${currentFaq === 3 ? 'block' : 'hidden'}`}
      style={{ borderRadius: '1rem' }}
    >
      <div className='p-5'>
        <div className='flex'>
          <div className='flex flex-col items-center justify-center mr-5'>
            <figure className='m-0 p-0 bg-blue-200 rounded-full w-4 h-4 grid place-items-center'>
              <img src={exclamationIcon} alt='exclamation' />
            </figure>
          </div>
          <div>
            <h4 className='text-black text-xl font-semibold'>{APPLY_FOR_LOAN_HEADER}</h4>
            <p className='text-gray-900'>{APPLY_FOR_LOAN}</p>
          </div>
        </div>
      </div>
      <Collapse
        accordion
        className='pl-10 bg-white border-0'
        expandIconPosition='right'
        expandIcon={item =>
          item.isActive ? (
            <span className='plus-and-minus'>
              <i className='fa fa-minus' />
            </span>
          ) : (
            <span className='border border-gray-500 rounded-full p-0.5 px-1'>
              <i className='fa fa-plus' />{' '}
            </span>
          )
        }
      >
        <Panel className='text-lg font-manrope' header={FILING_OUT_A_LOAN_HEADER} key='1'>
          <p className='apply-for-loan mb-4'>{FILING_OUT_A_LOAN}</p>
        </Panel>
        <Panel className='text-lg font-manrope' header={LOAN_APPROVAL_HEADER} key='2'>
          <p className='apply-for-loan mb-4'>{LOAN_APPROVAL}</p>
        </Panel>
        <Panel className='text-lg font-manrope' header={BORROW_LIMIT_HEADER} key='3'>
          <p className='apply-for-loan mb-4'>{BORROW_LIMIT}</p>
        </Panel>
        <Panel className='text-lg font-manrope' header={LENGTH_OF_BORROWING_HEADER} key='4'>
          <p className='apply-for-loan'>{LENGTH_OF_BORROWING}</p>
        </Panel>
        <Panel className='text-lg font-manrope' header={COLLATERAL_HEADER} key='5'>
          <p className='apply-for-loan mb-4'>{COLLATERAL}</p>
        </Panel>
        <Panel className='text-lg font-manrope' header={LOAN_DOCUMENT_HEADER} key='6'>
          <p className='apply-for-loan mb-4'>{LOAN_DOCUMENT_1}</p>
          <p className='apply-for-loan mb-4'>{LOAN_DOCUMENT_2}</p>
          <p className='apply-for-loan mb-4'>{LOAN_DOCUMENT_3}</p>
          <p className='apply-for-loan mb-4'>{LOAN_DOCUMENT_4}</p>
          <p className='apply-for-loan mb-4'>{LOAN_DOCUMENT_5}</p>
          <p className='apply-for-loan mb-4'>{LOAN_DOCUMENT_6}</p>
          <p className='apply-for-loan mb-4'>{LOAN_DOCUMENT_7}</p>
          <p className='apply-for-loan mb-4'>{LOAN_DOCUMENT_8}</p>
          <p className='apply-for-loan mb-4'>{LOAN_DOCUMENT_9}</p>
          <p className='apply-for-loan mb-4'>{LOAN_DOCUMENT_10}</p>
          <p className='apply-for-loan mb-4'>{LOAN_DOCUMENT_11}</p>
          <p className='apply-for-loan mb-4'>{LOAN_DOCUMENT_12}</p>
          <p className='apply-for-loan mb-4'>{LOAN_DOCUMENT_13}</p>
        </Panel>
        <Panel className='text-lg font-manrope' header={PAYMENT_FEES_HEADER} key='7'>
          <p className='apply-for-loan mb-4'>{PAYMENT_FEES}</p>
        </Panel>
        <Panel className='text-lg font-manrope' header={APPROVAL_FEES_HEADER} key='8'>
          <p className='apply-for-loan mb-4'>{APPROVAL_FEES}</p>
        </Panel>
      </Collapse>
    </div>
  )
}

ApplyForLoan.propTypes = {
  currentFaq: PropTypes.number.isRequired
}

export default ApplyForLoan
