import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import classnames from 'classnames'

import logo from '../../images/logo/logo.svg'
import { ReactComponent as NavIcon } from '../../images/icons/navIconMobile.svg'

import { HOME, ABOUT, BLOG, FAQ, LOGIN, SIGN_UP, DASHBOARD } from './constants'
import { useSelector } from 'react-redux'
import { PRIVACY_POLICY } from '../HomePage/constant'

const NavBar = () => {
  const { pathname } = useLocation()
  const [nav, setNav] = useState(true)
  const { userInfo } = useSelector(({ auth }) => auth.authUser)

  const shouldShowNewBlog = useSelector(
    ({ featureToggle }) => featureToggle.features?.enableNewBlog
  )
  const openRoutes = [
    '',
    'about',
    'blog',
    'faq',
    'login',
    'sign-up',
    'how-it-works',
    'landing-page',
    'about-us',
    'confirm-signup',
    'privacy-policy'
  ]

  if (!openRoutes.includes(pathname.split('/')[1])) return ''

  return (
    <nav className='nav-bar md:px-20 bg-neutral3'>
      <Link to='/'>
        <div className='flex items-center flex-shrink-0 text-blacker mr-6'>
          <img src={logo} alt='logo' />
        </div>
      </Link>
      <div className='block lg:hidden cursor-pointer'>
        <button
          onClick={() => setNav(navToggle => !navToggle)}
          className='flex items-center px-3 py-2 text-black'
        >
          <NavIcon className='text-primary w-12 h-12 fill-current' />
        </button>
      </div>

      <div
        className={classnames('lg:w-auto mt-1 w-full block flex-grow lg:flex lg:items-center', {
          hidden: nav
        })}
      >
        <div className='lg:flex-grow lg:ml-16 text-base'>
          <Link
            to='/'
            className={`${
              pathname === '/' ? 'border-b-2 border-blue-800 font-bold text-primary' : 'text-black'
            } block ml-4 mt-4 lg:inline-block lg:mt-0  hover:text-primary mr-6`}
          >
            {HOME}
          </Link>

          <Link
            to='/how-it-works'
            className={`${
              pathname === '/how-it-works'
                ? 'border-b-2 border-blue-800 font-bold text-primary'
                : 'text-black'
            } block ml-4 mt-4 lg:inline-block lg:mt-0 hover:text-primary mr-6`}
          >
            How it works
          </Link>

          <Link
            to='/about'
            className={`${
              pathname === '/about'
                ? 'border-b-2 border-blue-800 font-bold text-primary'
                : 'text-black'
            } block ml-4 mt-4 lg:inline-block lg:mt-0 hover:text-primary mr-6`}
          >
            {ABOUT}
          </Link>

          {shouldShowNewBlog ? (
            <Link
              to='/blog'
              className={`${
                pathname === '/blog'
                  ? 'border-b-2 border-blue-800 font-bold text-primary'
                  : 'text-black'
              } block ml-4 mt-4 lg:inline-block lg:mt-0 hover:text-primary mr-6`}
            >
              {BLOG}
            </Link>
          ) : (
            ''
          )}

          <Link
            to='/faq'
            className={`${
              pathname === '/faq'
                ? 'border-b-2 border-blue-800 font-bold text-primary'
                : 'text-black'
            } block ml-4 mt-4 lg:inline-block lg:mt-0 hover:text-primary mr-6`}
          >
            {FAQ}
          </Link>

          <Link
            to='/privacy-policy'
            className={`${
              pathname === '/privacy-policy'
                ? 'border-b-2 border-blue-800 font-bold text-primary'
                : 'text-black'
            } hidden ml-4 mt-4 lg:inline- lg:mt-0 hover:text-primary mr-6`}
          >
            {PRIVACY_POLICY}
          </Link>
        </div>
        {!userInfo ? (
          <div>
            <Link
              to='/login'
              className='nav-btn hover:text-white text-black rounded-lg border-primary'
            >
              {LOGIN}
            </Link>
            <Link
              to='/sign-up'
              className='nav-btn hover:text-gray-300 text-white rounded-lg bg-primary'
            >
              {SIGN_UP}
            </Link>
          </div>
        ) : (
          <Link
            to={userInfo?.is_admin === 1 ? '/admin-dashboard' : '/dashboard'}
            className='nav-btn hover:text-gray-300 text-white bg-primary'
          >
            {DASHBOARD}
          </Link>
        )}
      </div>
    </nav>
  )
}

export default NavBar
