/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik'
import * as Yup from 'yup'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Modal, Spin, notification } from 'antd'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { InputField } from '../../../sharedComponents/input-field'
import { NEXT } from './constants'
import { dispatch } from '../../../redux/store'
import {
  BIRTHDATE,
  EMAIL,
  LAST_NAME,
  FIRST_NAME,
  PHONE_NO,
  BANK_VERIFICATION_NO,
  YEARS_OF_EXPERIENCE,
  GENDER,
  MARITAL_STATUS,
  HOME_ADDRESS,
  BROWSE_FILE,
  NO_OF_KIDS,
  STATE_OF_ORIGIN,
  LOCAL_GOVT
} from '../../../sharedComponents/entries/constant'
import { Icon } from '@iconify/react'
import { validateFileTypes } from '../../../utils/helpers'
import { Upload } from '../../../services'
import PreviewModal from '../apply-for-loan/New-apply-loan/modal/PreviewModal'
import _ from 'lodash'

const closeButtonStyle = 'mt-7 mr-8 text-white text-4xl rounded-full bg-white'
const bodyStyle = {
  padding: 0
}

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  birth_date: '',
  bank_veri_no: '',
  address: '',
  gender: '',
  years_of_experience: '',
  marital_status: '',
  title: '',
  education_level: '',
  num_kids: '',
  photo: '',
  government_id: '',
  state_of_origin: '',
  local_govt: ''
}

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
  birth_date: Yup.date().required('Birthdate is required'),
  bank_veri_no: Yup.string().required('Bank verification number is required'),
  address: Yup.string().required('Home address is required'),
  gender: Yup.string().required('Gender is required'),
  title: Yup.string().required('Title is required'),
  num_kids: Yup.string().required('Number of kids is required'),
  education_level: Yup.string().required('Educational level is required'),
  years_of_experience: Yup.number()
    .typeError('Years of experience must be a number')
    .required('Years of experience is required')
    .min(0, 'Years of experience cannot be negative')
    .max(99, 'Years of experience cannot exceed 99'),
  marital_status: Yup.string().required('Marital status is required'),
  photo: Yup.string().required('Passport Photograph is required'),
  government_id: Yup.string().required('Government ID is required'),
  state_of_origin: Yup.string().required('State of origin is required'),
  local_govt: Yup.string().required('Local Govt is required')
})

const AddNewDirector = ({ getCurrentUserDirs, text }) => {
  const [showModal, setShowModal] = React.useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [uploading, setUploading] = useState({})
  const [previewFileUrl, setPreviewFileUrl] = useState('')
  const userIdFromParams = useParams().id

  const { isAdmin, token, permissions, loadingDirector } = useSelector(
    ({ auth: { authUser }, loading }) => ({
      isAdmin: authUser.userInfo?.is_admin,
      data: authUser.userInfo?.data,
      token: authUser.token,
      permissions: authUser.permissions,
      loadingDirector: loading.effects.directors.customerAddDirector
    })
  )

  const handleSubmit = async values => {
    const endpoint = isAdmin ? 'admin/add-directors' : 'customer/create-director'
    const details = isAdmin ? [{ ...values, user_id: userIdFromParams }] : [values]
    await dispatch.directors.customerAddDirector({
      token,
      permissions,
      details,
      endpoint
    })
    await getCurrentUserDirs()
    setModalVisible(false)
  }

  const handleUploadFile = (file, setFileInFormik, fieldName) => {
    if (
      !validateFileTypes(file, ['jpeg', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'png', 'jpg', 'webp'])
    ) {
      notification.error({
        message: 'Invalid file type',
        description:
          'Please upload a valid file type, we only accept images, pdfs, excel files and docs'
      })
      return
    }
    // eslint-disable-next-line no-undef
    const formData = new FormData()
    formData.append('file', file)
    Upload.uploadFile(formData)
      .then(({ data: { data } }) => {
        const dataUrl = data.url
        setFileInFormik(fieldName, dataUrl)
        setPreviewFileUrl(dataUrl) // Set the preview URL
      })
      .catch(error => {
        setFileInFormik(fieldName, '')
        setUploading({ ...uploading, [fieldName]: '' })
        notification.error({
          message: 'Error',
          description: error.message
        })
      })
  }
  return (
    <main className='w-full flex-grow p-4'>
      <button onClick={() => setShowModal(true)}>{text}</button>
      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        destroyOnClose
        centered
        className='director_modal'
        closeIcon={
          <Icon icon='iconamoon:close-thin' color='#b1b1ff' className={closeButtonStyle} />
        }
        bodyStyle={bodyStyle}
        width={800}
      >
        <div className='header_modal bg-primary w-full flex justify-between px-3 py-5'>
          <h2 className='text-white font-bold tracking-tighter text-2xl'>Add New Director</h2>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <>
              <Form>
                <div className='w-full my-8 px-4'>
                  <div className='flex justify-between flex-wrap'>
                    <div className='w-full  mx-2'>
                      <span className='text-base font-bold'>
                        Kindly provide the following documents:
                      </span>
                      <div className='mt-4'>
                        <div className='md:grid grid-cols-2 gap-2 gap-x-6'>
                          {/* First Name */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <label className='text-sm text-gray-900 font-semibold'>
                              First name
                            </label>
                            <Field
                              value={values.first_name}
                              type='text'
                              name='first_name'
                              maxLength='35'
                              placeholder={FIRST_NAME}
                              as={InputField}
                              className='form-control'
                            />
                            <ErrorMessage
                              name='first_name'
                              component='small'
                              className='text-red-500'
                            />
                          </div>

                          {/* Last Name */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <label className='text-sm text-gray-900 font-semibold'>Last name</label>
                            <Field
                              type='text'
                              name='last_name'
                              value={values.last_name}
                              maxLength='35'
                              placeholder={LAST_NAME}
                              as={InputField}
                              className='form-control'
                            />
                            <ErrorMessage
                              name='last_name'
                              component='small'
                              className='text-red-500'
                            />
                          </div>

                          {/* Gender */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <span className='text-sm text-gray-900 font-semibold'>{GENDER}</span>
                            <Field
                              as='select'
                              value={values.gender}
                              name='gender'
                              className='form-control'
                            >
                              <option value=''>Select gender</option>
                              <option value='Male'>Male</option>
                              <option value='Female'>Female</option>
                            </Field>
                            <ErrorMessage
                              name='gender'
                              component='small'
                              className='text-red-500'
                            />
                          </div>
                          {/* Education level */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <span className='text-sm text-gray-900 font-semibold'>
                              Education level
                            </span>
                            <Field
                              as='select'
                              value={values.education_level}
                              name='education_level'
                              className='form-control'
                            >
                              <option value=''>Select one</option>
                              <option value='None'>None</option>
                              <option value='Higer Education'>Higer Education</option>
                              <option value='Bachelor'>Bachelor</option>
                              <option value='Masters'>Masters</option>
                              <option value='Ph.D'>Ph.D</option>
                            </Field>
                            <ErrorMessage
                              name='education_level'
                              component='small'
                              className='text-red-500'
                            />
                          </div>
                          {/* Title */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <span className='text-sm text-gray-900 font-semibold'>Title</span>
                            <Field
                              as='select'
                              value={values.title}
                              name='title'
                              className='form-control'
                            >
                              <option value=''>Select title</option>
                              <option value='Mr'>Mr</option>
                              <option value='Chief'>Chief</option>
                              <option value='Engr'>Engr</option>
                              <option value='Dr'>Dr</option>
                              <option value='Prof'>Prof</option>
                              <option value='Master'>Master</option>
                              <option value='Miss'>Miss</option>
                            </Field>
                            <ErrorMessage name='title' component='small' className='text-red-500' />
                          </div>

                          {/* Marital Status */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <span className='text-sm text-gray-900 font-semibold'>
                              {MARITAL_STATUS}
                            </span>
                            <Field
                              as='select'
                              value={values.marital_status}
                              name='marital_status'
                              className='form-control'
                            >
                              <option value=''>Marital Status</option>
                              <option value='Single'>Single</option>
                              <option value='Married'>Married</option>
                              <option value='Others'>Others</option>
                            </Field>
                            <ErrorMessage
                              name='marital_status'
                              component='small'
                              className='text-red-500'
                            />
                          </div>

                          {/* Email */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <label className='text-sm text-gray-900 font-semibold'>{EMAIL}</label>
                            <Field
                              type='text'
                              name='email'
                              value={values.email}
                              maxLength='45'
                              placeholder={EMAIL}
                              as={InputField}
                              className='form-control'
                            />
                            <ErrorMessage name='email' component='small' className='text-red-500' />
                          </div>

                          {/* Phone Number */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <label className='text-sm text-gray-900 font-semibold'>
                              {PHONE_NO}
                            </label>
                            <Field
                              type='text'
                              name='phone'
                              maxLength='15'
                              value={values.phone}
                              placeholder={PHONE_NO}
                              as={InputField}
                              className='form-control'
                            />
                            <ErrorMessage name='phone' component='small' className='text-red-500' />
                          </div>

                          {/* Home Address */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <label className='text-sm text-gray-900 font-semibold'>
                              {HOME_ADDRESS}
                            </label>
                            <Field
                              type='text'
                              name='address'
                              value={values.address}
                              placeholder={HOME_ADDRESS}
                              as={InputField}
                              className='form-control'
                            />
                            <ErrorMessage
                              name='address'
                              component='small'
                              className='text-red-500'
                            />
                          </div>

                          {/* Birthdate */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <label className='text-sm text-gray-900 font-semibold'>
                              {BIRTHDATE}
                            </label>
                            <Field
                              type='date'
                              name='birth_date'
                              value={values.birth_date}
                              max={new Date().toISOString().slice(0, 10)}
                              placeholder={BIRTHDATE}
                              as={InputField}
                              className='form-control'
                            />
                            <ErrorMessage
                              name='birth_date'
                              component='small'
                              className='text-red-500'
                            />
                          </div>

                          {/* Years of Experience */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <label className='text-sm text-gray-900 font-semibold'>
                              {YEARS_OF_EXPERIENCE}
                            </label>
                            <Field
                              type='text'
                              name='years_of_experience'
                              maxLength='2'
                              placeholder={YEARS_OF_EXPERIENCE}
                              as={InputField}
                              value={values.years_of_experience}
                              className='form-control'
                            />
                            <ErrorMessage
                              name='years_of_experience'
                              component='small'
                              className='text-red-500'
                            />
                          </div>
                          {/* Number of kids */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <label className='text-sm text-gray-900 font-semibold'>
                              Number of Kids
                            </label>
                            <Field
                              type='text'
                              name='num_kids'
                              maxLength='2'
                              placeholder={NO_OF_KIDS}
                              as={InputField}
                              value={values.num_kids}
                              className='form-control'
                            />
                            <ErrorMessage
                              name='num_kids'
                              component='small'
                              className='text-red-500'
                            />
                          </div>

                          {/* Bank Verification Number */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <span className='text-sm text-gray-900 font-semibold'>
                              Bank verification number
                            </span>
                            <Field
                              type='text'
                              name='bank_veri_no'
                              maxLength='11'
                              value={values.bank_veri_no}
                              placeholder={BANK_VERIFICATION_NO}
                              as={InputField}
                              className='form-control'
                            />
                            <ErrorMessage
                              name='bank_veri_no'
                              component='small'
                              className='text-red-500'
                            />
                          </div>

                          {/* State of origin */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <span className='text-sm text-gray-900 font-semibold'>
                              State of origin
                            </span>
                            <Field
                              type='text'
                              name='state_of_origin'
                              value={values.state_of_origin}
                              placeholder={STATE_OF_ORIGIN}
                              as={InputField}
                              className='form-control'
                            />
                            <ErrorMessage
                              name='state_of_origin'
                              component='small'
                              className='text-red-500'
                            />
                          </div>
                          {/* Local Govt */}
                          <div className='w-full md:w-12/12 px-2 mb-6'>
                            <span className='text-sm text-gray-900 font-semibold'>Local Govt</span>
                            <Field
                              type='text'
                              name='local_govt'
                              value={values.local_govt}
                              placeholder={LOCAL_GOVT}
                              as={InputField}
                              className='form-control'
                            />
                            <ErrorMessage
                              name='local_govt'
                              component='small'
                              className='text-red-500'
                            />
                          </div>
                          <div className='w-full max-w-sm mt-4s'>
                            <div className='flex flex-col'>
                              <div className='relative'>
                                <label
                                  htmlFor='photoId'
                                  className='block text-black text-base leading-8'
                                >
                                  Passport Photograph
                                </label>
                                <input
                                  type='file'
                                  id='photoId'
                                  name='photo'
                                  accept='image/*, .jpeg, .pdf, .doc, .docx, .ppt, .pptx, .png, .jpg, .webp'
                                  onChange={event => {
                                    const file = event.target.files[0]
                                    setUploading({
                                      ...uploading,
                                      photo: file?.name
                                    })

                                    handleUploadFile(file, setFieldValue, 'photo')
                                  }}
                                  className='mt-1 sr-only form-control' // Hide the default input appearance
                                />
                                <label
                                  htmlFor='photoId'
                                  className='bg-white rounded-md pt-2 cursor-pointer w-full block text-right border border-gray-200 text-black px-3 h-12 align-middle leading-loose'
                                >
                                  {/* If not uploading, then make text to be browse file */}
                                  {!values.photo && uploading.photo === undefined && BROWSE_FILE}
                                  {values.photo && (
                                    // Show "View" link when a file is uploaded
                                    <a
                                      href='#'
                                      onClick={event => {
                                        event.preventDefault()
                                        setPreviewFileUrl(values.photo)
                                        setModalVisible(true)
                                      }}
                                      className='pr-2 text-blue-700 '
                                    >
                                      View
                                    </a>
                                  )}
                                  {/* if uploaded, then change text to change file */}
                                  {Boolean(values.photo) && (
                                    <>
                                      <span className='pr-3 hidden'>
                                        {' '}
                                        {uploading['forms.photo']}
                                      </span>{' '}
                                      <span className='text-blue-700 underline'>Change file</span>
                                    </>
                                  )}
                                  {/* if its uploading, then change text to uploading */}
                                  {uploading.photo && !values.photo && 'Uploading...'}
                                </label>
                              </div>
                              <ErrorMessage
                                name='photo'
                                component='small'
                                className='text-red-500'
                              />
                            </div>
                          </div>
                          <div className='w-full max-w-sm '>
                            <div className='relative'>
                              <label
                                htmlFor='governmentId'
                                className='block text-black text-base leading-8'
                              >
                                Government ID
                              </label>
                              <input
                                type='file'
                                id='governmentId'
                                name='government_id'
                                accept='image/*, .jpeg, .pdf, .doc, .docx, .ppt, .pptx, .png, .jpg, .webp'
                                onChange={event => {
                                  const file = event.target.files[0]
                                  setUploading({
                                    ...uploading,
                                    government_id: file?.name
                                  })

                                  handleUploadFile(file, setFieldValue, 'government_id')
                                }}
                                className='mt-1 sr-only form-control' // Hide the default input appearance
                              />
                              <label
                                htmlFor='governmentId'
                                className='bg-white rounded-md pt-2 cursor-pointer w-full block text-right border border-gray-200 text-black px-3 h-12 align-middle leading-loose'
                              >
                                {/* If not uploading, then make text to be browse file */}
                                {!values.government_id &&
                                  uploading.government_id === undefined &&
                                  BROWSE_FILE}
                                {values.government_id && (
                                  // Show "View" link when a file is uploaded
                                  <a
                                    href='#'
                                    onClick={event => {
                                      event.preventDefault()
                                      setPreviewFileUrl(values.government_id)
                                      setModalVisible(true)
                                    }}
                                    className='pr-2 text-blue-700 '
                                  >
                                    View
                                  </a>
                                )}
                                {/* if uploaded, then change text to change file */}
                                {Boolean(values.government_id) && (
                                  <>
                                    <span className='pr-3 hidden'>
                                      {' '}
                                      {uploading['forms.government_id']}
                                    </span>
                                    <span className='text-blue-700 underline'>Change file</span>
                                  </>
                                )}
                                {/* if its uploading, then change text to uploading */}
                                {uploading.government_id && !values.government_id && 'Uploading...'}
                              </label>
                            </div>
                            <ErrorMessage
                              name='government_id'
                              component='small'
                              className='text-red-500'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='  w-full flex justify-center '>
                  <button
                    type='submit'
                    className='text-white mb-10 text-lg bg-primary-200 rounded py-2 px-5'
                  >
                    Add Director
                    {loadingDirector ? <Spin size='small' /> : ''}
                  </button>
                </div>
              </Form>
            </>
          )}
        </Formik>
        <PreviewModal
          modalVisible={modalVisible}
          previewFileUrl={previewFileUrl}
          setModalVisible={setModalVisible}
        />
      </Modal>
    </main>
  )
}

AddNewDirector.propTypes = {
  getCurrentUserDirs: PropTypes.func,
  text: PropTypes.any
}

export default AddNewDirector
