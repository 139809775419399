import React from 'react'
import { Collapse } from 'antd'
import PropTypes from 'prop-types'
import exclamationIcon from '../../../images/icons/exclamation.svg'

import {
  LOAN_BASICS,
  LOAN_WORK,
  LOAN_INTEREST,

  // headers
  LOAN_BASICS_HEADER,
  LOAN_WORK_HEADER,
  LOAN_INTEREST_HEADER
} from '../constants'

const LoanBasicFaq = ({ currentFaq }) => {
  const { Panel } = Collapse

  return (
    <div
      className={`bg-info pb-5 ${currentFaq === 1 ? 'block' : 'hidden'}`}
      style={{ borderRadius: '1rem' }}
    >
      <div className='p-5'>
        <div className='flex'>
          <div className='flex flex-col items-center justify-center mr-5'>
            <figure className='m-0 p-0 bg-blue-200 rounded-full w-4 h-4 grid place-items-center'>
              <img src={exclamationIcon} alt='exclamation' />
            </figure>
          </div>
          <div>
            <h4 className='text-black text-xl font-semibold'>{LOAN_BASICS_HEADER}</h4>
            <p className='text-gray-900'>{LOAN_BASICS}</p>
          </div>
        </div>
      </div>
      <Collapse
        accordion
        className='pl-10 bg-white border-0'
        expandIconPosition='right'
        expandIcon={item =>
          item.isActive ? (
            <span className='plus-and-minus'>
              <i className='fa fa-minus' />
            </span>
          ) : (
            <span className='plus-and-minus'>
              <i className='fa fa-plus' />
            </span>
          )
        }
      >
        <Panel className='text-lg font-manrope' header={LOAN_WORK_HEADER} key='1'>
          <p className='apply-for-loan'>{LOAN_WORK}</p>
        </Panel>
        <Panel className='text-lg font-manrope' header={LOAN_INTEREST_HEADER} key='2'>
          <p className='apply-for-loan'>{LOAN_INTEREST}</p>
        </Panel>
      </Collapse>
    </div>
  )
}

LoanBasicFaq.propTypes = {
  currentFaq: PropTypes.number
}
export default LoanBasicFaq
