import React from 'react'
import { FAQ_SEARCH_BUTTON, FAQ_SEARCH_PLACEHOLDER, FREQUENTLY_ASK_QUESTION } from '../constants'

const SectionOne = () => {
  return (
    <div className='pt-8 bg-white pb-8mx-1'>
      <div className='flex flex-wrap'>
        <div className='w-full text-center text-black mx-auto'>
          <h1 className='font-bold text-xl md:text-4xl pt-6 pb-0 px-6'>
            {FREQUENTLY_ASK_QUESTION}
          </h1>
          <form className='w-full my-16'>
            <div className='w-11/12 md:w-4/5 lg:w-1/2 mx-auto'>
              <div className='relative'>
                <input
                  disabled
                  title='Coming soon'
                  className='appearance-none text-base block w-full bg-gray-100 border border-gray-200 rounded py-3 px-4 focus:outline-none focus:bg-white focus:border-gray-900'
                  type='search'
                  placeholder={FAQ_SEARCH_PLACEHOLDER}
                />

                <button
                  type='submit'
                  className='bg-gray-900 hover:bg-black text-white px-3 sm:px-5 md:px-10 lg:px-20 py-2 text-sm font-bold rounded absolute top-0 right-0 h-full'
                >
                  <i className='fa fa-search mr-2' />
                  {FAQ_SEARCH_BUTTON}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SectionOne
