/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Steps, Modal, notification, Spin } from 'antd'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { NEXT, applyLoanlocalStorageKey, steps } from '../../constants'
import { step2Validator } from '../../../../../utils/inputValidator'
import { InfoCircleOutlined } from '@ant-design/icons'
import InfoModal from '../modal/InfoModal'
import {
  AOA,
  businessLicense,
  bankStatement,
  PharmaceuticalLicense,
  MOA,
  cac7,
  cac3,
  cac2
} from '../../../../../sharedComponents/entries/constant'
import { validateFileTypes } from '../../../../../utils/helpers'
import { Upload } from '../../../../../services'
import { useSelector } from 'react-redux'
import { dispatch } from '../../../../../redux/store'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import PreviewModal from '../modal/PreviewModal'

const { Step } = Steps

const Step2 = ({ onNext, onGoBack, savedData, clearCurrentStep, disableStepThree }) => {
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState({})
  const [fileError, setFileError] = useState({})
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedCard, setSelectedCard] = useState({
    title: '',
    description: ''
  })
  const [infoModalVisible, setInfoModalVisible] = useState(false)
  const [previewFileUrl, setPreviewFileUrl] = useState('')
  const [fileUploaded, setFileUploaded] = useState({
    aoa: false,
    pharmacist_license: false,
    business_licenses: false,
    bank_statement: false,
    moa: false,
    cac_7: false,
    cac_2: false
  })

  const [fileUrls, setFileUrls] = useState({
    aoa: '',
    pharmacist_license: '',
    business_licenses: '',
    bank_statement: '',
    moa: '',
    cac_7: '',
    cac_2: ''
  })

  const userIdFromParams = useParams().userId
  const { isAdmin, token, permissions, docsSaving } = useSelector(
    ({ auth: { authUser }, loading }) => ({
      isAdmin: authUser.userInfo?.is_admin,
      token: authUser.token,
      permissions: authUser.permissions,
      docsSaving:
        loading.effects.applications.customerUploadDocument ||
        loading.effects.applications.adminUploadDocument
    })
  )

  /**
   * @description handles the file change event
   * @param {File} event the file change event
   * @param {*} cardId the id of the card or type of docs to upload
   * @returns {void}
   */
  const handleFileChange = (event, cardId) => {
    const file = event.target.files[0]

    setFileUploaded(prevState => ({
      ...prevState,
      [`${cardId}loading`]: true
    }))

    // Check if file is valid
    if (!validateFileTypes(file)) {
      setFileUploaded(prevState => ({
        ...prevState,
        [`${cardId}loading`]: false
      }))
      notification.error({
        message: 'Invalid file type',
        description:
          'Please upload a valid file type, we only accept images, pdfs, excel files and docs'
      })
      return
    }

    // upload file to the cloud
    // eslint-disable-next-line no-undef
    const formData = new FormData()
    formData.append('file', file)

    Upload.uploadFile(formData)
      .then(({ data: { data } }) => {
        // we added the IDs to see if frontend will determine the IDs of the docs. might be removed if its not working
        const dataUrl = `${uuidv4()}|${data.url}|${file.name}`

        // Save file name to state
        setFileUrls(prevState => ({
          ...prevState,
          [cardId]: dataUrl // Save the data URL and id instead of the Blob URL
        }))

        // Save file to local storage
        const loanObjectString = window.localStorage.getItem(applyLoanlocalStorageKey)

        if (loanObjectString) {
          const loanObject = {
            ...JSON.parse(loanObjectString),

            [steps[2]]: {
              ...JSON.parse(loanObjectString)?.businessDocuments,
              fileUrls: {
                ...JSON.parse(loanObjectString)?.businessDocuments?.fileUrls,
                [cardId]: dataUrl
              }
            }
          }

          window.localStorage.setItem(applyLoanlocalStorageKey, JSON.stringify(loanObject))
        }

        setFileError(prevState => ({
          ...prevState,
          [cardId]: ''
        }))

        // Set file uploaded to true
        setFileUploaded(prevState => ({
          ...prevState,
          [cardId]: true
        }))
        setPreviewFileUrl(dataUrl)
      })
      .catch(error => {
        console.log(error)
        notification.error({
          message: 'Error',
          description: `An error occured while uploading file, ${error?.message}`
        })
      })
      .finally(() => {
        setFileUploaded(prevState => ({
          ...prevState,
          [`${cardId}loading`]: false
        }))
      })

    // Set file error if file is not uploaded
    if (!file) {
      setFileError(prevState => ({
        ...prevState,
        [cardId]: 'File is required!'
      }))
    }
  }

  const handleInfoClick = (title, description) => {
    setSelectedCard({ title, description })
    setInfoModalVisible(true)
  }

  const handleSubmit = async () => {
    let fileErrorPresent = false
    const newFileError = { ...fileError }

    // Check if any file uploads are missing
    if (!fileUploaded.aoa && !newFileError.aoa) {
      newFileError.aoa = 'Article of Association is required!'
      fileErrorPresent = true
    }
    if (!fileUploaded.pharmacist_license && !newFileError.pharmacist_license) {
      newFileError.pharmacist_license = 'Pharmaceutical license is Required!'
      fileErrorPresent = true
    }
    if (!fileUploaded.business_licenses && !newFileError.business_licenses) {
      newFileError.business_licenses = 'Business license is Required !!!'
      fileErrorPresent = true
    }
    if (!fileUploaded.bank_statement && !newFileError.bank_statement) {
      newFileError.bank_statement = 'Bank statement is Required !!!'
      fileErrorPresent = true
    }
    if (!fileUploaded.moa && !newFileError.moa) {
      newFileError.moa = 'MOA is Required!!!'
      fileErrorPresent = true
    }
    if (!fileUploaded.cac_7 && !newFileError.cac_7) {
      newFileError.cac_7 = 'CAC form 7 is Required!!!'
      fileErrorPresent = true
    }
    if (!fileUploaded.cac_2 && !newFileError.cac_2) {
      newFileError.cac_2 = 'CAC Form 2 is Required!!!'
      fileErrorPresent = true
    }

    // Updating the file error state
    setFileError(newFileError)
    // TODO: @Ifizeesneh clean this data up
    // Check both file errors and input validation errors
    const { valid, error } = step2Validator(fileUrls, fileUploaded)
    if (valid && !fileErrorPresent) {
      // if the user changed the file urls, upload the new files
      if (!_.isEqual(fileUrls, savedData?.fileUrls || savedData)) {
        // prepare the docs for upload for the specific customers profile
        const docsForUpload = Object.entries(fileUrls).map(([key, value]) => ({
          file_url: value.split('|')[1],
          type: key,
          title: value.split('|')[2],
          customer_id: userIdFromParams
        }))

        let docsIds = []
        // if it is not an admin, upload the docs for the customer
        if (!isAdmin) {
          docsIds = await dispatch.applications.customerUploadDocument({
            token,
            permissions,
            formValue: docsForUpload
          })

          if (!docsSaving) {
            return await onNext({ fileUrls, docsIds }, 2)
          }
        } else {
          docsIds = await dispatch.applications.adminUploadDocument(docsForUpload)
          if (!docsSaving) {
            return await onNext({ fileUrls, docsIds }, 2)
          }
        }
      }

      if (!docsSaving) {
        return await onNext(false, 2)
      }
    } else {
      let errorMessages = []
      if (!valid) {
        errorMessages = errorMessages.concat(Object.values(error))
      }

      setError({ ...error })
      // TODO: @Ifizeesneh are we using this ?
      if (errorMessages.length > 0) {
      }
    }
  }

  useEffect(() => {
    // Check for previously uploaded files in local storage
    if (savedData) {
      setFileUrls(savedData?.fileUrls || savedData)
      // convert savedData to object of boolean values
      setFileUploaded(
        Object.entries(savedData?.fileUrls || savedData || {}).reduce((acc, [key, value]) => {
          acc[key] = !!value
          return acc
        }, {})
      )
    } else {
      setFileUrls({
        aoa: '',
        pharmacist_license: '',
        business_licenses: '',
        bank_statement: '',
        moa: '',
        cac_7: '',
        cac_3: '',
        cac_2: ''
      })
      setFileUploaded({
        aoa: false,
        pharmacist_license: false,
        business_licenses: false,
        bank_statement: false,
        moa: false,
        cac_7: false,
        cac_3: false,
        cac_2: false
      })
    }
  }, [savedData])

  return (
    <div className='md:my-12'>
      <Steps current={1} className='custom-steps'>
        <Step title='Business information' />
        <Step title='Business Documents' disabled />
        {!disableStepThree ? <Step title='Business Signatories' disabled /> : null}
        <Step title='Loan Details' disabled />
      </Steps>
      <div className='my-4'>
        <div className='grid grid-cols-5'>
          <button
            onClick={() => clearCurrentStep(2)}
            className='text-blue-500 text-lg mt-10 text-right col-start-4'
          >
            Clear all information
          </button>
        </div>
        <div className='mt-6'>
          <h1>Kindly upload a clear document below. (File size: 30mb max.)</h1>
          {/* Rendering cards here, modifying the corresponding state and props */}
          <div className='grid w-full max-w-5xl md:gap-14 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:mt-7'>
            {/* Start of  Article of Association */}
            <div className='w-full'>
              <div className='flex gap-4'>
                <h2 className='text-base tracking-tight font-medium mb-4'>
                  Article of Association
                </h2>
                <InfoCircleOutlined
                  style={{ fontSize: '16px', color: '#08c', marginTop: '5px' }}
                  onClick={() => handleInfoClick('Article of Association', AOA)}
                />
              </div>
              <div className='bg-transparent flex flex-col items-center border border-gray-300 shadow-sm p-4 rounded-md'>
                {fileUploaded.aoa && !fileUploaded?.aoaloading ? (
                  <>
                    <p className='text-sm px-6 mx-auto mb-2 text-center'>Uploaded successfully</p>
                    <p className='mb-2'>
                      <a
                        href='#'
                        onClick={() => {
                          setPreviewFileUrl(fileUrls.aoa)
                          setModalVisible(true)
                        }}
                        className='text-blue-500'
                      >
                        View
                      </a>
                    </p>
                    <label
                      htmlFor='fileInputaoa'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Change file
                    </label>
                  </>
                ) : !fileUploaded?.aoaloading ? (
                  <>
                    <p className='mb-2 text-center'>Drag and drop your document</p>
                    <p className='text-gray-500 text-sm mt-2'>OR</p>
                    <label
                      htmlFor='fileInputaoa'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Browse file
                    </label>
                  </>
                ) : (
                  <p className='mb-2 text-center'>Uploading...</p>
                )}
                <input
                  type='file'
                  name='Article of Association'
                  id='fileInputaoa'
                  className='hidden'
                  accept='image/*, .pdf, .doc, .docx, .ppt, .pptx, .txt, .xls, .xlsx'
                  onChange={event => handleFileChange(event, 'aoa')}
                />
              </div>
              {fileError.aoa && <small className='text-red-500'>{fileError.aoa}</small>}
            </div>
            {/* Start of  Pharmaceutical license */}
            <div className='w-full'>
              <div className='flex gap-4'>
                <h2 className='text-base tracking-tight font-medium mb-4'>
                  Pharmaceutical license
                </h2>
                <InfoCircleOutlined
                  style={{ fontSize: '16px', color: '#08c', marginTop: '5px' }}
                  onClick={() => handleInfoClick('Pharmaceutical license', PharmaceuticalLicense)}
                />
              </div>
              <div className='bg-transparent flex flex-col items-center border border-gray-300 shadow-sm p-4 rounded-md'>
                {fileUploaded.pharmacist_license && !fileUploaded?.pharmacist_licenseloading ? (
                  <>
                    <p className='text-sm px-6 mx-auto mb-2 text-center'>Uploaded successfully</p>
                    <p className='mb-2'>
                      <a
                        href='#'
                        onClick={() => {
                          setModalVisible(true)
                          setPreviewFileUrl(fileUrls.pharmacist_license)
                        }}
                        className='text-blue-500'
                      >
                        View
                      </a>
                    </p>
                    <label
                      htmlFor='fileInputPharmaceuticalLicense'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Change file
                    </label>
                  </>
                ) : !fileUploaded?.pharmacist_licenseloading ? (
                  <>
                    <p className='mb-2 text-center'>Drag and drop your document</p>
                    <p className='text-gray-500 text-sm mt-2'>OR</p>
                    <label
                      htmlFor='fileInputPharmaceuticalLicense'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Browse file
                    </label>
                  </>
                ) : (
                  <p className='mb-2 text-center'>Uploading...</p>
                )}
                <input
                  type='file'
                  name='Pharmaceutical license'
                  id='fileInputPharmaceuticalLicense'
                  className='hidden'
                  onChange={event => handleFileChange(event, 'pharmacist_license')}
                />
              </div>
              {fileError.pharmacist_license && (
                <small className='text-red-500'>{fileError.pharmacist_license}</small>
              )}
            </div>

            {/* Start of Business License */}
            <div className='w-full'>
              <div className='flex gap-4'>
                <h2 className='text-base tracking-tight font-medium mb-4'>Business License</h2>
                <InfoCircleOutlined
                  style={{ fontSize: '16px', color: '#08c', marginTop: '5px' }}
                  onClick={() => handleInfoClick('Business License', businessLicense)}
                />
              </div>
              <div className='bg-transparent flex flex-col items-center border border-gray-300 shadow-sm p-4 rounded-md'>
                {fileUploaded.business_licenses && !fileUploaded?.business_licensesloading ? (
                  <>
                    <p className='text-sm px-6 mx-auto mb-2 text-center'>Uploaded successfully</p>
                    <p className='mb-2'>
                      <a
                        href='#'
                        onClick={() => {
                          setModalVisible(true)
                          setPreviewFileUrl(fileUrls.business_licenses)
                        }}
                        className='text-blue-500'
                      >
                        View
                      </a>
                    </p>
                    <label
                      htmlFor='fileInputbusinessLicense'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Change file
                    </label>
                  </>
                ) : !fileUploaded?.business_licensesloading ? (
                  <>
                    <p className='mb-2 text-center'>Drag and drop your document</p>
                    <p className='text-gray-500 text-sm mt-2'>OR</p>
                    <label
                      htmlFor='fileInputbusinessLicense'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Browse file
                    </label>
                  </>
                ) : (
                  <p className='mb-2 text-center'>Uploading...</p>
                )}
                <input
                  type='file'
                  name='Business License'
                  id='fileInputbusinessLicense'
                  className='hidden'
                  onChange={event => handleFileChange(event, 'business_licenses')}
                />
              </div>
              {fileError.business_licenses && (
                <small className='text-red-500'>{fileError.business_licenses}</small>
              )}
            </div>

            {/* Start of 6 Month Bank Statement */}
            <div className='w-full'>
              <div className='flex gap-4'>
                <h2 className='text-base tracking-tight font-medium mb-4'>
                  6 Month Bank Statement
                </h2>
                <InfoCircleOutlined
                  style={{ fontSize: '16px', color: '#08c', marginTop: '5px' }}
                  onClick={() => handleInfoClick('6 Month Bank Statement', bankStatement)}
                />
              </div>
              <div className='bg-transparent flex flex-col items-center border border-gray-300 shadow-sm p-4 rounded-md'>
                {fileUploaded.bank_statement && !fileUploaded?.bank_statementloading ? (
                  <>
                    <p className='text-sm px-6 mx-auto mb-2 text-center'>Uploaded successfully</p>
                    <p className='mb-2'>
                      <a
                        href='#'
                        onClick={() => {
                          setModalVisible(true)
                          setPreviewFileUrl(fileUrls.bank_statement)
                        }}
                        className='text-blue-500'
                      >
                        View
                      </a>
                    </p>
                    <label
                      htmlFor='fileInputbankStatement'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Change file
                    </label>
                  </>
                ) : !fileUploaded?.bank_statementloading ? (
                  <>
                    <p className='mb-2 text-center'>Drag and drop your document</p>
                    <p className='text-gray-500 text-sm mt-2'>OR</p>
                    <label
                      htmlFor='fileInputbankStatement'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Browse file
                    </label>
                  </>
                ) : (
                  <p className='mb-2 text-center'>Uploading...</p>
                )}
                <input
                  type='file'
                  name='6 Month Bank Statement'
                  id='fileInputbankStatement'
                  className='hidden'
                  onChange={event => handleFileChange(event, 'bank_statement')}
                />
              </div>
              {fileError.bank_statement && (
                <small className='text-red-500'>{fileError.bank_statement}</small>
              )}
            </div>

            {/* Memorandum of Association */}
            <div className='w-full'>
              <div className='flex gap-4'>
                <h2 className='text-base tracking-tight font-medium mb-4'>
                  Memorandum of Association (MOA)
                </h2>
                <InfoCircleOutlined
                  style={{ fontSize: '16px', color: '#08c', marginTop: '5px' }}
                  onClick={() => handleInfoClick('Memorandum of Association (MOA)', MOA)}
                />
              </div>
              <div className='bg-transparent flex flex-col items-center border border-gray-300 shadow-sm p-4 rounded-md'>
                {fileUploaded.moa && !fileUploaded?.moaloading ? (
                  <>
                    <p className='text-sm px-6 mx-auto mb-2 text-center'>Uploaded successfully</p>
                    <p className='mb-2'>
                      <a
                        href='#'
                        onClick={() => {
                          setModalVisible(true)
                          setPreviewFileUrl(fileUrls.moa)
                        }}
                        className='text-blue-500'
                      >
                        View
                      </a>
                    </p>
                    <label
                      htmlFor='fileInputmoa'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Change file
                    </label>
                  </>
                ) : !fileUploaded?.moaloading ? (
                  <>
                    <p className='mb-2 text-center'>Drag and drop your document</p>
                    <p className='text-gray-500 text-sm mt-2'>OR</p>
                    <label
                      htmlFor='fileInputmoa'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Browse file
                    </label>
                  </>
                ) : (
                  <p className='mb-2 text-center'>Uploading...</p>
                )}
                <input
                  type='file'
                  name='Memorandum of Association (MOA)'
                  id='fileInputmoa'
                  className='hidden'
                  onChange={event => handleFileChange(event, 'moa')}
                />
              </div>
              {fileError.moa && <small className='text-red-500'>{fileError.moa}</small>}
            </div>

            {/* CAC Form 7 - Appointment of Directors */}
            <div className='w-full'>
              <div className='flex gap-4'>
                <h2 className='text-base tracking-tighter font-medium mb-4'>
                  CAC Form 7 (Appointment of Directors)
                </h2>
                <InfoCircleOutlined
                  style={{ fontSize: '16px', color: '#08c', marginTop: '5px' }}
                  onClick={() => handleInfoClick(' CAC Form 7 (Appointment of Directors)', cac7)}
                />
              </div>
              <div className='bg-transparent flex flex-col items-center border border-gray-300 shadow-sm p-4 rounded-md'>
                {fileUploaded.cac_7 && !fileUploaded?.cac_7loading ? (
                  <>
                    <p className='text-sm px-6 mx-auto mb-2 text-center'>Uploaded successfully</p>
                    <p className='mb-2'>
                      <a
                        href='#'
                        onClick={() => {
                          setModalVisible(true)
                          setPreviewFileUrl(fileUrls.cac_7)
                        }}
                        className='text-blue-500'
                      >
                        View
                      </a>
                    </p>
                    <label
                      htmlFor='fileInputcac7'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Change file
                    </label>
                  </>
                ) : !fileUploaded?.cac_7loading ? (
                  <>
                    <p className='mb-2 text-center'>Drag and drop your document</p>
                    <p className='text-gray-500 text-sm mt-2'>OR</p>
                    <label
                      htmlFor='fileInputcac7'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Browse file
                    </label>
                  </>
                ) : (
                  <p className='mb-2 text-center'>Uploading...</p>
                )}
                <input
                  type='file'
                  name=' CAC Form 7 (Appointment of Directors)'
                  id='fileInputcac7'
                  className='hidden'
                  onChange={event => handleFileChange(event, 'cac_7')}
                />
              </div>
              {fileError.cac_7 && <small className='text-red-500'>{fileError.cac_7}</small>}
            </div>

            {/* CAC Form 3 (change of registered address) */}
            <div className='w-full'>
              <div className='flex gap-4'>
                <h2 className='text-base tracking-tight font-medium mb-4'>
                  CAC Form 3 (change of registered address){' '}
                  <span className='text-red-500'>(Optional)</span>
                </h2>
                <InfoCircleOutlined
                  style={{ fontSize: '16px', color: '#08c', marginTop: '5px' }}
                  onClick={() => handleInfoClick('CAC Form 3 (change of registered address)', cac3)}
                />
              </div>
              <div className='bg-transparent flex flex-col items-center border border-gray-300 shadow-sm p-4 rounded-md'>
                {fileUploaded.cac_3 && !fileUploaded?.cac_3loading ? (
                  <>
                    <p className='text-sm px-6 mx-auto mb-2 text-center'>Uploaded successfully</p>
                    <p className='mb-2'>
                      <a
                        href='#'
                        onClick={() => {
                          setModalVisible(true)
                          setPreviewFileUrl(fileUrls.cac_3)
                        }}
                        className='text-blue-500'
                      >
                        View
                      </a>
                    </p>
                    <label
                      htmlFor='fileInputcac3'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Change file
                    </label>
                  </>
                ) : !fileUploaded?.cac_3loading ? (
                  <>
                    <p className='mb-2 text-center'>Drag and drop your document</p>
                    <p className='text-gray-500 text-sm mt-2'>OR</p>
                    <label
                      htmlFor='fileInputcac3'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Browse file
                    </label>
                  </>
                ) : (
                  <p className='mb-2 text-center'>Uploading...</p>
                )}
                <input
                  type='file'
                  name=' CAC Form 3 (change of registered address)'
                  id='fileInputcac3'
                  className='hidden'
                  onChange={event => handleFileChange(event, 'cac_3')}
                />
              </div>
              {fileError.cac_3 && <small className='text-red-500'>{fileError.cac_3}</small>}
            </div>

            {/* CAC Form 2 (Statement of share capital) */}
            <div className='w-full'>
              <div className='flex gap-4'>
                <h2 className='text-base tracking-tight font-medium mb-4'>
                  CAC Form 2 (Statement of share capital)
                </h2>
                <InfoCircleOutlined
                  style={{ fontSize: '16px', color: '#08c', marginTop: '5px' }}
                  onClick={() => handleInfoClick('  CAC Form 2 (Statement of share capital)', cac2)}
                />
              </div>
              <div className='bg-transparent flex flex-col items-center border border-gray-300 shadow-sm p-4 rounded-md'>
                {fileUploaded.cac_2 && !fileUploaded?.cac_2loading ? (
                  <>
                    <p className='text-sm px-6 mx-auto mb-2 text-center'>Uploaded successfully</p>
                    <p className='mb-2'>
                      <a
                        href='#'
                        onClick={() => {
                          setModalVisible(true)
                          setPreviewFileUrl(fileUrls.cac_2)
                        }}
                        className='text-blue-500'
                      >
                        View
                      </a>
                    </p>
                    <label
                      htmlFor='fileInputcac2'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Change file
                    </label>
                  </>
                ) : !fileUploaded?.cac_2loading ? (
                  <>
                    <p className='mb-2 text-center'>Drag and drop your document</p>
                    <p className='text-gray-500 text-sm mt-2'>OR</p>
                    <label
                      htmlFor='fileInputcac2'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Browse file
                    </label>
                  </>
                ) : (
                  <p className='mb-2 text-center'>Uploading...</p>
                )}
                <input
                  type='file'
                  name=' CAC Form 2 (Statement of share capital)'
                  id='fileInputcac2'
                  className='hidden'
                  onChange={event => handleFileChange(event, 'cac_2')}
                />
              </div>
              {fileError.cac_2 && <small className='text-red-500'>{fileError.cac_2}</small>}
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex py-8 mt-8 mb-8 justify-start gap-8 md:ml-5'>
        <button className='px-5 text-base text-primary' onClick={() => onGoBack(1)}>
          Go back
        </button>{' '}
        <button className='btn-primary bg-primary px-8 py-2 text-white' onClick={handleSubmit}>
          {NEXT} {docsSaving ? <Spin size='small' /> : <i className='fa fa-arrow-right ml-2' />}
        </button>{' '}
      </div>
      {modalVisible && (
        <PreviewModal
          modalVisible={modalVisible}
          previewFileUrl={previewFileUrl.split('|')[1]}
          setModalVisible={setModalVisible}
        />
      )}
      <InfoModal
        visible={infoModalVisible}
        onCancel={() => setInfoModalVisible(false)}
        title={selectedCard.title}
        description={selectedCard.description}
      />
    </div>
  )
}

Step2.propTypes = {
  onNext: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  savedData: PropTypes.object,
  clearCurrentStep: PropTypes.func,
  disableStepThree: PropTypes.bool
}

export default Step2
