/* eslint-disable no-unused-vars */
import loansImg from '../../../images/icons/loans.svg'
import feedbacksImg from '../../../images/icons/feedbacks.svg'
import distributorImg from '../../../images/icons/distributor.svg'
import rolesImg from '../../../images/icons/roles.svg'
import dashboardImg from '../../../images/icons/dashboard.svg'
import applicationsImg from '../../../images/icons/applications.svg'
import contactImg from '../../../images/icons/contact.svg'
import { checkIsAdmin } from '../../../redux/globalSelectors'
import transactionsImg from '../../../images/icons/interest.svg'
import TransactionImg from '../../../images/icons/transaction.svg'
import CustomerDirImg from '../../../images/icons/people.svg'
import HomeImage from '../../../images/icons/home.svg'
import NotificationImg from '../../../images/icons/bell.svg'
import approvedImg from '../../../images/icons/approved-icon.svg'

const WELCOME = 'Welcome'
const HOME = 'Home'
const LOG_OUT = 'Log Out'
const FEEDBACKS = 'Feedbacks'
const RETURN_TO_WEBSITE = 'Return to Website'
const APPLICATION = 'Applications'
const LOANS = 'Loans'
const APPROVED = 'Approved'
const CUSTOMERS = 'Customers'
const ROLES = 'Roles'
const ADMINISTRATION = 'Administration'
const DASHBOARD = 'Dashboard'
const Transactions = 'Transactions'
const SYSTEM_LOGS = 'System Logs'
const FINANCE = 'Finance'
// const APPLY_FOR_LOAN = 'Apply for Loans'
const INTEREST_TRACKER = 'Interest Tracker'
const APPLICATIONS = 'Applications'
const TRANSACTIONS = 'Transactions'
const DIRECTORS = 'Directors'
const DISTRIBUTORS = 'Distributors'
const Leads = 'Leads'
// const UPLOAD_DOCS = 'Upload_Docs'
const CUSTOMER_DIRECTOR = 'Customer Directors'
const NOTIFICATION = 'Notifications'
const Read = 'Read'
const CUSTOMERSMODULE = 'Customer Module'
const MARK_AS_READ = 'Mark all as Read'
// const DOCUMENTS = 'Documents'
const ADMIN_SIDE_MENU = [
  // {
  //   id: '0',
  //   title: 'Profile',
  //   icon: true,
  //   route: '/profile'
  // },
  {
    id: '1',
    title: DASHBOARD,
    image: dashboardImg,
    route: `${checkIsAdmin() ? '/admin-dashboard' : '/dashboard'}`
  },
  {
    id: '2',
    title: CUSTOMERS,
    image: contactImg,
    route: '/admin-customers'
  },
  {
    id: '3',
    title: APPLICATION,
    image: applicationsImg,
    route: '/admin-pending'
  },
  {
    id: '4',
    title: LOANS,
    image: loansImg,
    route: '/admin-disbursed'
  },
  {
    id: '5',
    route: '/admin-approved',
    title: APPROVED,
    image: approvedImg
  },

  {
    id: '6',
    title: INTEREST_TRACKER,
    image: transactionsImg,
    route: '/interest-tracker'
  },
  {
    id: '7',
    title: FINANCE,
    image: transactionsImg,
    route: '/finance'
  },
  {
    id: '8',
    title: Transactions,
    image: TransactionImg,
    route: '/transactions'
  },
  {
    id: '9',
    title: DISTRIBUTORS,
    image: distributorImg,
    route: '/distributors'
  },
  {
    id: '10',
    title: CUSTOMER_DIRECTOR,
    image: CustomerDirImg,
    route: '/customer-directors'
  },
  {
    id: '11',
    title: ROLES,
    image: rolesImg,
    route: '/admin-roles'
  },
  // {
  //   id: '9',
  //   title: APPLY_FOR_LOAN,
  //   image: loansImg,
  //   route: '/apply-for-loan'
  // },
  // {
  //   id: '10',
  //   title: UPLOAD_DOCS,
  //   image: applicationsImg,
  //   route: '/admin-upload-docs'
  // },
  // {
  //   id: '11',
  //   title: ADMINISTRATION,
  //   image: distributorImg,
  //   route: '/administration'
  // },
  // {
  //   id: '12',
  //   title: TEMPLATES,
  //   image: templateImg,
  //   route: '/templates'
  // },
  // {
  //   id: '13',
  //   title: SYSTEM_LOGS,
  //   image: sysLogsImg,
  //   route: '/system-logs'
  // },
  {
    id: '12',
    title: FEEDBACKS,
    image: feedbacksImg,
    route: '/feedbacks'
  },
  {
    id: '13',
    title: CUSTOMERSMODULE,
    image: contactImg,
    route: '/admin-customers-module'
  },
  {
    id: '14',
    title: Leads,
    image: rolesImg,
    route: '/admin-leads'
  }
]
const NOTIFICATION_DATA = [
  {
    id: 0,
    title: 'Congratulation!!! your loan has been aprroved',
    content:
      'Lorem ipsum dolor sit amet consectetur adipiscing Lorem ipsum dolor sit amet consectetur...'
  },
  {
    id: 2,
    title: 'Loan Payment',
    content:
      'Lorem ipsum dolor sit amet consectetur adipiscing Lorem ipsum dolor sit amet consectetur...'
  },
  {
    id: 3,
    title: 'Interest Rate',
    content:
      'Lorem ipsum dolor sit amet consectetur adipiscing Lorem ipsum dolor sit amet consectetur...'
  },
  {
    id: 4,
    title: 'Next Loan Payment',
    content:
      'Lorem ipsum dolor sit amet consectetur adipiscing Lorem ipsum dolor sit amet consectetur...'
  }
]

export const SIDE_MENU = [
  { id: 1, path: '/dashboard', url: '/dashboard', title: HOME, image: HomeImage },
  {
    id: 2,
    url: '/applications/all-application',
    path: [
      '/applications/all-application',
      '/applications/application-in-review',
      '/applications/approved-application',
      '/applications/declined-application'
    ],
    title: APPLICATIONS,
    image: applicationsImg
  },
  { id: 3, path: '/directors', url: '/directors', title: DIRECTORS, image: contactImg },
  {
    id: 4,
    path: '/transactions',
    url: '/transactions',
    title: TRANSACTIONS,
    image: TransactionImg
  },
  // NOT FUNCTIONAL AT THE MOMENT
  {
    id: 5,
    path: '/customer-notifications',
    url: '/customer-notifications',
    title: NOTIFICATION,
    image: NotificationImg
  },
  { id: 6, path: '/feedbacks', url: '/feedbacks', title: FEEDBACKS, image: feedbacksImg }
]

export {
  ADMIN_SIDE_MENU,
  WELCOME,
  ADMINISTRATION,
  ROLES,
  CUSTOMERS,
  LOG_OUT,
  FEEDBACKS,
  RETURN_TO_WEBSITE,
  LOANS,
  APPLICATION,
  DASHBOARD,
  Transactions,
  SYSTEM_LOGS,
  NOTIFICATION_DATA,
  Read,
  MARK_AS_READ
}
