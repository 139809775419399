import { Popconfirm, notification } from 'antd'
import React, { useState } from 'react'
import { dispatch } from '../../redux/store'
import PropTypes, { bool } from 'prop-types'

const UnapproveLoan = ({ loanId, canUnapproveLoan }) => {
  const [reason, setReason] = useState('')
  const handleDelete = async () => {
    if (!reason || reason.length < 3) {
      notification.warn({
        message: 'Please provide a valid reason'
      })

      return
    }
    await dispatch.applications.unApproveLoan({ loanId, reason })
  }
  const handleCancel = () => {
    console.log('cancel')
  }
  return (
    <Popconfirm
      title={
        <>
          <label htmlFor='reason' className='pr-4'>
            Add reason
          </label>
          <input
            className='border border-blue-700 rounded-md p-1'
            id='reason'
            type='text'
            disabled={!canUnapproveLoan}
            onChange={e => setReason(e.target.value)}
          />
        </>
      }
      onConfirm={handleDelete}
      onCancel={handleCancel}
      okText='Okay'
      cancelText='Cancel'
    >
      <span> Un-Approve Loan</span>
    </Popconfirm>
  )
}

UnapproveLoan.propTypes = {
  loanId: PropTypes.string,
  canUnapproveLoan: bool
}
export default UnapproveLoan
