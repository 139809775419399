/* eslint-disable camelcase */
/* eslint-disable no-path-concat */
import React, { useEffect, useRef, useState } from 'react'
import { message, Modal, notification, Upload } from 'antd'
import { jsPDF as JsPDF } from 'jspdf'

import PropTypes from 'prop-types'
import {
  DECLINE_NOW,
  DROP_REASON,
  LOAN_INTEREST_RATE,
  LOAN_MESSAGE,
  MGT_FEE,
  PENDING_LOAN,
  LOAN_AMOUNT,
  LOAN_DURATION,
  GENERATE_DOCS,
  AMORTIZED_AMOUNT
} from './constant'
import { dispatch } from '../../redux/store'
import { useSelector } from 'react-redux'
import { approveLoanValidator } from '../../utils/inputValidator'
import MOUAgreement from '../agreementDocs/MOUdocs'
import { BASE_URL, UPLOAD_BASE_URL } from '../../utils/constants'
import { SUPPORTED_IMAGE_TYPE } from '../../utils/data'
import { convertNumbersTowords } from '../../utils/helpers'
import VittasButton from '../buttons/Button'
import { FormSelect } from '../form-select'

const AdminApprovedLoansModal = ({ type, icon, data, disabled }) => {
  const initialState = {
    loanID: data.loan_id,
    mgtFee: '1.2',
    loanInterestRate: '3.5',
    loanAmount: data.loan_amount,
    loanDuration: data.duration_of_loan,
    agreement_doc_url: '',
    distributors_name: '',
    distributors_alias: '',
    note: '',
    amortised_interest_amount: 0
  }
  const [reason, setReason] = useState('')
  const [visible, setModal] = useState(false)
  const [showApproveLoan, setShowApproveLoan] = useState({
    title: GENERATE_DOCS,
    show: false
  })
  const [shouldShowAgreement, setShouldShowAgreement] = useState(false)
  const [isNormalLoan, setIsNormalLoan] = useState(true)
  const [loan, setLoan] = useState(initialState)
  const docuSignLinkRef = useRef(null)

  const declinedLoading = useSelector(
    ({ loading }) => loading.effects.applications.adminDeclineApplication
  )
  const approveLoading = useSelector(
    ({ loading }) => loading.effects.applications.adminApproveApplication
  )
  const exitingDistributors = useSelector(({ distributors }) => distributors.adminGetDistributors)

  const docuSignFeatureToggleEnabled = useSelector(
    ({ featureToggle }) => featureToggle.features?.DocuSign
  )

  const submitFormHandle = async () => {
    if (type === 'approve') {
      const { distributors_alias, distributors_name, ...others } = loan
      const { agreement_doc_url, distributors_alias: alias, note, ...withoutMou } = loan
      // When feature toogle for docusign is enabled, use others object else use withoutMou object
      const validator = approveLoanValidator(!docuSignFeatureToggleEnabled ? withoutMou : others)
      if (!validator.isValid) {
        notification.error({
          placement: 'bottomRight',
          message: 'An error occured',
          description: validator.error,
          duration: 5000
        })
        return false
      }

      const info = {
        loanId: data.loan_id,
        loanInterestRate: loan.loanInterestRate,
        loanPrincipalAmount: String(loan.loanAmount),
        mgtFee: loan.mgtFee,
        loanDuration: loan.loanDuration,
        agreement_doc_url: loan.agreement_doc_url,
        distributors_name: loan.distributors_name,
        note: loan.note,
        amortised_interest_amount: loan.amortised_interest_amount
      }

      if (isNormalLoan) {
        delete info.amortised_interest_amount
      }

      try {
        const result = await dispatch.applications.adminApproveApplication(info)
        if (result?.success && docuSignFeatureToggleEnabled) {
          docuSignLinkRef.current.setAttribute(
            'href',
            `${BASE_URL}docusign-admin-auth?loan_id=${result.id}`
          )
          docuSignLinkRef.current.click()
        }
        setLoan(initialState)
        setModal(false)
      } catch (error) {
        console.log(error)
      }
    } else {
      if (!reason) {
        notification.error({
          message: 'An error occured',
          description: 'Reason for decline is required!'
        })
        return false
      }
      const info = {
        loanId: String(data.loan_id),
        reason: reason
      }
      dispatch.applications.adminDeclineApplication(info)
      setModal(false)
    }
  }

  const uploadProps = {
    action: `//${UPLOAD_BASE_URL}upload`,
    name: 'file',
    beforeUpload: file => {
      if (!SUPPORTED_IMAGE_TYPE.includes(file.type)) {
        return message.error('Not supported')
      }
      if (file.size / 1000000 > 35) {
        return message.error('File size more than 30mb')
      }
      return file.size / 1000000 < 35 && SUPPORTED_IMAGE_TYPE.includes(file.type)
        ? true
        : Upload.LIST_IGNORE
    }
  }

  const handleUploadChange = (info, type) => {
    if (info.file && info.file.status === 'done') {
      if (info.file.response.success) {
        setLoan({ ...loan, [type]: info.file.response.data.url })
      }
    }
  }

  const convertToPdf = e => {
    e.preventDefault()
    const doc = new JsPDF('portrait', 'pt', [1450, 1410])

    doc.html(document.getElementById('pdf-view'), {
      x: 50,
      y: 50,
      margin: [73, 25],
      windowWidth: 1400,
      width: 1200,
      callback: () => {
        const pages = doc.internal.getNumberOfPages()
        const pageWidth = doc.internal.pageSize.width // Optional
        const pageHeight = doc.internal.pageSize.height // Optional
        doc.setFontSize(12) // Optional

        for (let j = 1; j < pages + 1; j++) {
          const horizontalPos = pageWidth / 2 // Can be fixed number
          const verticalPos = pageHeight - 10 // Can be fixed number
          doc.setPage(j)
          doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
            align: 'center'
          })
        }
        doc.save('mou.pdf')
        setShouldShowAgreement(false)
        setShowApproveLoan({ title: 'Approve now', show: true })
        setModal(true)
      }
    })
  }

  const previewMOUDocs = () => {
    const { agreement_doc_url, ...others } = loan
    const validator = approveLoanValidator(others)
    if (!validator.isValid) {
      notification.error({
        placement: 'bottomRight',
        message: 'An error occured',
        description: validator.error,
        duration: 5000
      })
      return false
    }
    setShouldShowAgreement(true)
  }

  function getDistributors(e) {
    e.preventDefault()

    if (exitingDistributors.length <= 0) {
      dispatch.distributors.adminGetAllDistributors()
    }
  }

  useEffect(() => {
    if (!docuSignFeatureToggleEnabled) {
      setShowApproveLoan({ title: 'Approve now', show: false })
    }
  }, [docuSignFeatureToggleEnabled])

  return (
    <>
      <span
        onClick={() => (disabled ? null : setModal(true))}
        className={` ${type === 'approve' ? 'hover:text-blue-600' : 'hover:text-red-600'}`}
      >
        {icon}
      </span>
      <div hidden>
        <MOUAgreement
          amountInNumber={loan.loanAmount}
          amountInWords={`${convertNumbersTowords(loan.loanAmount)} naira only`}
          customerEmail={data?.business_email}
          customerName={data?.business_name}
          duration={loan.loanDuration}
          distributorsAlias={loan.distributors_alias}
          distributorsName={loan.distributors_name}
        />
      </div>
      <Modal
        width={!shouldShowAgreement ? 800 : undefined}
        open={visible}
        onCancel={() => {
          setModal(false)
          setShouldShowAgreement(false)
        }}
        footer={false}
      >
        {!shouldShowAgreement ? (
          <div className='flex flex-wrap mt-2'>
            <div className='block w-full text-white'>
              <h1 className='text-black text-2xl py-3 font-bold text-center'>{PENDING_LOAN}</h1>
              <p className='mt-2 text-black text-lg text-center'>{LOAN_MESSAGE}</p>
              {type === 'approve' ? (
                <>
                  <div className='flex gap-8 my-5 justify-cente'>
                    <div className='flex gap-2'>
                      <input
                        onClick={() => setIsNormalLoan(true)}
                        type='radio'
                        id='normalLoan'
                        name='loan'
                        value='normal_loan'
                        defaultChecked
                      />
                      <label htmlFor='normalLoan' className='text-black font-semibold text-lg'>
                        Normal Laon
                      </label>
                    </div>
                    <div className='flex gap-2'>
                      <input
                        onClick={() => setIsNormalLoan(false)}
                        type='radio'
                        id='amortizedLoan'
                        name='loan'
                        value='amortized_loan'
                      />
                      <label htmlFor='amortizedLoan' className='text-black font-semibold text-lg'>
                        Amortized Loan
                      </label>
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-x-10 gap-y-2'>
                    <div className='mt-5'>
                      <span className='text-sm text-black font-semibold text-left'>Loan ID</span>
                      <input
                        className='w-full rounded-sm outline-none border-gray-300 round hover:border-gray-700 border text-gray-900 mt-2 py-3 px-4 '
                        type='text'
                        value={loan.loanID}
                        disabled
                        name='loan_id'
                        onChange={({ target }) => setLoan({ ...loan, loanID: target.value })}
                      />
                    </div>
                    <div className='mt-5'>
                      <span className='text-sm text-black font-semibold text-left'>{MGT_FEE}</span>
                      <input
                        className='w-full outline-none rounded-sm  border-gray-300 round hover:border-gray-700 border text-gray-900 mt-2 py-3 px-4 '
                        type='number'
                        min={0}
                        placeholder='Value in percentage'
                        value={loan.mgtFee}
                        name='mgtFee'
                        onChange={({ target }) => setLoan({ ...loan, mgtFee: target.value })}
                      />
                      <span className='text-gray-400 tracking-wider'>
                        {loan.mgtFee || '0'}% of ₦{loan.loanAmount || '0'} is{' '}
                        <span className='text-blue-500 opacity-70'>
                          ₦{Number((+loan.mgtFee / 100) * +loan.loanAmount).toFixed(2)}
                        </span>
                      </span>{' '}
                    </div>
                    {isNormalLoan ? (
                      <div className='mt-5'>
                        <span className='text-sm text-black font-semibold text-left'>
                          {LOAN_INTEREST_RATE}
                        </span>
                        <input
                          className='w-full outline-none rounded-sm  border-gray-300 round hover:border-gray-700 border text-gray-900 mt-2 py-3 px-4 '
                          type='number'
                          min={0}
                          value={loan.loanInterestRate}
                          name='loanInterestRate'
                          onChange={({ target }) =>
                            setLoan({ ...loan, loanInterestRate: target.value })
                          }
                        />
                        <span className='text-gray-400'>
                          {loan.loanInterestRate || '0'}% of ₦{loan.loanAmount || '0'} is{' '}
                          <span className='text-blue-500 opacity-70'>
                            ₦{Number((+loan.loanInterestRate / 100) * +loan.loanAmount).toFixed(2)}
                          </span>
                        </span>
                      </div>
                    ) : (
                      <div className='mt-5'>
                        <span className='text-sm text-black font-semibold text-left'>
                          {AMORTIZED_AMOUNT}
                        </span>
                        <input
                          className='w-full outline-none rounded-sm  border-gray-300 round hover:border-gray-700 border text-gray-900 mt-2 py-3 px-4 '
                          type='number'
                          min={0}
                          value={loan.amortised_interest_amount}
                          name='amortised_interest_amount'
                          onChange={({ target }) =>
                            setLoan({
                              ...loan,
                              amortised_interest_amount: target.value
                            })
                          }
                        />
                      </div>
                    )}
                    <div className='mt-5'>
                      <span className='text-sm text-black font-semibold text-left'>
                        {LOAN_AMOUNT}
                      </span>
                      <input
                        className='w-full outline-none rounded-sm  border-gray-300 round hover:border-gray-700 border text-gray-900 mt-2 py-3 px-4 '
                        type='number'
                        min={0}
                        value={loan.loanAmount}
                        name='loanAmount'
                        onChange={({ target }) => setLoan({ ...loan, loanAmount: target.value })}
                      />
                    </div>
                    <div className='mt-5'>
                      <span className='text-sm text-black font-semibold text-left'>
                        {LOAN_DURATION}
                      </span>
                      <input
                        className='w-full outline-none rounded-sm  border-gray-300 round hover:border-gray-700 border text-gray-900 mt-2 py-3 px-4 '
                        type='number'
                        min={0}
                        value={loan.loanDuration}
                        name='loanDuration'
                        onChange={({ target }) => setLoan({ ...loan, loanDuration: target.value })}
                      />
                    </div>
                    <div className='mt-5'>
                      <span className='text-sm font-semibold text-black text-left'>
                        Distributors name (Optional)
                      </span>
                      <FormSelect
                        value={loan.distributors_name}
                        name='distributors_name'
                        onClick={e => getDistributors(e)}
                        label='Select distributor'
                        className='w-full border-gray-300 rounded-sm  hover:border-gray-700 border text-gray-900  py- px-4 '
                        onChange={({ target }) =>
                          setLoan({
                            ...loan,
                            distributors_name: target.value
                          })
                        }
                        options={exitingDistributors?.map(item => ({
                          value: item.id,
                          name: item.name
                        }))}
                      />
                    </div>
                    {docuSignFeatureToggleEnabled && (
                      <div className='mt-5'>
                        <span className='text-sm text-black text-left'>Distributors alias</span>
                        <input
                          className='w-full border-gray-300 round hover:border-gray-700 border text-gray-900 mt-2 py-2 px-4 '
                          type='text'
                          placeholder={loan.distributors_alias}
                          value={loan.distributors_alias}
                          name='distributors_alias'
                        />
                      </div>
                    )}
                    <div className='mt-5'>
                      <span className='text-sm text-black text-left'>{DROP_REASON}</span>
                      <textarea
                        className='w-full border-gray-300 round hover:border-gray-700 border text-gray-900 mt-2 py-2 px-4 '
                        value={loan.note}
                        name='note'
                        onChange={({ target }) => setLoan({ ...loan, note: target.value })}
                      />
                    </div>
                    {showApproveLoan.show && (
                      <div
                        className='text-center px-2 mx-auto py-10'
                        style={{
                          wordWrap: 'break-word',
                          maxWidth: '190px',
                          minWidth: '180px'
                        }}
                      >
                        <Upload.Dragger
                          {...uploadProps}
                          onChange={info => handleUploadChange(info, 'agreement_doc_url')}
                        >
                          <p className='text-center font-normal pt-6'> Drag files </p>
                          <p className='text-center my-6 font-normal'> Or </p>
                          <p className='text-center font-normal text-base text-primary underline'>
                            Upload MOU pdf
                          </p>
                        </Upload.Dragger>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className='mt-5'>
                  <span className='text-sm text-black text-left'>{DROP_REASON}</span>
                  <textarea
                    className='w-full border-gray-600 hover:border-gray-700 border text-gray-900 mt-2 py-2 px-4 rounded-lg'
                    value={reason}
                    onChange={({ target }) => setReason(target.value)}
                  />
                </div>
              )}
              <div className='mt-5'>
                <button
                  onClick={() => {
                    if (docuSignFeatureToggleEnabled) {
                      return showApproveLoan.show ? submitFormHandle() : previewMOUDocs()
                    } else {
                      return submitFormHandle()
                    }
                  }}
                  className={`py-3 my-5 font-bold ${
                    type === 'approve' ? 'bg-primary' : 'bg-red-600'
                  } text-white rounded-md w-full focus:outline-none focus:shadow-outline`}
                >
                  {declinedLoading && (
                    <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
                  )}
                  {approveLoading && <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />}
                  {type === 'approve' ? `${showApproveLoan.title}` : DECLINE_NOW}
                </button>
                <a ref={docuSignLinkRef} hidden rel='noreferrer' />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <MOUAgreement
              amountInNumber={loan.loanAmount}
              amountInWords={`${convertNumbersTowords(loan.loanAmount)} naira only`}
              customerEmail={data?.business_email}
              customerName={data?.business_name}
              duration={loan.loanDuration}
              distributorsAlias={loan.distributors_alias}
              distributorsName={loan.distributors_name}
              showHighlight
            />
            <VittasButton
              className='bg-primary-200 text-white'
              showicon={false}
              onClick={convertToPdf}
              text='Download mou'
            />
          </div>
        )}
      </Modal>
    </>
  )
}

AdminApprovedLoansModal.propTypes = {
  icon: PropTypes.any,
  type: PropTypes.string.isRequired,
  data: PropTypes.object,
  disabled: PropTypes.bool
}

export default React.memo(AdminApprovedLoansModal)
