/* eslint-disable react/display-name */

import React, { useState } from 'react'
import { Table } from 'antd'
import PropTypes from 'prop-types'

const ApplicationTable = ({ tableData }) => {
  const [pagination, setPagination] = useState({
    pageSize: 10,
    showSizeChanger: true,
    current: 1
  })

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name
    })
  }

  return (
    <div className='z-40'>
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
        columns={tableData.column}
        onChange={value => setPagination(value)}
        pagination={pagination}
        // loading={tableData.loading}
        dataSource={tableData.data}
      />
    </div>
  )
}

ApplicationTable.propTypes = {
  tableData: PropTypes.shape({
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    column: PropTypes.array
  })
}

export default ApplicationTable
