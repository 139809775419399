/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/display-name */
/* eslint-disable indent */
import React from 'react'
import IdsComponent from '../../../sharedComponents/others/IdsComponent'
import { checkPrivilages, formatCurrency } from '../../../utils/helpers'
import { Link } from 'react-router-dom'
import * as roles from '../../../utils/roles'
import AdminApprovedLoansModal from '../../../sharedComponents/modal/AdminApprovedLoansModal'
import DeleteLoan from '../../applications/DeleteLoan'
import AdminDisburseLoansModal from '../../../sharedComponents/modal/AdminDisburseLoansModal'
import UnapproveLoan from '../../applications/UnapproveLoan'
import DropDown from '../../../sharedComponents/drop-down/DropDown'
import { notification } from 'antd'
import { adminServices } from '../../../services'

export const LOAN_STATUSES_COLOUR_AND_NAME = {
  Declined: 'bg-red-50 text-red-500 font-bold',
  Approved: 'bg-green-100 text-green-700 font-bold',
  Disbursed: 'bg-paidOnTime text-white font-bold',
  Pending: 'bg-yellow-100 text-yellow-700 font-bold'
}
export const NEXT = 'Next'

export const customerModuleColumns = [
  {
    title: <span className='font-bold'>Customer ID</span>,
    dataIndex: 'id',
    key: 'id',
    render: id => <IdsComponent id={id} />
  },
  {
    title: <span className='font-bold'>Business Name</span>,
    dataIndex: 'business_name',
    key: 'business_name',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.business_name.localeCompare(b.business_name)
  },
  {
    title: <span className='font-bold'>Admin Name</span>,
    dataIndex: 'name',
    key: 'name',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.name.localeCompare(b.name)
  },
  {
    title: <span className='font-bold'>Email address</span>,
    dataIndex: 'email',
    key: 'email',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.email.localeCompare(b.email)
  },
  {
    title: <span className='font-bold'>Phone Number</span>,
    dataIndex: 'phone_number',
    key: 'phone_number',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.phone_number - b.phone_number
  },
  {
    title: <span className='font-bold'>Customer Documents</span>,
    dataIndex: 'documents',
    key: 'documents',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.documents - b.documents,
    render: documents => (
      <span
        className={
          documents && documents.length > 0
            ? 'bg-green-50 text-green-600 px-2 py-1'
            : 'bg-red-50 text-red-600 px-2 py-1'
        }
      >
        {documents && documents.length > 0 ? 'Available' : 'Not available'}
      </span>
    )
  },
  {
    title: <span className='font-bold'>Created At</span>,
    dataIndex: 'created_at',
    key: 'created_at',
    defaultSortOrder: ['ascend'],
    render: created_at => <span>{new Date(created_at).toLocaleDateString()}</span>,
    sorter: (a, b) => new Date(a.created_at) - new Date(b.phone_number)
  },
  {
    title: <span className='font-bold capitalize'>Actions</span>,
    datakey: '',
    key: 'action',
    render: record => {
      return (
        <DropDown>
          <Link
            to={`/admin-customers-details/${record.id}`}
            style={{ outline: 0 }}
            className='w-full text-left nav-sm-link'
          >
            <i className='far fa-eye mr-2' /> View Deatils
          </Link>
          <Link
            to={`/admin-create-customer-account/${record.id}`}
            style={{ outline: 0 }}
            className='w-full text-left nav-sm-link'
          >
            <i className='fas fa-plus mr-2' /> Manage Bank
          </Link>
        </DropDown>
      )
    }
  }
]

const handleGetTransactionByReference = async trxId => {
  try {
    const { data } = await adminServices.getVfdTransactionByReference(trxId)
    notification.info({
      message: 'Transaction Query',
      description: data?.message
    })
  } catch (err) {
    notification.info({
      message: 'An error occured',
      description: 'We are unable to query the transaction at this time. Try again after sometime.'
    })
  }
}

export const loanAppColumns = (permissions, { setModalVisible, setRecord, getAllUserLoans }) => {
  return [
    {
      title: <span className='font-bold'>Loan ID</span>,
      dataIndex: 'loan_id',
      key: 'loan_id',
      render: loan_id => <IdsComponent id={loan_id} />,
      defaultSortOrder: ['ascend'],
      sorter: (a, b) => a.loan_id - b.loan_id
    },
    {
      title: <span className='font-bold'>Transaction Reference</span>,
      defaultSortOrder: ['ascend'],
      render: records => {
        return records?.approved_obj?.transaction_id || 'N/A'
      }
    },
    {
      title: <span className='font-bold'>Submission Date</span>,
      dataIndex: 'time_submitted',
      key: 'time_submitted',
      defaultSortOrder: ['ascend'],
      sorter: (a, b) => new Date(a.time_submitted) - new Date(b.time_submitted),
      render: time_submitted => <span>{time_submitted?.split(' ')[0]}</span>
    },
    {
      title: <span className='font-bold'>Loan Purpose</span>,
      dataIndex: 'loan_purpose',
      key: 'loan_purpose',
      defaultSortOrder: ['ascend'],
      sorter: (a, b) => a.loan_purpose - b.loan_purpose
    },
    {
      title: <span className='font-bold'>Loan Amount</span>,
      defaultSortOrder: ['ascend'],
      render: (_, data) => {
        if (!data?.approved_obj) return <span>₦{formatCurrency(data?.loan_amount)}</span>
        return <span>₦{formatCurrency(data?.approved_obj?.loan_principal_amount_fixed)}</span>
      }
    },
    {
      title: (
        <span className='font-bold'>
          Loan Duration<sub>(months)</sub>
        </span>
      ),
      dataIndex: 'duration_of_loan',
      key: 'duration_of_loan',
      defaultSortOrder: ['ascend'],
      sorter: (a, b) => a.duration_of_loan - b.duration_of_loan,
      render: duration_of_loan => <span>{duration_of_loan || '--'}</span>
    },
    {
      title: <span className='font-bold'>Status</span>,
      dataIndex: 'loan_app_status',
      key: 'loan_app_status',
      defaultSortOrder: ['ascend'],
      sorter: (a, b) => a.loan_app_status - b.loan_app_status,
      render: loan_app_status => (
        <span
          className={`px-4 py-1 rounded-md text-white text-xs ${
            LOAN_STATUSES_COLOUR_AND_NAME[loan_app_status]
              ? LOAN_STATUSES_COLOUR_AND_NAME[loan_app_status]
              : 'bg-red-700'
          }`}
        >
          {loan_app_status}
        </span>
      )
    },

    {
      title: <span className='font-bold capitalize'>Actions</span>,
      key: 'action',
      render: records => {
        const checkPermissionsAndRender = (permission, content) =>
          checkPrivilages([permission], permissions) ? content : null

        const renderLoanScheduleButton = () => (
          <div
            className='hover:bg-white p-2'
            onClick={() => {
              setRecord(records)
              setModalVisible(true)
            }}
          >
            <span
              title='Loan schedule'
              className='hover:text-blue-600 w-full text-left nav-sm-link cursor-pointer'
            >
              Loan schedule
            </span>
          </div>
        )

        const renderVerifyDisburseButton = () => (
          <div
            className='hover:bg-white p-2'
            onClick={() => {
              handleGetTransactionByReference(records?.approved_obj?.transaction_id)
            }}
          >
            <span
              title='Verify Disburse'
              className='hover:text-blue-600 w-full text-left nav-sm-link cursor-pointer'
            >
              Verify Disburse
            </span>
          </div>
        )

        const renderViewDetailsButton = () => (
          <span>
            <Link
              to={`../application/${records.loan_id}`}
              style={{ outline: 0 }}
              className={`w-full text-left nav-sm-link ${
                !checkPrivilages([roles.VIEW_LOAN_APPLICATIONS], permissions)
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer'
              }`}
              title={
                !checkPrivilages([roles.VIEW_LOAN_APPLICATIONS], permissions)
                  ? 'You have no permission'
                  : ''
              }
              disabled={!checkPrivilages([roles.VIEW_LOAN_APPLICATIONS], permissions)}
            >
              View details
            </Link>
          </span>
        )
        const actions = {
          Pending: (
            <>
              <div className='hover:bg-white p-2'>
                <span
                  style={{ outline: 0 }}
                  className={`w-full text-left nav-sm-link ${
                    !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                      ? 'cursor-not-allowed'
                      : 'cursor-pointer'
                  }`}
                  title={
                    !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                      ? 'You have no permission'
                      : ''
                  }
                >
                  <AdminApprovedLoansModal
                    disabled={!checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)}
                    type='approve'
                    data={records}
                    icon={<span> Approve </span>}
                  />
                </span>
              </div>
              <div className='hover:bg-white p-2'>{renderViewDetailsButton()}</div>
              {renderLoanScheduleButton()}
            </>
          ),
          Approved: (
            <>
              <div className='hover:bg-white p-2'>
                <span
                  style={{ outline: 0 }}
                  className={`w-full text-left nav-sm-link ${
                    !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                      ? 'cursor-not-allowed'
                      : 'cursor-pointer'
                  }`}
                  title={
                    !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                      ? 'You have no permission'
                      : ''
                  }
                >
                  <AdminDisburseLoansModal
                    disabled={!checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)}
                    data={records?.approved_obj}
                    icon={<> Disburse Loan </>}
                  />
                </span>
              </div>

              <div className='hover:bg-white p-2'>
                <span
                  style={{ outline: 0 }}
                  className={`w-full text-left nav-sm-link hover:text-blue-600 ${
                    !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                      ? 'cursor-not-allowed'
                      : 'cursor-pointer'
                  }`}
                  title={
                    !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                      ? 'You have no permission'
                      : ''
                  }
                >
                  <UnapproveLoan
                    loanId={records.loan_id}
                    canUnapproveLoan={!checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)}
                  />
                </span>
              </div>
              <div className='hover:bg-white p-2'>{renderViewDetailsButton()}</div>
              {renderLoanScheduleButton()}
            </>
          ),
          VerifyDisburse: (
            <>
              <div className='hover:bg-white'>
                <span
                  style={{ outline: 0 }}
                  className={`w-full text-left nav-sm-link ${
                    !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                      ? 'cursor-not-allowed'
                      : 'cursor-pointer'
                  }`}
                  title={
                    !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                      ? 'You have no permission'
                      : ''
                  }
                >
                  <AdminDisburseLoansModal
                    disabled={!checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)}
                    data={records?.approved_obj}
                    icon={<> Disburse Loan </>}
                  />
                </span>
              </div>

              <div className='hover:bg-white p-2'>
                <span
                  style={{ outline: 0 }}
                  className={`w-full text-left nav-sm-link hover:text-blue-600 ${
                    !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                      ? 'cursor-not-allowed'
                      : 'cursor-pointer'
                  }`}
                  title={
                    !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                      ? 'You have no permission'
                      : ''
                  }
                >
                  <UnapproveLoan
                    loanId={records.loan_id}
                    canUnapproveLoan={!checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)}
                  />
                </span>
              </div>
              <div className='hover:bg-white p-2'>{renderViewDetailsButton()}</div>
              <div className='hover:bg-white'>{renderLoanScheduleButton()}</div>
              <div className='hover:bg-white'>{renderVerifyDisburseButton()}</div>
            </>
          ),
          Disbursed: (
            <>
              <div className='hover:bg-white'>{renderViewDetailsButton()}</div>
              {renderLoanScheduleButton()}
              <div className='hover:bg-white p-2'>
                <button className='hover:text-blue-600 w-full text-left nav-sm-link'>
                  Interest tracker{' '}
                </button>
              </div>
            </>
          ),
          Declined: (
            <button className='hover:bg-white'>
              {checkPermissionsAndRender(
                roles.DELETE_LOAN,
                <DeleteLoan
                  canDeleteLoan={checkPrivilages([roles.DELETE_LOAN], permissions)}
                  id={records.loan_id}
                />
              )}
            </button>
          ),
          Incomplete: (
            <button className='hover:bg-white p-2'>
              {checkPermissionsAndRender(
                roles.DELETE_LOAN,
                <DeleteLoan
                  canDeleteLoan={checkPrivilages([roles.DELETE_LOAN], permissions)}
                  callback={getAllUserLoans}
                  id={records.loan_id}
                />
              )}
            </button>
          ),
          Closed: <span className='hover:bg-white p-2'>This loan is closed</span>
        }
        return (
          <DropDown>
            {records?.approved_obj?.transaction_id && !records?.approved_obj?.vfd_disburse_status
              ? actions.VerifyDisburse
              : actions[records.loan_app_status]
              ? actions[records.loan_app_status]
              : actions.Approved}
          </DropDown>
        )
      }
    }
  ]
}
