import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Spin, notification } from 'antd'
import { Icon } from '@iconify/react'
import { InputField } from '../../../../sharedComponents/input-field'
import { Upload, adminServices } from '../../../../services'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { validateFileTypes } from '../../../../utils/helpers'

const closeButtonStyle = 'mt-7 mr-8 text-white text-4xl rounded-full bg-white'
const bodyStyle = {
  padding: 0
}

const AddDebtModal = ({ visible, onCancel, title, description }) => {
  const [agreementDocument, setAgreementDocument] = useState('')
  const [uploading, setUploading] = useState(false)

  const [loading, setLoading] = useState(false)
  const initialValues = {
    debt_amount: '',
    tenure: '',
    rate: '',
    start_date: '',
    end_date: ''
  }

  const validationSchema = Yup.object().shape({
    debt_amount: Yup.string().required('Debt amount is required'),
    tenure: Yup.string().required('Tenure is required'),
    rate: Yup.string().required('Interest rate is required'),
    start_date: Yup.date().required('Start date is required'),
    end_date: Yup.date().required('End date is required')
  })

  const handleSubmit = async values => {
    if (!agreementDocument) {
      notification.error({
        message: 'Error',
        description: 'Kindly upload the agreement document'
      })
      return
    }

    setLoading(true)
    try {
      const { data } = await adminServices.createVdfDebtAccount({
        ...values,
        source: 'VFD',
        debt_amount: values.debt_amount?.toString(),
        start_date: new Date(values?.start_date),
        end_date: new Date(values?.end_date),
        agreement_document: agreementDocument
      })

      if (data?.success) {
        notification.success({
          message: 'Success',
          description: data?.data?.message
        })

        setTimeout(() => {
          window.location.reload()
        }, 1000)
      } else {
        notification.success({
          message: 'Validation Error',
          description: 'Please provide the right inputs'
        })
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.response.data.message || 'Something went wrong'
      })
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const handleUploadFile = file => {
    if (!validateFileTypes(file, ['jpeg', 'png', 'pdf', 'doc', 'docx'])) {
      notification.error({
        message: 'Invalid file type',
        description: 'Please upload a valid file type, we only accept jpegs, pngs, pdfs, and docs'
      })
      return
    }

    setUploading(true)

    // eslint-disable-next-line no-undef
    const formData = new FormData()
    formData.append('file', file)
    Upload.uploadFile(formData)
      .then(({ data: { data } }) => {
        setUploading(false)
        console.log(data?.url)
        setAgreementDocument(data?.url)
      })
      .catch(error => {
        setUploading(false)
        notification.error({
          message: 'Error',
          description: error.message
        })
      })
  }

  return (
    <Modal
      onCancel={onCancel}
      open={visible}
      footer={null}
      destroyOnClose
      centered
      className='info-modal'
      closeIcon={<Icon icon='iconamoon:close-thin' color='#b1b1ff' className={closeButtonStyle} />}
      bodyStyle={bodyStyle}
      width={800}
    >
      <div className='modal-header bg-primary px-6 py-2 flex justify-between'>
        <h2 className='font-bold tracking-tight mt-4 text-white text-2xl'>{title}</h2>
      </div>
      <div className='py-8 px-8'>
        <p className='text-black text-base  font-bold'>{description}</p>
        <div>
          <Formik
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            initialValues={initialValues}
          >
            <Form>
              <div className='flex flex-wrap mx-1 lg:mx-2 md:mx-auto'>
                <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8 '>
                  <Field
                    as={InputField}
                    type='number'
                    name='debt_amount'
                    label={<label className='text-black text-base'>Debt Amount</label>}
                    placeholder='₦1,691.000.56'
                    className='form-control bg-white'
                  />
                  <ErrorMessage name='debt_amount' component='small' className='text-red-500' />
                </div>
                <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8 '>
                  <Field
                    as={InputField}
                    type='date'
                    name='start_date'
                    label={<label className='text-black text-base'>Start Date</label>}
                    className='form-control bg-white'
                  />
                  <ErrorMessage name='start_date' component='small' className='text-red-500' />
                </div>
                <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8 '>
                  <Field
                    as={InputField}
                    type='text'
                    name='tenure'
                    label={<label className='text-black text-base'>Duration</label>}
                    placeholder='6 month'
                    className='form-control bg-white'
                  />
                  <ErrorMessage name='tenure' component='small' className='text-red-500' />
                </div>
                <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8 '>
                  <Field
                    as={InputField}
                    type='date'
                    name='end_date'
                    label={<label className='text-black text-base'>End Date</label>}
                    className='form-control bg-white'
                  />
                  <ErrorMessage name='end_date' component='small' className='text-red-500' />
                </div>
                <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8 '>
                  <Field
                    as={InputField}
                    type='number'
                    name='rate'
                    label={<label className='text-black text-base'>Interest Rate</label>}
                    placeholder='2.60%'
                    className='form-control bg-white'
                  />
                  <ErrorMessage name='rate' component='small' className='text-red-500' />
                </div>

                <div className='w-full sm:-1/2 md:w-1/2 md:px-4 relative mt-8'>
                  <Field
                    as={InputField}
                    name='sms'
                    label={<label className='text-black text-base'>Loan Agreement</label>}
                    className='form-control bg-white'
                  />
                  <ErrorMessage name='sms' component='small' className='text-red-500' />

                  <input
                    type='file'
                    className='absolute left-6 top-16 transform -translate-y-1/2'
                    onChange={e => {
                      // Handle file selection if needed
                      console.log('Selected file:', e.target.files[0])
                      handleUploadFile(e.target.files[0])
                    }}
                  />
                </div>
              </div>
              {uploading ? (
                <button
                  type='button'
                  className='bg-primary flex mx-auto text-base mt-6 py-3 px-6 border rounded-lg hover:bg-primary-200 text-white hover:text-gray-50'
                >
                  <Spin />
                </button>
              ) : (
                <button
                  type='submit'
                  className='bg-primary flex mx-auto text-base mt-6 py-3 px-6 border rounded-lg hover:bg-primary-200 text-white hover:text-gray-50'
                >
                  {loading ? <Spin /> : ' '}
                  Continue
                </button>
              )}
            </Form>
          </Formik>
        </div>
      </div>
    </Modal>
  )
}

AddDebtModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string
}

export default AddDebtModal
