import React, { useState, useEffect } from 'react'
import { notification } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as qs from 'query-string'

import Logo from '../../images/login/logo.svg'
import googleIcon from '../../images/icons/google-icon.png'
import { logInValidator } from '../../utils/inputValidator'
import {
  ForgotPwdModalBox,
  PasswordModalBoxFeedback,
  ConfirmSignUpModal,
  ResetPwdModal
} from '../../sharedComponents/modal'
import { dispatch } from '../../redux/store'
import {
  LOGIN_LABEL_EMAIL,
  LOGIN_LABEL_PWD,
  LOGIN_BTN,
  DONT_HAVE_AN_ACCOUNT,
  SIGN_UP,
  LOGIN_TEXT,
  FORGOT_YOUR_PWD,
  VITTAS_INTERNATIONAL,
  OR,
  REMEMBER_ME,
  CONTINUE_USING_GOOGLE
} from './constants'
import { BASE_URL } from '../../utils/constants'
import ResetTempPwdModal from '../../sharedComponents/modal/ResetTempPwdModal'

const Login = () => {
  const query = qs.parse(window.location.search)

  const history = useHistory()
  const loading = useSelector(({ loading }) => loading.effects.auth.initLogin)
  const [user, setUser] = useState({
    email: '',
    password: '',
    rememberMe: false
  })

  const [checked, setChecked] = useState(false)
  const [showPwd, setShowPwd] = useState(false)

  useEffect(() => {
    dispatch.auth.forgotPasswordModal(false)
    dispatch.auth.changePwdModal(false)
    dispatch.auth.forgotPwdModalFeedback({ visible: false, email: '' })
  }, [])

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      dispatch.auth.initLogin({ history, googleLogin: query })
    }
    dispatch.featureToggle.getAllFeatureToggles()
  }, [])

  const submitFormHandle = () => {
    const validator = logInValidator(user)
    if (validator.isValid) {
      if (checked) setUser({ ...user, rememberMe: true })
      dispatch.auth.initLogin({ history, user, googleLogin: false })
    } else {
      notification.error({
        message: 'An error occured',
        description: validator.error
      })
    }
  }

  const handleKeypress = e => {
    // it triggers by pressing the enter key
    if (e.nativeEvent.keyCode === 13) {
      submitFormHandle()
    }
  }

  const onChangeText = ({ target }) => {
    let value = target.value
    switch (target.name) {
      case 'email':
        value = target.value.toLowerCase()
        break
      case 'password':
        value = target.value
        break
    }
    setUser(user => ({
      ...user,
      [target.name]: target.value !== 'email' ? value.trim() : value
    }))
  }

  return (
    <div className='grid md:grid-cols-5 h-full gap-5 w-full'>
      <div className='hidden md:flex flex-col items-center justify-center bg-login-img col-span-3 h-screen bg-cover'>
        <div>
          <img src={Logo} alt='logo' />
          <div className='mt-5'>
            <p className='text-violet300 text-5xl font-semibold mb-0 leading-normal'>
              Focus on securing health
            </p>
            <p className='text-white text-5xl font-semibold max-w-2xl leading-normal'>
              while we take care of your healthcare loans.
            </p>
            <p className='mt-6 text-zinc300 text-xl font-normal'>
              The one stop platform for healthcare finance
            </p>
          </div>
        </div>
      </div>

      <div className='px-4 pt-24 col-span-2'>
        <div className='login-control'>
          <h1 className='text-4xl font-bold'>{LOGIN_TEXT} </h1>
          <div className=''>
            <div className='mt-5'>
              <span className='text-sm text-black'>{LOGIN_LABEL_EMAIL}</span>
              <input
                className='auth-input focus:bg-white'
                type='email'
                value={user.email}
                onChange={onChangeText}
                name='email'
              />
            </div>

            <div className='mt-7'>
              <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                  <span className='text-sm text-black'>{LOGIN_LABEL_PWD}</span>
                </div>
                <div className='text-sm'>
                  <ResetTempPwdModal headerTitle={VITTAS_INTERNATIONAL} />
                  <ResetPwdModal headerTitle={VITTAS_INTERNATIONAL} />
                  <PasswordModalBoxFeedback headerTitle={VITTAS_INTERNATIONAL} />
                  <ConfirmSignUpModal headerTitle={VITTAS_INTERNATIONAL} />
                </div>
              </div>

              <div className='flex items-center mt-6'>
                <div className='w-full'>
                  <div className='relative'>
                    <input
                      className='text-base block w-full bg-white border border-gray-300 focus:border-gray-600 rounded-lg p-3 px-4 focus:outline-none focus:bg-white'
                      type={showPwd ? 'text' : 'password'}
                      value={user.password}
                      onChange={onChangeText}
                      name='password'
                      onKeyDown={handleKeypress}
                    />

                    <button
                      onClick={() => setShowPwd(!showPwd)}
                      type='submit'
                      className='text-gray-500 text-xl font-bold absolute top-1 right-2 my-2 mr-2 focus:outline-none'
                    >
                      {showPwd ? <i className='fas fa-eye-slash' /> : <i className='fas fa-eye' />}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex justify-center items-center'>
              <div className='flex justify-center items-center mt-7'>
                <input
                  onChange={e => setChecked(!checked)}
                  type='checkbox'
                  checked={checked}
                  className='h-4 w-4 text-blue-600 bg-primary focus:ring-blue-500 border-blue-300 rounded'
                />
                <label htmlFor='remember_me' className='ml-2 block text-sm text-gray-900'>
                  {REMEMBER_ME}
                </label>
              </div>
            </div>

            <div className='mt-7'>
              <button
                onClick={submitFormHandle}
                disabled={!(user.email && user.password)}
                className={`py-3 font-bold bg-primary text-white rounded-md w-full focus:outline-none ${
                  !(user.email && user.password) && 'bg-opacity-50'
                }`}
              >
                {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
                {LOGIN_BTN}
              </button>
            </div>
            <div className='mt-7 text-center text-lg'>{OR}</div>
            <div className='mt-7'>
              <a
                href={`${BASE_URL}login`}
                className='block py-3 bg-neutral border-neutral2 border-2 text-black rounded-md w-full focus:outline-none cursor-pointer text-lg text-center'
              >
                <img src={googleIcon} alt='google icon' className='inline mr-2' />
                {CONTINUE_USING_GOOGLE}
              </a>
            </div>
            <div className='text-center my-4 text-lg'>
              {DONT_HAVE_AN_ACCOUNT}
              <Link to='/sign-up' className='text-primary ml-2 underline '>
                {SIGN_UP}
              </Link>
            </div>
            <div className='mt-4 flex justify-center'>
              <ForgotPwdModalBox headerTitle={VITTAS_INTERNATIONAL} btnTitle={FORGOT_YOUR_PWD} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
