/* eslint-disable no-unused-vars */
import { Menu, notification } from 'antd'
import { PropTypes } from 'prop-types'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '../../../redux/store'
import { Roles } from '../../../services'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import { ROLES } from './constants'
import AntdDropdown from '../../../sharedComponents/drop-down/AntdDropdown'
import DeleteRole from './DeleteRole'
import UpdateRoleModal from './UpdateRoleModal'

const AdminRoles = () => {
  const [roles, setRoles] = useState(ROLES)
  const [rolesTitle, setRolesTitle] = useState('')
  const [openUserMenu, setUserMenu] = useState(false)
  const [query2, setQuery2] = useState('')
  const [loading, setLoading] = useState(false)

  const { allRoles } = useSelector(({ roles }) => roles)

  const calculatePrivilages = role => {
    let count = 0
    for (const value of Object.values(role)) {
      if (Number(value) === 1) {
        count = count + 1
      }
    }
    return count
  }

  useEffect(() => {
    dispatch.roles.getAllRoles()
  }, [])

  const handleChange = ({ target }) => {
    const no = roles.findIndex(item => item.name === target.name)
    const newRoles = roles
    newRoles[no] = { ...newRoles[no], isChecked: !newRoles[no].isChecked }
    setRoles([...newRoles])
  }
  const submitFormHandle = async e => {
    let roleObj = {}
    roles.forEach(role => {
      roleObj = { ...roleObj, [role.name]: Number(role.isChecked) }
    })
    roleObj.role_title = rolesTitle
    try {
      if (rolesTitle.length === 0) {
        notification.error({
          message: 'Input Validation Failed',
          description: 'Please enter role title'
        })
        return false
      }
      setLoading(true)
      const { data } = await Roles.updateUserRoles(roleObj)
      if (data.success) {
        notification.success({
          message: 'Request Successful',
          description: data.message
        })
        dispatch.roles.getAllRoles()
        setLoading(false)
        setRolesTitle('')
      } else {
        notification.error({
          message: 'Reqiest Failed',
          description: data.message
        })
        setLoading(false)
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong'
      })
      setLoading(false)
    }
  }

  const handleSearch2 = e => {
    setQuery2(e.target.value)
  }

  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <div className='grid grid-cols-2 md:grid-cols-2 gap-2 mx-2 mt-4'>
          <div className=''>
            <h2 className='pt-4 font-bold text-xl'>Create New Roles </h2>
            <div className='w-full md:w-4/5 px-2 my-6'>
              <Form
                placeholder=''
                title2='Role Title'
                onChange={({ target }) => setRolesTitle(target.value)}
                value={rolesTitle}
              />
            </div>
            <div className='px-2'>
              <h2 className='font-bold'>Privileges:</h2>
              {roles.map((item, index) => (
                <div key={index} className='flex items-center mt-7'>
                  <input
                    onChange={handleChange}
                    type='checkbox'
                    name={item.name}
                    value={item.isChecked}
                    checked={item.isChecked}
                    className='h-4 w-4 text-blue-600 bg-primary focus:ring-blue-500 border-blue-300 rounded'
                  />
                  <label htmlFor='remember_me' className='ml-2 block text-sm text-gray-900'>
                    {item.title}
                  </label>
                </div>
              ))}
            </div>
            <div>
              <div className='w-full flex my-10 justify-start'>
                <button className='btn-white mr-4' onClick={() => {}}>
                  Cancel
                </button>
                <button className='btn-primary-200' onClick={submitFormHandle}>
                  {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}
                  Create Role
                </button>
              </div>
            </div>
          </div>
          <div className='mr-8'>
            <h2 className='pt-4 mb-5 font-bold text-xl'>Existing Roles </h2>
            <Form placeholder='Search Roles' width='100%' onChange={handleSearch2} value={query2} />
            <div>
              {allRoles.length > 0 && (
                <table width='100%' className='mt-4'>
                  <tbody>
                    {allRoles.map((role, index) => {
                      const roleCount = calculatePrivilages(role)
                      return (
                        <tr
                          key={index}
                          style={{ lineHeight: '50px', borderBottom: '1px dotted gray' }}
                        >
                          <td>
                            <p className='font-bold'>
                              {role.role_title.charAt(0).toUpperCase() +
                                role.role_title.substring(1)}
                            </p>
                          </td>
                          <td>
                            <span className='px-6 py-2 border bg-green-100 text-green-700 hover:text-green-800 rounded-lg hover:bg-green-100 text-sm'>
                              {roleCount} Privilege{roleCount > 1 && 's'}
                            </span>
                          </td>
                          <td>
                            <AntdDropdown
                              componentViewer={<i className='fa fa-ellipsis-v' />}
                              menu={() => dropDownMenu(role)}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  )
}

const Form = props => {
  return (
    <div className={`w-full ${props.width ? 'md:w-full' : 'md:w-4/5 px-2 my-6'}`}>
      {props.title2 && <span className='text-sm text-gray-900  font-semibold'>{props.title2}</span>}
      <input
        className='form-control'
        placeholder={props.placeholder}
        type='text'
        onChange={props.onChange}
        value={props.value}
      />
    </div>
  )
}

const dropDownMenu = role => (
  <Menu>
    <Menu.Item key={1}>
      <a target='_blank' rel='noopener noreferrer'>
        <i className='far fa-eye mr-2' />
        View Privileges
      </a>
    </Menu.Item>
    <Menu.Item>
      <UpdateRoleModal role={role} />
    </Menu.Item>
    <Menu.Item key={3}>
      <DeleteRole roleId={role.role_id} />
    </Menu.Item>
  </Menu>
)

Form.propTypes = {
  placeholder: PropTypes.string,
  width: PropTypes.string,
  title2: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default AdminRoles
