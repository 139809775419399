/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  BIZ_DOCUMENTS,
  BIZ_INFORMATION,
  BIZ_SIGNATORY,
  GENDER,
  DATE_OF_BIRTH,
  HOUSE_ADDRESS,
  MARITAL_STATUS,
  MEMORANDUM_AND_ARTICLE,
  PARTICULARS_OF_DIRECTORS,
  PHARMACISTS,
  REQUIRED_BIZ_LICENSE,
  SIX_MONTHS_BANK_STATEMENT,
  NO_OF_KIDS,
  CHANGE_OF_REG_ADDRESS,
  APPOINTMENT_OF_DIR,
  STATEMENT_SHARE_CAP,
  GOVERNMENT_ID
} from '../../pages/applications/constant'
import pdfImage from '../../images/pdf.jpg'
import loader from '../../images/load.gif'
import { formatCurrency, validateURL } from '../../utils/helpers'
import { downloadCSV } from '../../sharedComponents/export/bankStatement'
import { bankStatementColumn } from '../admin-pages/customers/constants'
import { DETAILS, NEW_BUSINESS_INFORMATION } from '../../pages/applications/ApplicationsDetails'
import { getQueryParams } from '../../utils/customeReactHook'
import directors from '../../services/directors'
import { notification } from 'antd'
import { VIEW_APPLICATION, APPLICATION_DATE } from './constants'
const DirectorAcceptLoan = () => {
  const history = useHistory()
  const [details, setDetails] = useState({})

  const query = getQueryParams()
  const loanId = query.get('loanID')
  const directorId = query.get('directorID')

  const viewLoanInfo = async () => {
    try {
      const { data } = await directors.director_view_loan(loanId, directorId)
      if (data?.success) {
        setDetails(data.data)
      } else {
        notification.error({
          message: 'An error occured',
          description: data?.message
        })
      }
    } catch (error) {
      notification.error({
        message: 'Error occured',
        description: error?.response?.data?.msg ||
              error?.message ||
              error?.data?.message ||
              'Something went wrong'
      })
    }
  }

  const handleAccept = async () => {
    try {
      const { data } = await directors.director_accept_loan(directorId, loanId)
      if (data?.success) {
        notification.success({
          message: 'Success',
          description: data?.message || 'Loan Accepted'
        })
        history.push('/')
      } else {
        notification.error({
          message: 'An error occured',
          description: data?.message
        })
      }
    } catch (error) {
      notification.error({
        message: 'Error occured',
        description: error?.response?.data?.msg ||
                      error?.message ||
                      error?.data?.message ||
                      'Something went wrong'
      })
    }
  }

  useEffect(() => {
    if (directorId && loanId) {
      viewLoanInfo()
    } else {
      history.push('./')
    }
  }, [])

  return (
    <div>
      <main className='w-full flex-grow p-4 bg-white px-1 md:px-8'>
        <div className='ml-1 flex flex-wrap mt-4'>
          <div className='w-2/3'>
            <h3 className='font-bold text-lg cursor-pointer mb-4'>
              {VIEW_APPLICATION}
            </h3>
            <div className='flex flex-wrap font-normal justify-start text-light-200'>
              <p className='mr-8'>
                <strong>Loan ID: #{details.loan_data?.loan_id && details.loan_data?.loan_id}</strong>

              </p>
              <p className=''>
                <strong>
                  {APPLICATION_DATE}{' '}
                  {details.loan_data?.loan_application_initiated &&
                    details.loan_data?.loan_application_initiated.slice(0, 10)}
                </strong>
              </p>
            </div>
          </div>
        </div>
        {Object.keys(details).length > 0 ? (
          <div className='ml-1 flex flex-wrap mt-4 mb-10'>
            <div className='w-full'>
              <h2 className='mt-3 mb-5 text-xl font-bold text-light-200'>{BIZ_INFORMATION}</h2>
            </div>
            <div className='w-full md:w-1/2'>
              { details.loan_data?.business_email ? DETAILS.map((detail, i) => (
                <div className='mb-3' key={i}>
                  <strong>{detail.title}: </strong> <span>{details.loan_data[detail.key]}</span>
                  <hr className='mt-2' />
                </div>
              )) : NEW_BUSINESS_INFORMATION.map((detail, i) => {
                // console.log(details.loan_data?.loan_business_information[detail.key])
                return (
                  <div className='mb-3' key={i}>
                    <strong>{detail.title}: </strong> <span>{details.loan_data?.loan_business_information[detail.key]}</span>
                    <hr className='mt-2' />
                  </div>
                )
              })}
            </div>
            <div className='w-full'>
              <h2 className='mt-5 mb-5 text-xl font-bold text-light-200'>{BIZ_DOCUMENTS}</h2>
            </div>
            <div className='w-full lg:w-4/5'>
              <div className='flex flex-wrap'>
                {details.loan_data?.cac_document_two && (
                  <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                    <span className='mr-6  '>{STATEMENT_SHARE_CAP}</span>
                    <a
                      href={details.loan_data?.cac_document_two}
                      target='_blank'
                      rel='noopener noreferrer'
                      download='CAC Form 2'
                    >
                      <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                    </a>
                  </p>
                )}
                {details.loan_data?.cac_document_three && (
                  <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                    <span className='mr-6  '>{CHANGE_OF_REG_ADDRESS}</span>
                    <a
                      href={details.loan_data?.cac_document_three}
                      target='_blank'
                      rel='noopener noreferrer'
                      download='CAC Form 3'
                    >
                      <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                    </a>
                  </p>
                )}
                {details.loan_data?.cac_document_seven && (
                  <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                    <span className='mr-6  '>{APPOINTMENT_OF_DIR}</span>
                    <a
                      href={details.loan_data?.cac_document_seven}
                      target='_blank'
                      rel='noopener noreferrer'
                      download='CAC Form 7'
                    >
                      <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                    </a>
                  </p>
                )}
                <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                  <span className='mr-6'>{REQUIRED_BIZ_LICENSE}</span>
                  <a
                    href={details.loan_data?.business_license_doc}
                    target='_blank'
                    rel='noopener noreferrer'
                    download='Business Licence'
                  >
                    <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                  </a>
                </p>
                <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                  <span className='mr-6'>{PARTICULARS_OF_DIRECTORS}</span>
                  <a
                    href={details.loan_data?.business_aoa_doc}
                    target='_blank'
                    rel='noopener noreferrer'
                    download='CAC'
                  >
                    <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                  </a>
                </p>
                <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                  <span className='mr-6  '>{SIX_MONTHS_BANK_STATEMENT}</span>
                  {validateURL(details.loan_data?.business_bank_statement) ? (
                    <a
                      href={details.loan_data?.business_bank_statement}
                      target='_blank'
                      rel='noopener noreferrer'
                      download='CAC'
                    >
                      <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                    </a>
                  ) : (
                    <button
                      onClick={() =>
                        downloadCSV(
                          bankStatementColumn,
                          JSON.parse(details.loan_data?.business_bank_statement)
                        )
                      }
                    >
                      <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                    </button>
                  )}
                </p>
                <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                  <span className='mr-6'>{PHARMACISTS}</span>
                  <a
                    href={details.loan_data?.business_super_pharmacist_lic}
                    target='_blank'
                    rel='noopener noreferrer'
                    download='CAC'
                  >
                    <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                  </a>
                </p>
                <p className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'>
                  <span className='mr-6'>{MEMORANDUM_AND_ARTICLE}</span>
                  <a
                    href={details.loan_data?.business_moa_doc}
                    target='_blank'
                    rel='noopener noreferrer'
                    download='CAC'
                  >
                    <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                  </a>
                </p>
              </div>
            </div>
            {details.signatories.length > 0 ? (
              <>
                <div className='w-full'>
                  <h2 className='mt-5 mb-5 text-xl font-bold text-light-200'>{BIZ_SIGNATORY}</h2>
                </div>
                <div className='w-full md:w-5/6 lg:w-4/5'>
                  <div className='flex flex-wrap'>
                    {details.signatories.map((user, i) => (
                      <div
                        key={i}
                        className={`w-full mb-6 ${
                          details.signatories.length === 1 ? 'p-8 bg-gray-100 md:w-4/5' : 'md:w-1/2'
                        }`}
                      >
                        <table>
                          <tbody>
                            <tr className='leading-7'>
                              <th className=' pr-6 text-right text-light-200'>
                                {user?.photo.split('.')[user?.photo.split('.').length - 1] ===
                                'pdf' ? (
                                    <a
                                      href={user?.photo}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      download='Picture'
                                      className='pointer'
                                    >
                                      <img src={pdfImage} alt='profile' width='100px' />
                                    </a>
                                  ) : (
                                    <img
                                      src={user?.photo?.length > 0 ? user?.photo : pdfImage}
                                      alt='profile'
                                      width='100px'
                                    />
                                  )}
                              </th>
                              <td className=''>
                                <i>
                                  {user.sig_title_role} {user.sig_last_name} {user.sig_first_name}
                                </i>
                                <br />
                                <i>{user.sig_email} </i>
                                <br />
                                <i>{user.sig_ph_num}</i>
                              </td>
                            </tr>
                            <tr className='leading-7'>
                              <th className=' pr-6 text-right text-light-200'>{HOUSE_ADDRESS}</th>
                              <td className=''>{user.sig_address ? user.sig_address : 'Null'}</td>
                            </tr>
                            <tr className='leading-7'>
                              <th className=' pr-6 text-right text-light-200'>{MARITAL_STATUS}</th>
                              <td className=''>{user.sig_marital_status}</td>
                            </tr>
                            <tr className='leading-7'>
                              <th className=' pr-6 text-right text-light-200'>{DATE_OF_BIRTH}</th>
                              <td className=''>{user.sig_birth_date}</td>
                            </tr>
                            <tr className='leading-7'>
                              <th className=' pr-6 text-right text-light-200'>{GENDER}</th>
                              <td className=''>{user.sig_gender}</td>
                            </tr>
                            <tr className='leading-7'>
                              <th className=' pr-6 text-right text-light-200'>{NO_OF_KIDS}</th>
                              <td className=''>{user.sig_num_kids}</td>
                            </tr>
                            <tr className='leading-7'>
                              <th className=' pr-6 text-right text-light-200'>{GOVERNMENT_ID}</th>
                              <td className=''>
                                <a
                                  href={user.government_id}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  download='CAC'
                                >
                                  <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
            <div className='w-full'>
              <h2 className='mt-5 mb-5 text-xl font-bold text-light-200'>Loan Details</h2>
            </div>

            <div className='w-full'>
              <p>
                <strong className='text-light-200 font-extrabold'>Loan Amount: </strong>{' '}
                <span>₦{formatCurrency(details.loan_data?.loan_amount)}</span>
              </p>
              <p>
                <strong className='text-light-200 font-extrabold'>Loan Duration: </strong>{' '}
                <span>{details.loan_data?.duration_of_loan} months</span>
              </p>
              <p>
                <strong className='text-light-200 font-extrabold'>
                  Pharmacist License Expiration Date:{' '}
                </strong>{' '}
                <span>{details.loan_data?.lic_expiry}</span>
              </p>
              <p>
                <strong className='text-light-200 font-extrabold'>Loan Purpose: </strong>{' '}
                <span>{details.loan_data?.loan_purpose}</span>
              </p>
            </div>
            <div className='w-full mt-6'>
              <button
                onClick={() => handleAccept()}
                className='btn-white font-normal hover:text-primary-200 bg-light-blue-1'
              >
                Accept Loan
              </button>
            </div>
          </div>
        ) : (
          <div className='flex justify-center items-center' style={{ height: '60vh' }}>
            <h1>
              <img src={loader} className='w-14' alt='Loading...' />
            </h1>
          </div>
        )}
      </main>
    </div>
  )
}

export default DirectorAcceptLoan
