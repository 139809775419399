import { notification } from 'antd'
import jwtDecode from 'jwt-decode'

import { AuthApi } from '../../services'
import { getSingleModel } from '../../utils/helpers'
import { reducerActions } from '../reducer'
import { dispatch as globalDispatch } from '../store'

const initialState = {
  authUser: {
    token: null,
    refreshToken: { details: null, token: null },
    permissions: {},
    userInfo: null,
    monoCode: null
  },
  isServerError: null,
  pwdModal: false,
  pwdModalFeedback: false,
  changePwdModal: false,
  tempChangePwdModal: { visible: false, email: null },
  confirmSignUpModal: { visible: false, email: null }
}

export const auth = {
  state: initialState,
  reducers: reducerActions,
  effects: dispatch => ({
    async initLogin(payload) {
      dispatch.auth.setError(null)
      try {
        let responseData = null
        if (payload.googleLogin) {
          responseData = payload.googleLogin
        } else {
          const { rememberMe, ...userDetails } = payload.user
          const { data } = await AuthApi.login(userDetails)
          responseData = data
          if (!responseData.token) {
            throw new Error(responseData?.message || 'Could not authenticate user')
          }
        }

        if (!responseData.success || responseData.success === '0') {
          notification.error({
            message: 'An error occured or the account is not found'
          })

          dispatch.auth.logout()
          return payload.history.push('/login')
        }

        if (!responseData.verified && !payload.googleLogin) {
          notification.error({
            message: 'Account Verification:',
            description: 'Account not yet verified!'
          })
          dispatch.auth.confirmSignUpModalBox({
            visible: true,
            email: payload?.user?.email
          })

          return payload.history.push('/login')
        }
        const refreshToken = {
          token: responseData?.data?.refresh_token,
          details: jwtDecode(responseData?.data?.refresh_token)
        }

        dispatch.auth.setState({
          authUser: {
            token: responseData.token,
            refreshToken
          }
        })
        const { data: userInfo } = await AuthApi.getUser()
        const user = jwtDecode(responseData.token)
        if (userInfo.data.has_temp_password) {
          dispatch.auth.tempChangePwdModal({
            visible: true,
            email: payload?.user?.email
          })
        } else {
          dispatch.auth.setState({
            authUser: {
              token: responseData.token,
              refreshToken,
              permissions: responseData?.data?.permissions ? responseData?.data?.permissions : {},
              userInfo: { ...user, ...userInfo }
            }
          })

          notification.success({
            message: 'Login successful'
          })
          if (responseData?.is_admin === 1) {
            payload.history.push('/admin-dashboard')
          } else {
            payload.history.push('/dashboard')
          }
        }
      } catch ({ response, message }) {
        notification.error({
          message: 'An error occured',
          description:
            response?.data?.message ||
            response?.data?.data?.email ||
            response?.data?.data?.password ||
            message
        })
      }
    },
    async refreshToken() {
      dispatch.auth.setError(null)
      try {
        const authModel = getSingleModel('auth')
        const authUser = authModel?.authUser

        const { data } = await AuthApi.refreshToken()
        const tokenDetails = jwtDecode(data.data.access_token)

        dispatch.auth.setState({
          authUser: {
            ...authUser,
            token: data.data?.access_token,
            userInfo: { ...authUser.userInfo, ...tokenDetails }
          }
        })

        window.location.reload()
        return
      } catch (error) {
        dispatch.auth.setError(error.response)
      }
    },
    async fetchUserInfo(payload) {
      const { token, permissions } = payload
      dispatch.auth.setError(null)
      try {
        const { data: userInfo } = await AuthApi.getUser()
        const user = jwtDecode(token)
        if (userInfo?.success) {
          dispatch.auth.setState({
            authUser: {
              token,
              permissions,
              userInfo: {
                ...user,
                ...userInfo,
                bank_statements: userInfo?.data?.documents?.filter(
                  obj => obj?.type === 'bank_statement'
                )
              }
            }
          })
        }
      } catch ({ response, message }) {
        notification.error({
          message: 'An error has occured',
          description: message
        })
      }
    },

    async register(payload) {
      dispatch.auth.setError(null)
      try {
        const { data } = await AuthApi.register(payload.user)

        if (!data.success) {
          throw new Error(data.message || 'Could not register user')
        }

        notification.success({
          message: data.message || 'Signup successful'
        })

        if (data.success) {
          dispatch.auth.confirmSignUpModalBox({
            visible: true,
            email: payload.user.email
          })
        }
      } catch ({ response, message }) {
        notification.error({
          message: 'An error occured',
          description:
            response?.data?.message ||
            response?.data?.data?.first_name ||
            response?.data?.data?.last_name ||
            response?.data?.data?.email ||
            response?.data?.data?.phone_number ||
            response?.data?.data?.password ||
            message
        })
      }
    },
    async forgotPwd(payload) {
      dispatch.auth.setError(null)
      try {
        const { data } = await AuthApi.forgotPassword({
          email: payload.email
        })

        if (!data?.success) {
          throw new Error(data?.message || 'Could not complete action')
        }

        if (data && data?.success) {
          notification.success({
            message: data.message || 'Code sent successful'
          })
          dispatch.auth.forgotPasswordModal(false)
          dispatch.auth.forgotPwdModalFeedback({
            visible: true,
            email: payload.email
          })
        }
      } catch ({ response, message }) {
        notification.error({
          message: 'An error occured',
          description:
            response?.data?.message ||
            response?.data?.data.email ||
            response?.data?.data.password ||
            message
        })
      }
    },
    async resetPasswordForCustomer(payload) {
      dispatch.auth.setError(null)
      try {
        const { data } = await AuthApi.resetPwdForCustomer(payload)
        const { success, message } = data
        if (!success) {
          notification.error({
            message: message || 'Could not reset password. Please try again'
          })
        }
        if (success) {
          notification.success({
            message: message || 'Password was reset successfully'
          })
        }
      } catch ({ message, response }) {
        notification.error({
          message: 'An error occured',
          description:
            response?.data?.message || response?.message || message || 'Something went wrong'
        })
      }
    },
    async resetTempPwd(payload) {
      dispatch.auth.setError(null)
      try {
        const { data } = await AuthApi.resetTempPassword(payload)
        if (!data.success) {
          notification.error({
            message: data.message || 'Could not complete action. Please try again!'
          })
        }
        notification.info({
          message: data.message || 'Password was changed successfully!'
        })
        if (data.success) {
          dispatch.auth.tempChangePwdModal({ visible: false, email: null })
          dispatch.auth.logout()
        }
      } catch (error) {
        dispatch.auth.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.message ||
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },
    async resetPwd(payload) {
      dispatch.auth.setError(null)
      try {
        const { history, ...info } = payload
        const { data } = await AuthApi.resetPassword(info)
        const { success, message } = data
        if (!success) {
          throw new Error(data.message || 'Could not complete action. Please try again!')
        }

        notification.info({
          message: message || 'Password was changed successfully!'
        })

        if (success) {
          dispatch.auth.changePwdModal(false)
          dispatch.auth.forgotPwdModalFeedback({ visible: false, email: '' })
          payload.history.push('/login')
        }
      } catch ({ response, message }) {
        notification.error({
          message: 'An error occured',
          description: response?.data?.message?.email
            ? 'Unknown email field!'
            : '' || response?.data?.message?.new_password || message
        })
      }
    },
    async confirmSignUp(payload) {
      dispatch.auth.setError(null)
      try {
        const { data } = await AuthApi.confirmAccount(payload.token)
        const { success, message } = data
        if (!success) {
          throw new Error(message || 'Could not complete action. Please try again!')
        }
        notification.info({
          message: message || 'Account confirmed successfully!'
        })
        if (success) {
          dispatch.auth.confirmSignUpModalBox({ visible: false, email: null })
          payload.history.push('/login')
        }
      } catch ({ response, message }) {
        notification.error({
          message: 'An error occured',
          description: response?.data?.message || response?.message || message
        })
      }
    },

    async resendConfirmSignUp(payload) {
      dispatch.auth.setError(null)
      try {
        const { data } = await AuthApi.resendConfirmAccount(payload)
        const { success, message } = data
        if (!success) {
          throw new Error(message || 'Could not complete action. Please try again!')
        }
        notification.info({
          message: message || 'Account confirmed successfully!'
        })
        if (success) {
          // dispatch.auth.setState({countDown: new Date()})
          notification.success({
            message: 'Sent',
            description: 'The confirmation code has been sent to your email'
          })
        }
      } catch ({ response, message }) {
        notification.error({
          message: 'An error occured',
          description: response?.data?.message || response?.message || message
        })
      }
    },

    async changePassword(payload) {
      dispatch.auth.setError(null)

      try {
        const { data } = await AuthApi.passwordChange(payload)
        const { success, message } = data
        if (!success) {
          throw new Error(message || 'Could not complete action. Please try again!')
        }

        if (success) {
          notification.success({
            message: 'Request completed',
            description: 'Password changed successfully!'
          })
        }
      } catch ({ response, message }) {
        if (response?.status > 200) {
          notification.error({
            message: 'An error occured',
            description: response?.data?.message || 'Invalid password provided!'
          })
        }
      }
    },

    async getBankStatement(payload) {
      dispatch.auth.setError(null)
      const auth1 = getSingleModel('auth')
      dispatch.auth.monoUpdate(payload)

      try {
        const { data } = await AuthApi.updateBankStatement({
          user_id: auth1.authUser.userInfo.data.id,
          code: payload.code,
          duration: payload.bankStatementDuration
        })
        const { success, message } = data
        if (!success) {
          throw new Error(message || 'Could not complete action. Please try again!')
        }
        if (success) {
          await dispatch.auth.fetchUserInfo(auth1?.authUser)
          notification.success({
            message: 'Success',
            description: 'Bank statement updated successfully'
          })
        }
      } catch ({ response, message }) {
        notification.error({
          message: 'An error occured',
          description: response?.data?.message || response?.message || message
        })
      }
    },
    async uploadProfileImage(payload) {
      dispatch.auth.setError(null)

      try {
        const { data } = await AuthApi.uploadProfileImg({
          photo_url: payload
        })
        const { success, message } = data
        if (!success) {
          throw new Error(message || 'Could not complete action. Please try again!')
        }

        if (success) {
          notification.success({
            message: 'Request completed',
            description: 'Image updated successfully!'
          })
        }
      } catch ({ response, message }) {
        if (response?.status >= 200) {
          notification.error({
            message: 'An error occured',
            description: message || 'Something went wrong, please try again!'
          })
        } else {
          notification.error({
            message: 'An error occured',
            description: message || 'Something went wrong, please try again!'
          })
        }
      }
    },

    async forgotPasswordModal(visible) {
      dispatch.auth.setError(null)
      dispatch.auth.setState({ pwdModal: visible })
    },

    async forgotPwdModalFeedback({ visible, email }) {
      dispatch.auth.setError(null)
      dispatch.auth.setState({
        pwdModalFeedback: { isVisible: visible, email }
      })
    },
    tempChangePwdModal(payload) {
      dispatch.auth.setError(null)
      dispatch.auth.setState({
        tempChangePwdModal: { visible: payload.visible, email: payload.email }
      })
    },

    async changePwdModal(visible) {
      dispatch.auth.setError(null)
      dispatch.auth.setState({ changePwdModal: visible })
    },

    async confirmSignUpModalBox(payload) {
      dispatch.auth.setError(null)
      dispatch.auth.setState({
        confirmSignUpModal: { visible: payload.visible, email: payload.email }
      })
    },

    async profileInfoUpdate(payload) {
      dispatch.auth.setError(null)
      const auth1 = getSingleModel('auth')
      try {
        const { data } = await AuthApi.updateProfile(payload)
        const { success, message } = data
        if (!success) {
          throw new Error(message || 'Could not complete action. Please try again!')
        }

        if (success) {
          notification.success({
            message: 'Success',
            description: 'Profile updated successfully'
          })
          dispatch.auth.setState({
            authUser: {
              ...auth1.authUser,
              userInfo: {
                ...auth1.authUser.userInfo,
                data: {
                  ...auth1.authUser.userInfo.data
                }
              }
            }
          })
        }
      } catch ({ response, message }) {
        notification.error({
          message: 'An error occured',
          description: response?.message || message
        })
      }
    },
    async bizProfileInfoUpdate(payload) {
      dispatch.auth.setError(null)
      const auth1 = getSingleModel('auth')
      try {
        const { data } = await AuthApi.updateProfile(payload)
        const { success, message } = data
        if (!success) {
          throw new Error(message || 'Could not complete action. Please try again!')
        }

        if (success) {
          notification.success({
            message: 'Success',
            description: 'Profile updated successfully'
          })
          dispatch.auth.setState({
            authUser: {
              token: auth1.authUser.token,
              userInfo: {
                ...auth1.authUser.userInfo,
                data: {
                  ...auth1.authUser.userInfo.data
                }
              }
            }
          })
        }
      } catch ({ response, message }) {
        notification.error({
          message: 'An error occured',
          description: response?.message || message
        })
      }
    },
    monoUpdate(payload) {
      const auth1 = getSingleModel('auth')
      dispatch.auth.setState({
        monoCode: payload,
        ...auth1
      })
    },

    async reset() {
      await Promise.all([dispatch.auth.setState(initialState)])
    },

    async logout() {
      await Promise.all([
        globalDispatch.applications.reset(),
        globalDispatch.distributors.reset(),
        globalDispatch.salesRep.reset(),
        globalDispatch.payments.reset(),
        globalDispatch.feedbacks.reset(),
        globalDispatch.loans.reset(),
        globalDispatch.auth.reset(),
        globalDispatch.featureToggle.reset(),
        globalDispatch.roles.reset(),
        globalDispatch.users.reset()
      ])
    }
  }),
  selectors: (slice, createSelector, hasProps) => ({
    getCurrentUserInfo() {
      return createSelector(
        slice, // shortcut for (rootState) => rootState.auth
        auth => auth.authUser?.userInfo
      )
    }
  })
}
